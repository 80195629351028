import React, { useContext, useEffect, useReducer, useState, } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import Axios from 'axios';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { ArrowLeft, Trash2 } from 'react-feather'
import PageLayout from '../../libs/PageLayout';
import uiTexts from '../../config/text';
import globalLinks from '../../config/links';
import GradientButton from '../../components/GradientButton';
import globalSizes from '../../config/sizes';
import { globalReducer } from '../../store/reducer';
import { GlobalReducerActionType, KeyValueObject } from '../../types/shared'
import AuthContext from '../../context/auth';
import ProfilValidator from '../../utils/ProfilValidator';
import FormField from '../../components/FormField';
import PaypalForm from '../../components/PaypalForm';
import BankForm from '../../components/BankForm';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Toast from 'react-bootstrap/Toast';

const initState = {
  loading: false,
  errors: {},
  global: null,
  data: {},
};
const payoutTexts = uiTexts.account.payout;

export default function Payout() {
  const axiosCancel = Axios.CancelToken.source();
  let _isMounted = true;
  const authContext = useContext(AuthContext);
  const history = useHistory();
  const params = useParams<KeyValueObject<any>>();
  const { state: payoutDetails } = useLocation<KeyValueObject<any>>();
  if (payoutDetails === undefined || !payoutDetails?.id) {
    history.push(globalLinks.account.pendingPayouts);
  }
  initState.data = payoutDetails || {};
  const [state, dispatch] = useReducer(globalReducer, initState);
  const [showModal, setShowModal] = useState(false);
  const [showTaost, setShowTaost] = useState(false);

  const onClose = (type: GlobalReducerActionType, payload: any) => () => {
    dispatch({
      type,
      payload,
    })
  }
  // change the status of this payout
  const onChange = (evnt: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name: key, value } = evnt.target;
    dispatch({
      type: 'SET_FIELD',
      payload: {
        key,
        value
      }
    });
  }
  const onSubmit = async (evnt: React.FormEvent<HTMLFormElement>) => {
    evnt.preventDefault();
    _isMounted && dispatch({
      type: 'LOADING',
      payload: true,
    });

    try {
      const { payoutState } = state.data;
      const errors = ProfilValidator.payoutStatusData({ status: payoutState });

      if (errors) {
        dispatch({
          type: 'ERROR',
          payload: errors,
        })
      } else {
        const { data } = await Axios.put(`${globalLinks.api.account.editPayout}/${state.data?.payoutId}`, { status: payoutState }, {
          headers: {
            'X-Csrf-Token': authContext.csrf
          },
        });

        if (data.code === 'success') {
          _isMounted && dispatch({
            type: 'SUCCESS',
            payload: data.message,
          });
        }
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.code) {
        _isMounted && dispatch({
          type: 'ERROR',
          payload: error.response.data.errors,
        });
      } else {
        _isMounted && dispatch({
          type: 'ERROR',
          payload: { global: uiTexts.global.network },
        });
      }
    }
  }
  // open/close modal
  const onToggleModal = () => setShowModal(!showModal);
  /**
   * toggle taost
   */
  const onToggleTaostForRemove = () => {
    setShowTaost(!showTaost);
  }
  const onRemovePayout = async () => {
    _isMounted && dispatch({
      type: 'LOADING',
      payload: true,
    });
    try {
      const { data } = await Axios.delete(
        `${globalLinks.api.account.editPayout}/${state.data.payoutId}`,
        {
          headers: {
            'X-Csrf-Token': authContext.csrf
          },
          cancelToken: axiosCancel.token,
        }
      );

      if (data.code === 'success') {
        _isMounted && dispatch({
          type: 'SUCCESS',
          payload: data.message,
        });
        window.scrollTo(0, 0);
      }
    } catch (error) {
      let payload;
      if (error?.response && error?.response.data && error?.response.data.code) {
        payload = error?.response?.data?.errors;
      } else {
        payload = { global: uiTexts.global.network };
      }
      _isMounted && dispatch({
        type: 'ERROR',
        payload,
      });
    }
    _isMounted && setShowTaost(false);
  }

  useEffect(() => {
    return () => {
      _isMounted = false;
      axiosCancel.cancel('Operation canceled');
    }
  }, []);

  return (
    <PageLayout className='page__bloc pt-3 pt-md-5 pb-3 pb-md-5 main__block' title={`${payoutTexts.title} #${params.id}`}>
      {
        state.loading && (
          <div className='page__spinner d-flex justify-content-center align-items-center' data-testid='spinner'>
            <Spinner className='main-text-color' animation="border" role="status">
              <span className="sr-only">{uiTexts.global.loading}.</span>
            </Spinner>
          </div>
        )
      }
      <div className="action__block d-flex justify-content-between flex-wrap mb-3 mb-md-4">
        <h1 className='h4'>{`${payoutTexts.title} #${params.id}`}</h1>
        <div className='action__block'>
          <ButtonGroup className="mb-2">
            <Button variant='outline-danger' onClick={onToggleTaostForRemove}>
              <Trash2 className='mr-2' size={globalSizes.icon.default} />
              <span>{uiTexts.global.remove}</span>
            </Button>
          </ButtonGroup>
          <Toast show={showTaost} onClose={onToggleTaostForRemove} className='custom__toast'>
            <Toast.Header>
              <Trash2 className='mr-2' size={globalSizes.icon.default} />
              <strong className="mr-auto">{uiTexts.global.remove}</strong>
              <small>
                status:{` `}
                <span className={`custom__badge badge badge-${globalSizes.statusColor[state?.data?.payoutState]}`}>
                  {state?.data?.payoutState}
                </span>
              </small>
            </Toast.Header>
            <Toast.Body>
              <h4 className='h6 mb-3'>
                {uiTexts.global.notes.deletePayout}
              </h4>
              <ButtonGroup>
                <Button variant='outline-danger' onClick={onRemovePayout}>
                  <Trash2 className='mr-2' size={globalSizes.icon.default} />
                  <span>{uiTexts.global.sure}</span>
                </Button>
              </ButtonGroup>
            </Toast.Body>
          </Toast>
        </div>
      </div>
      {
        !!state.errors.global &&
        <Alert className='w-100' variant='danger' onClose={onClose('ERROR', {})} dismissible transition>{state.errors.global}</Alert>
      }
      {
        !!state.global &&
        <Alert className='w-100' variant='success' onClose={onClose('SUCCESS', null)} dismissible transition>{state.global}</Alert>
      }
      <Form onSubmit={onSubmit} className='form__block mw-100'>

        <Form.Row>
          <Col>
            <h4 className='h5 mb-4'>{uiTexts.account.payout.payoutInfos}</h4>
          </Col>
        </Form.Row>

        <Form.Row>
          <Col xs={12} sm={6}>
            <FormField name='' defaultValue={`${state.data?.userFname} ${state.data?.userLname}`} label={uiTexts.signup.form.fullname} readonly />
          </Col>
          <Col xs={12} sm={6}>
            <FormField name='' defaultValue={`${state.data?.userEmail}`} label={uiTexts.signup.form.email} readonly />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col xs={12} sm={6}>
            <FormField name='' defaultValue={`${state.data?.userIncome} ${globalSizes.currency}`} label={uiTexts.account.profil.income} readonly />
          </Col>
          <Col xs={12} sm={6}>
            <FormField name='' defaultValue={`${state.data?.payoutAmount} ${globalSizes.currency}`} label={uiTexts.account.payout.amount} readonly />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col xs={12} sm={6}>
            <Form.Group controlId="payoutState">
              <Form.Label>{uiTexts.global.itemStatus}</Form.Label>
              <Form.Control as="select" name='payoutState' value={state.data?.payoutState} onChange={onChange} size='lg' isInvalid={!!state.errors?.status} required>
                <option value='PENDING'>En attente</option>
                <option value='PAID'>Payée</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">{state.errors?.status}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} sm={6}>
            <Button size='lg' variant='outline-primary' onClick={onToggleModal} block>
              {uiTexts.account.payout.payoutAccount}
            </Button>
          </Col>
        </Form.Row>

        <Form.Row>
          <Col className='d-flex justify-content-end'>
            <Link to={globalLinks.account.pendingPayouts} className='btn btn -lg btn-outline-primary d-flex align-items-center mr-2'>
              <ArrowLeft size={globalSizes.icon.default} className='mr-2' />
              {uiTexts.global.cancel}
            </Link>
            <GradientButton type='submit' size='lg' disabled={state.loading} className='text-white'>
              {
                state.loading
                  ? uiTexts.global.loading
                  : uiTexts.global.save
              }
            </GradientButton>
          </Col>
        </Form.Row>

      </Form>

      <Modal size='lg' show={showModal} onHide={onToggleModal}>
        <Modal.Header className='border-0' closeButton>
          <Modal.Title>Compte {state.data?.type} de {state.data?.owner}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='form-group__container'>
          {
            state.data?.type === 'paypal'
              ? (
                <PaypalForm
                  state={{ ...state, data: state.data }}
                  onSubmit={undefined} onClose={undefined} readonly />
              )
              : (
                <BankForm
                  state={{ ...state, data: state.data }}
                  onSubmit={undefined} onClose={undefined} readonly />
              )
          }
        </Modal.Body>
        <Modal.Footer className='border-0'>
          <Button variant="outline-danger" onClick={onToggleModal} size='lg'>
            {uiTexts.global.close}
          </Button>
        </Modal.Footer>
      </Modal>

    </PageLayout >
  )
}
