import React from 'react'
import SpinnerLogo from '../SpinnerLogo';

export default function LoaderIndicator({ loading = false, testid = '', className='' }) {
  return (
    loading
      ? (
        <div className={`page__spinner d-flex justify-content-center align-items-center ${className}`} data-testid={testid}>
          <SpinnerLogo className='h-100' />
        </div>
      )
      : null
  );
}
