import Axios from 'axios';
import React, { useEffect, useReducer } from 'react'
import { Download, Eye } from 'react-feather';
import { Link } from 'react-router-dom';
import AlertMessage from '../../../components/AlertMessage';
import LoaderIndicator from '../../../components/LoaderIndicator';
import TableList from '../../../components/TableList';
import globalLinks from '../../../config/links';
import globalSizes from '../../../config/sizes';
import uiTexts from '../../../config/text';
import useAbortController from '../../../hooks/use-abort-controller';
import PageLayout from '../../../libs/PageLayout';
import { globalReducer } from '../../../store/reducer';

const initialState: any = {
  loading: true,
  errors: {},
  global: null,
  data: {},
};
export default function EmptyBarcodeIsrc() {
  const { newAbortSignal, cancelPreviousRequest } = useAbortController();
  const [state, dispatch] = useReducer(globalReducer, initialState);
  const alertMessage = state?.global || state?.errors?.global;
  const alertVariant = state?.global ? "success" : "danger";
  const showAlert = !!state?.global || !!state?.errors?.global;

  useEffect(() => {
    const loadData = async () => {
      try {
        cancelPreviousRequest();
        const { data: trackData } = await Axios.get(globalLinks.api.tools.emptyISRCBarcode, {
          signal: newAbortSignal()
        });
        dispatch({
          type: "RESET_STATE",
          payload: {
            loading: false,
            errors: trackData?.errors || null,
            data: trackData?.data || [],
          }
        })
      } catch (error) {
        let errors: Record<string, string> = {};

        if (error?.response?.data?.code) {
          errors = error.response.data.errors;
        } else {
          errors = {
            global: uiTexts.global.network
          };
        }

        dispatch({
          type: 'ERROR',
          payload: errors,
        });
      }
    }
    loadData();
  }, []);

  return (
    <PageLayout className='page__bloc pt-3 pt-md-5 pb-3 pb-md-5 main__block' title={uiTexts.global.sidebar.tools.emptyIsrcBarcode}>
      <LoaderIndicator loading={state.loading} />
      <div className="action__block d-flex justify-content-between flex-wrap mb-3 mb-md-4">
        <h1 className='h4'>{uiTexts.global.sidebar.tools.emptyIsrcBarcode}</h1>
      </div>
      <AlertMessage
        message={alertMessage}
        variant={alertVariant}
        show={showAlert}
      />
      <h2 className='h5 mb-3'>{uiTexts.global.sidebar.tools.emptyIsrc}</h2>
      <TableList headers={["ISRC", "Titre de piste", "Titre de sortie", ""]}>
        {
          state.loading
            ? (
              <tr>
                <td colSpan={3}>
                  <h4 className='h5 text-muted text-center'>
                    <Download size={globalSizes.icon.xm} className='mr-2' />
                    {uiTexts.global.loading}
                  </h4>
                </td>
              </tr>
            )
            : (
              state.data?.tracks?.map((track: Record<string, string>, index: number) => (
                <tr
                  className={`table-list__item`}
                  key={`src-release-track-${track.trackId}-${index}`}
                >
                  <td className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                    {track?.isrc}
                  </td>
                  <td className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle text-capitalize'>
                    {track?.trackTitle}
                  </td>
                  <td className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                    {track?.libraryTitle}
                    {
                      !!track?.userId === false && (
                        <div>Pas de compte</div>
                      )
                    }
                  </td>
                  <td className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                    <Link
                      to={`${globalLinks.library.release}/${track?.libraryId}`}
                      title={uiTexts.global.cancel}
                      className='btn btn-outline-primary'
                    >
                      <Eye size={globalSizes.icon.default} className='mr-2' />
                      {uiTexts.global.visit}
                    </Link>
                  </td>
                </tr>
              ))
            )
        }
      </TableList>
      <h2 className='h5 mt-4 mb-3'>{uiTexts.global.sidebar.tools.emptyBarcode}</h2>
      <TableList headers={["UPC", "Titre de sortie", "Status", ""]}>
        {
          state.loading
            ? (
              <tr>
                <td colSpan={3}>
                  <h4 className='h5 text-muted text-center'>
                    <Download size={globalSizes.icon.xm} className='mr-2' />
                    {uiTexts.global.loading}
                  </h4>
                </td>
              </tr>
            )
            : (
              state.data?.libraries?.map((library: Record<string, string>, index: number) => (
                <tr
                  className={`table-list__item`}
                  key={`src-release-${library.id}-${index}`}
                >
                  <td className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                    {library?.barcode}
                  </td>
                  <td className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle text-capitalize'>
                    {library?.title}
                    {
                      !!library?.userId === false && (
                        <div>Pas de compte</div>
                      )
                    }
                  </td>
                  <td className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                    <span className={`custom__badge badge badge-${globalSizes.statusColor[library?.status]}`}>
                      {uiTexts.global.status[library?.status]}
                    </span>
                  </td>
                  <td className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                    <Link
                      to={`${globalLinks.library.release}/${library?.id}`}
                      title={uiTexts.global.cancel}
                      className='btn btn-outline-primary'
                    >
                      <Eye size={globalSizes.icon.default} className='mr-2' />
                      {uiTexts.global.visit}
                    </Link>
                  </td>
                </tr>
              ))
            )
        }
      </TableList>
    </PageLayout>
  );
}
