import React from 'react';
import { Helmet } from 'react-helmet';

interface SeoProps {
  title: string;
  lang?: string;
  titleTemplate?: string|null;
}

export default function Seo({ lang='fr', title, titleTemplate=null }: SeoProps) {
  titleTemplate = titleTemplate === null ? `` : `%s | ${titleTemplate}`;
  return (
    <Helmet
      htmlAttributes={{ lang, }}
      title={title}
      titleTemplate={titleTemplate}
    />
  );
}