import React, { ChangeEvent, FormEvent, useContext, useReducer, } from 'react';
import PageLayout from '../../libs/PageLayout';
import uiTexts from '../../config/text';
import { globalReducer } from '../../store/reducer';
import { useParams } from 'react-router-dom';
import LoaderIndicator from '../../components/LoaderIndicator';
import AlertMessage from '../../components/AlertMessage';
import { useTickets } from '../../hooks/use-tickets';
import TableList from '../../components/TableList';
import { toolsMessages } from '../../config/messages/tools';
import globalSizes from '../../config/sizes';
import { Slash } from 'react-feather';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import TicketForm from '../../components/TicketForm';
import AuthContext from '../../context/auth';
import axios, { AxiosRequestConfig } from 'axios';
import globalLinks from '../../config/links';
import useAbortController from '../../hooks/use-abort-controller';

const initState = {
  loading: false,
  errors: {},
  global: null,
  data: {
    showModal: false,
    ticket: {}
  },
};
const ticketText = uiTexts.tools;
export default function Tickets() {
  const params = useParams<{ status: "opened" | "closed" }>();
  const { csrf } = useContext(AuthContext);
  const { newAbortSignal, cancelPreviousRequest } = useAbortController();
  const [{ loading: ticketsLoading, data: tickets }, setTickets] = useTickets(params.status);
  const [state, dispatch] = useReducer(globalReducer, initState);
  const alertMessage = state.global || state.errors.global;
  const alertVariant = !!state.global ? "success" : "danger";
  const onClose = () => {
    dispatch({
      type: "SUCCESS",
      payload: null,
    })
  }
  const onOpenTicket = (ticket: Record<string, string> = {}) => () => {
    dispatch({
      type: 'SET_FIELDS',
      payload: {
        ...state.data,
        ticket,
        showModal: !state.data.showModal,
      }
    });
  }
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.currentTarget;
    const finalValue = type === "checkbox" ? checked : value;

    dispatch({
      type: "SET_FIELD",
      payload: {
        key: "ticket",
        value: {
          ...state.data.ticket,
          [name]: finalValue
        }
      }
    });
  }
  // update tickets list after a change of the status
  const removeTicketFromList = (id: string | number, newStatus: string) => {
    const newTickets = tickets.filter((ticket: Record<string, string | number>) => {
      return !(ticket.id === id && newStatus !== ticket.status);
    });
    setTickets({
      type: "SET_LIST_WITH_MESSAGE",
      payload: {
        message: null,
        data: newTickets
      }
    });
  }
  const onEditTicket = async (event: FormEvent) => {
    event.preventDefault();
    dispatch({
      type: "LOADING",
      payload: true
    });

    try {
      cancelPreviousRequest();
      const options: AxiosRequestConfig<any> = {
        headers: {
          'X-Csrf-Token': csrf,
        },
        signal: newAbortSignal()
      };
      const { ticket: newTicket } = state.data;
      const { data: response } = await axios.put(
        `${globalLinks.api.tools.ticket.root}/${newTicket.id}`,
        newTicket,
        options
      );

      if (response.code === 'success') {
        dispatch({
          type: 'RESET_STATE',
          payload: {
            ...initState,
            global: response.message,
            data: {
              ...initState.data,
              showModal: false,
            }
          }
        });
        removeTicketFromList(newTicket.id, newTicket.status);
      } else {
        throw new Error('failed');
      }
    } catch (error) {
      let payload;
      if (error?.response?.data?.code) {
        payload = error.response.data.errors;
      } else {
        payload = { global: uiTexts.global.network };
      }
      dispatch({
        type: 'ERROR',
        payload
      });
    }
  }

  return (
    <PageLayout className='page__bloc pt-3 pt-md-5 pb-3 pb-md-5 main__block' title={ticketText.ticket.title}>
      <LoaderIndicator
        loading={ticketsLoading}
        className='z_index_2'
      />
      <div className="action__block mb-3 mb-md-4">
        <h1 className='h4'>{ticketText.ticket.title}</h1>
      </div>
      <AlertMessage
        message={alertMessage}
        variant={alertVariant}
        show={!!state.errors.global || !!state.global}
        onClose={onClose}
      />
      <TableList
        headers={["#", toolsMessages.ticket.title.label, "Etat", toolsMessages.ticket.url.label, "Timing"]}
      >
        {
          tickets.map((ticket: Record<string, string>) => {
            const badgeClassName = ticket.status === 'CLOSED'
              ? ' badge-success'
              : ' badge-warning';

            return (
              <tr
                key={`ticket-${ticket.id}`}
                className='table-list__item'
                onClick={onOpenTicket(ticket)}
              >
                <td className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                  {`#dt-${ticket?.id}`}
                </td>
                <td className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                  {ticket?.title}
                </td>
                <td className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                  <span className={`badge custom__badge${badgeClassName}`}>
                    {uiTexts.global.status[ticket?.status]}
                  </span>
                </td>
                <td className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                  {ticket.url}
                </td>
                <td className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                  <div className="flex flex-column">
                    <div>{toolsMessages.ticket.startAt.label}: {ticket?.startAt}</div>
                    <div>{toolsMessages.ticket.endAt.label}: {ticket?.endAt}</div>
                  </div>
                </td>
              </tr>
            )
          })
        }
        {
          tickets?.length === 0 && (
            <tr>
              <td colSpan={5} className='py-4'>
                <h4 className='h5 text-muted text-center mb-4'>
                  <Slash size={globalSizes.icon.xm} className='mr-2' />
                  {uiTexts.global.empty}
                </h4>
              </td>
            </tr>
          )
        }
      </TableList>

      <Modal
        show={state.data.showModal}
        onHide={onOpenTicket()}
        animation
        centered
      >
        <Modal.Header className='border-0' closeButton>
          <Modal.Title>
            {uiTexts.tools.ticket.edit}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='show-grid pl-0 pr-0'>
          <Container>
            <TicketForm
              data={state.data.ticket}
              loading={state.loading}
              errors={state.errors}
              onChange={onChange}
              onSubmit={onEditTicket}
            />
          </Container>
        </Modal.Body>
      </Modal>

    </PageLayout>
  )
}
