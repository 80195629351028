import React from 'react';
import Table from 'react-bootstrap/Table';
import { KeyValueObject } from '../../types/shared';
import './style.css';

interface TableListProps {
  headers: string[];
  children: React.ReactNode;
}

/**
 * render a list of items in <table />
 * @param props 
 */
export default function TableList({ headers, children }: TableListProps) {

  return (
    <div className='tablie-list__wrapper border border_color_light-blue border_radius_3 overflow-hidden'>
      <Table className='table-list__block bg-white mb-0' responsive hover>
        <thead className='table-list__header'>
          <tr className='table-list__item'>
            {
              headers.map(
                (header: string) =>
                (
                  <th className='table-list__header-cell fl-uppercase pt-3 pl-4 pb-3 pr-4 align-middle' key={header}>
                    {header}
                  </th>
                )
              )
            }
          </tr>
        </thead>
        <tbody className='table-list__body'>
          {children}
        </tbody>
      </Table>
    </div>
  )
}
