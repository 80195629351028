import React, { useContext } from 'react';
import LoaderIndicator from '../../components/LoaderIndicator';
import globalLinks from '../../config/links';
import PrivateRoute from '../../libs/PrivateRoute';
import EmptyBarcodeIsrc from '../../pages/tools/EmptyBarcodeIsrc';
import ISRCBarcodeRedundancy from '../../pages/tools/IsrcBarcodeRedundancy';
import NamingRedundancy from '../../pages/tools/NamingRedundancy';
import TracksISRC from '../../pages/tools/TracksISRC';
import { CustomRoutesContainerProps } from '../../types/shared';
import Tickets from '../../pages/Tickets';
import { renderRouteIfAllowedTo, renderRouteIfOneOfExist } from '../../utils/ui/capabilities';
import AuthContext from '../../context/auth';

export default function ToolsRoutes({ auth }: CustomRoutesContainerProps) {
  const { userCapabilities } = useContext(AuthContext);

  return (
    <React.Fragment>
      <React.Suspense
        fallback={<LoaderIndicator loading={true} />}
      >
        {
          renderRouteIfAllowedTo(
            userCapabilities,
            ["edit_tools"],
            <>
              <PrivateRoute
                exact
                path={globalLinks.tools.editIsrcOrBarcode}
                auth={auth}
              >
                <TracksISRC />
              </PrivateRoute>
            </>
          )
        }
        {
          renderRouteIfOneOfExist(
            userCapabilities,
            ["edit_tools", "read_tools"],
            <PrivateRoute
              exact
              path={globalLinks.tools.ticket.listByStatus}
              auth={auth}
            >
              <Tickets />
            </PrivateRoute>
          )
        }
        {
          renderRouteIfAllowedTo(
            userCapabilities,
            ["read_tools"],
            <>
              <PrivateRoute
                exact
                path={globalLinks.tools.isrcRedundancy}
                auth={auth}
              >
                <ISRCBarcodeRedundancy />
              </PrivateRoute>
              <PrivateRoute
                exact
                path={globalLinks.tools.emptyISRCBarcode}
                auth={auth}
              >
                <EmptyBarcodeIsrc />
              </PrivateRoute>
              <PrivateRoute
                exact
                path={globalLinks.tools.namingRedundancy}
                auth={auth}
              >
                <NamingRedundancy />
              </PrivateRoute>
            </>
          )
        }
      </React.Suspense>
    </React.Fragment>
  );
}
