import React, { useContext, useEffect, useReducer } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ChevronLeft } from 'react-feather'
import Button from 'react-bootstrap/Button';
import Axios from 'axios';
import PageLayout from '../../libs/PageLayout';
import uiTexts from '../../config/text';
import globalSizes from '../../config/sizes';
import { globalReducer } from '../../store/reducer';
import AccountValidator from '../../utils/ProfilValidator';
import globalLinks from '../../config/links';
import AuthContext from '../../context/auth';
import { GlobalReducerActionType } from '../../types/shared';
import PaypalForm from '../../components/PaypalForm';

const initialState = {
  loading: false,
  errors: {},
  global: null,
  data: {},
};

export default function PaypalAccount() {
  let _isMounted = true;
  const authContext = useContext(AuthContext);
  const history = useHistory();
  const { state: bankAccount } = useLocation();
  initialState.data = bankAccount || {};
  const [state, dispatch] = useReducer(globalReducer, initialState);
  // redirect user if no account passed to history state
  if (!state.data?.id) {
    history.push(globalLinks.account.profiles);
  }
  // change new date fired by onchange event on input
  const onChange = (evnt: React.ChangeEvent<HTMLInputElement>) => {
    const { name: key, value } = evnt.target;
    dispatch({
      type: 'SET_FIELD',
      payload: { key, value },
    });
  }
  // send update request
  const onSubmit = async (evnt: React.FormEvent<HTMLFormElement>) => {
    evnt.preventDefault();
    _isMounted && dispatch({
      type: 'LOADING',
      payload: true,
    });
    const { email, owner, holderAddress, holderAddress2, holderCity, holderCountry, holderPostcode, } = state.data;
    const newDate = { email, owner, holderAddress, holderAddress2, holderCity, holderCountry, holderPostcode, };

    try {
      const errors = AccountValidator.paypalAccountData(newDate);
      if (errors) {
        dispatch({
          type: 'ERROR',
          payload: errors,
        });
      } else {
        const { data } = await Axios.put(`${globalLinks.api.account.updateBilling}/${state.data.id}/paypal`, newDate, {
          headers: {
            'X-Csrf-Token': authContext.csrf,
          },
        });
        if (data.code === 'success') {
          _isMounted && dispatch({
            type: 'SUCCESS',
            payload: data.message,
          });
          window.scrollTo(0, 0);
        }
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.code) {
        _isMounted && dispatch({
          type: 'ERROR',
          payload: error.response.data.errors,
        });
      } else {
        _isMounted && dispatch({
          type: 'ERROR',
          payload: { global: uiTexts.global.network },
        });
      }
    }
  }
  // close alert message
  const onClose = (type: GlobalReducerActionType, payload: any) => () => {
    _isMounted && dispatch({
      type,
      payload,
    });
  }

  useEffect(() => {
    return () => {
      _isMounted = false;
    }
  }, []);

  return (
    <PageLayout className='page__bloc pt-3 pt-md-5 pb-3 pb-md-5 main__block' title={uiTexts.account.billing.paypal.title}>
      <h1 className="h4 mb-5">
        <Button variant='light' size='sm' className='mr-2' onClick={history.goBack}>
          <ChevronLeft size={globalSizes.icon.xm} />
        </Button>
        {uiTexts.account.billing.paypal.title}
      </h1>
      <PaypalForm onSubmit={onSubmit} onClose={onClose} onChange={onChange} state={state} />
    </PageLayout>
  )
}
