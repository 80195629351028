import React, { useEffect, useReducer, } from 'react';
import { useHistory, useParams, } from 'react-router-dom';
import Axios from 'axios';
import ListGroup from 'react-bootstrap/ListGroup';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import { Slash } from 'react-feather'
import PageLayout from '../../libs/PageLayout';
import uiTexts from '../../config/text';
import globalLinks from '../../config/links';
import globalSizes from '../../config/sizes';
import { globalReducer } from '../../store/reducer';
import { GlobalReducerActionType, KeyValueObject } from '../../types/shared'
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

const initState = {
  loading: true,
  errors: {},
  global: null,
  data: [],
};

export default function InvoicesList({ apiUrl, status }: KeyValueObject<string>) {
  const axiosCancel = Axios.CancelToken.source();
  let _isMounted = true;
  const [state, dispatch] = useReducer(globalReducer, initState);
  const history = useHistory();
  const params = useParams<KeyValueObject<string>>();
  /**
   * close the alert message
   */
  const onClose = (type: GlobalReducerActionType, payload: any) => () => {
    dispatch({
      type,
      payload,
    })
  }
  /**
   * navigate to bank/paypal account for full see/edit it details
   * @param item user account details
   */
  const onNavigateTo = (item: any) => () => {
    if (item) {
      const historyOptions = getNavigationConfig(item);
      history.push(historyOptions.pathname, historyOptions.state);
    }
  }
  /**
   * get configuration of history.push
   * @param item user account details
   */
  const getNavigationConfig = (item: KeyValueObject<any>) => {
    const pathname = globalLinks.account.invoice.replace(':id', item.id);

    return {
      pathname,
      state: item
    }
  }

  /**
   * render a list of invoices
   */
  const renderListItem = () => {
    if (!state.loading && !state.data.length) {
      return (
        <h4 className='h5 text-muted text-center'>
          <Slash size={globalSizes.icon.xm} className='mr-2' />
          {uiTexts.global.noOptions}
        </h4>
      );
    } else {
      return state.data.map((invoice: KeyValueObject<any>) => {
        let createdAt;
        let orderItems;
        let transactionDetails;
        try {
          createdAt = new Date(invoice.createdAt);
          createdAt = format(createdAt, globalSizes.dates.fr.whole, {
            locale: fr
          });
          orderItems = JSON.parse(invoice.orderItems);
          transactionDetails = JSON.parse(invoice.transactionDetails);
        } catch (err) {
          createdAt = 'y a long temps';
        }
        invoice = {
          ...invoice,
          orderItems,
          transactionDetails
        };
        return (
          <ListGroup.Item role='listitem' key={invoice.id} onClick={onNavigateTo(invoice)} action className='d-flex justify-content-between align-items-center flex-wrap border_color_light-blue'>
            <div className="account-detail__block">
              <div>
                <span>{`${uiTexts.account.invoices.order} `}</span>
                <strong>{invoice.id}</strong>
              </div>
              <div>
                <span>{`${uiTexts.account.payout.amount} `}</span>
                <span className='font-weight-bold'>{invoice.totalAmountByLocation}</span>
                <span>{orderItems?.currency}</span>
              </div>
            </div>
            <div className="account-date__block">
              <div className='text-muted'>
                <span className={`badge custom__badge${invoice?.status === 'PAID' ? ' badge-primary' : ' badge-warning'}`}>
                  {uiTexts.global.status[invoice?.status]}
                </span>
              </div>
            </div>
            <div className="account-action__block">
              <span className='date__block'>
                {createdAt}
              </span>
            </div>
          </ListGroup.Item>
        );
      });
    }
  }

  useEffect(() => {
    const loadInvoicessData = async () => {
      apiUrl = params?.id
        ? `${globalLinks.api.account.userInvoices}/${params.id}/list`
        : apiUrl;

      try {
        const { data } = await Axios.get(
          apiUrl,
          {
            cancelToken: axiosCancel.token,
          }
        );

        if (data.code === 'success') {
          _isMounted && dispatch({
            type: 'SET_LIST',
            payload: data.data,
          });
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.code) {
          _isMounted && dispatch({
            type: 'ERROR',
            payload: error.response.data.errors,
          });
        } else {
          _isMounted && dispatch({
            type: 'ERROR',
            payload: { global: uiTexts.global.network },
          });
        }
      }
    }
    loadInvoicessData();
    return () => {
      _isMounted = false;
      axiosCancel.cancel('Operation canceled');
    }
  }, []);

  return (
    <PageLayout className='page__bloc pt-3 pt-md-5 pb-3 pb-md-5 main__block' title={uiTexts.account.invoices.title}>
      {
        state.loading && (
          <div className='page__spinner d-flex justify-content-center align-items-center' data-testid='spinner'>
            <Spinner className='main-text-color' animation="border" role="status">
              <span className="sr-only">{uiTexts.global.loading}.</span>
            </Spinner>
          </div>
        )
      }
      <div className="action__block d-flex justify-content-between flex-wrap mb-3 mb-md-4">
        <h1 className='h4'>{uiTexts.account.invoices.title}</h1>
      </div>
      {
        !!state.errors.global &&
        <Alert className='w-100' variant='danger' onClose={onClose('ERROR', {})} dismissible transition>{state.errors.global}</Alert>
      }
      {
        !!state.global &&
        <Alert className='w-100' variant='success' onClose={onClose('SUCCESS', null)} dismissible transition>{state.global}</Alert>
      }
      <div className="list__block mt-3 mt-md-4">
        {renderListItem()}
      </div>

    </PageLayout>
  )
}
