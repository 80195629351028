import Papa, { ParseResult } from 'papaparse';

const csvTransformHeader = (header: string) => {
  return header.toLowerCase();
}
/**
 * Parse csv file to json
 * @param file selected file(csf format)
 * @returns json format of the file content
 */
export const parseCSVFile = <R = unknown>(file: File, options: Partial<Papa.ParseLocalConfig<unknown, File>> = {}): Promise<ParseResult<R>> => {
  return new Promise((resolve) => {
    Papa.parse(
      file,
      {
        header: true,
        transformHeader: csvTransformHeader,
        dynamicTyping: true,
        skipEmptyLines: true,
        complete: (releases: ParseResult<R>) => {
          resolve(releases);
        },
        ...options
      }
    )
  });
}

type GenerateCSVFileReturnType = {
  content: string;
  link: string;
}
/**
 * Generate csv file content & its local link(blob:)
 * @param data csf file content
 * @param options unparse options such as delimiter ..
 * @returns content of the generated file and its local link
 */
export const generateCSVFile = (data: any, options?: Papa.UnparseConfig | undefined): Promise<GenerateCSVFileReturnType> => {
  return new Promise((resolve) => {
    const csvContent = Papa.unparse(data, options);
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const link = window.URL.createObjectURL(blob);

    resolve({
      content: csvContent,
      link
    });
  });
}