import React, { useReducer, useContext, ChangeEvent } from 'react';
import Axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Gift, Save } from 'react-feather';
import Form from 'react-bootstrap/Form';
import globalSizes from '../../config/sizes';
import globalLinks from '../../config/links';
import uiTexts from '../../config/text';
import { globalReducer } from '../../store/reducer';
import LoaderIndicator from '../LoaderIndicator';
import AlertMessage from '../AlertMessage';
import ProfilValidator from '../../utils/ProfilValidator';
import AuthContext from '../../context/auth';
import FormField from '../FormField';
import Select, { ActionMeta, ValueType } from 'react-select';
import { ProfileCSVReleasesFormPropsTypes } from '../ProfileCSVReleasesForm/types';
import Col from 'react-bootstrap/Col';
import { useAccounts } from '../../hooks/use-accounts';
import { useParams } from 'react-router-dom';

function ProfileReferralsForm({ show, onToggleModal }: Omit<ProfileCSVReleasesFormPropsTypes, "user">) {
  const [{ data: accountsList, loading }] = useAccounts();
  const params = useParams<{ id: string }>();
  const { csrf } = useContext(AuthContext);
  const [state, dispatch] = useReducer(globalReducer, {
    loading: false,
    errors: null,
    data: {}
  });
  const accounts = accountsList.reduce((acc: Record<string, string>[], account: Record<string, string>) => {
    if (`${account.id}` !== `${params.id}`) {
      acc.push({
        value: account?.id,
        label: `${account?.fname} ${account?.lname}`,
      });
    }
    return acc;
  }, []);
  const showAlertMessage = !!state.errors?.global || !!state.global;
  const alertMessage = state.errors?.global || state.global;
  const alertVariant = state.errors?.global ? "danger" : "success";
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch({
      type: 'LOADING',
      payload: true
    });
    try {
      const referralsData = {
        ...state.data,
        referrerId: params.id
      };
      const errors = ProfilValidator.referralData(referralsData);

      if (errors !== false) {
        dispatch({
          type: 'ERROR',
          payload: errors,
        });
        return false;
      }

      const { data: response } = await Axios.post(
        `${globalLinks.api.account.referrals.add}`,
        referralsData,
        {
          headers: {
            'X-Csrf-Token': csrf,
          },
        }
      );

      if (response.code === 'success') {
        dispatch({
          type: 'SUCCESS',
          payload: response.message,
        });
      } else {
        throw new Error('failed');
      }
    } catch (error) {
      let payload;
      // @ts-ignore
      if (error.response && error.response.data && error.response.data.code) {
        // @ts-ignore
        payload = error.response.data.errors;
      } else {
        payload = { global: uiTexts.global.network };
      }
      dispatch({
        type: 'ERROR',
        payload
      });
    }
  }
  const noOptionsMessage = () => uiTexts.global.noOptions;
  const onChangeSelect = ({ value }: ValueType<any>, { name }: ActionMeta<any>) => {
    dispatch({
      type: 'SET_FIELD',
      payload: {
        key: name,
        value: value,
      }
    })
  }
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name: key, value } = event.target;
    dispatch({
      type: "SET_FIELD",
      payload: {
        key,
        value
      }
    })
  }

  return (
    <Modal
      show={show}
      onHide={onToggleModal}
      centered
    >
      <Modal.Body>
        <Modal.Title className='mb-4 position-sticky bg-white sticky_top'>
          <Gift size={globalSizes.icon.default} className='mr-2' />
          {uiTexts.account.profil.referrals.add}
        </Modal.Title>
        <LoaderIndicator loading={state.loading || loading} className='z_index_2' />
        <AlertMessage
          show={showAlertMessage}
          message={alertMessage}
          variant={alertVariant}
        />
        <Form onSubmit={onSubmit}>
          <Form.Row>
            <Col>
              <Form.Group id='coverDetailsYear' className='custom__select-block'>
                <Select
                  name='referredId'
                  options={accounts}
                  placeholder={uiTexts.account.profil.referrals.referred}
                  // defaultValue={defaultContractDuration}
                  className={`custom__select ${state.errors?.referredId && "custom__select-error"}`}
                  onChange={onChangeSelect}
                  noOptionsMessage={noOptionsMessage}
                />
                {
                  !!state.errors?.referredId && (
                    <Form.Control.Feedback className='d-block' type="invalid">
                      {state.errors?.referredId}
                    </Form.Control.Feedback>
                  )
                }
                <Form.Label>{uiTexts.account.profil.referrals.referred}</Form.Label>
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <FormField
                name="percentile"
                label={uiTexts.account.profil.referrals.percentile}
                onChange={onChange}
                defaultValue={state.data?.percentile || ""}
                error={state.errors?.percentile}
                type="number"
              />
            </Col>
          </Form.Row>
          <div className="d-flex justify-content-end mt-n2 position-sticky bg-white sticky_bottom">
            <Button variant='outline-danger' className='border_radius_left-side_lg' onClick={onToggleModal}>
              {uiTexts.global.close}
            </Button>
            <Button
              variant='outline-success'
              className='border_radius_right-side_lg'
              disabled={state.loading}
              type='submit'
            >
              <Save size={globalSizes.icon.default} className='mr-2' />
              {uiTexts.global.save}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default ProfileReferralsForm;