import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import { Sliders, X } from 'react-feather';
import DatePicker, { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';

import FormField from '../../components/FormField';
import reportMessages from '../../config/messages/report';
import 'react-datepicker/dist/react-datepicker.css';
import parse from 'date-fns/parse';
import globalSizes from '../../config/sizes';
import ReportsFilterFormPropsType from './types';
import uiTexts from '../../config/text';

registerLocale('fr', fr);
/**
 * Reports filter form
 */
function ReportsFilterForm({ filters, onFilter, onSelectDate }: ReportsFilterFormPropsType) {
  const CustomDateInput = React.forwardRef(({ value, onClick, error, name, label }: any, ref: any) => {
    return (
      <FormField
        autoComplete='off'
        defaultValue={value}
        error={error || null}
        label={label}
        name={name}
        onClick={onClick}
        readonly={false}
      />
    );
  });
  const fromDate = filters?.fromDate !== null
    ? new Date(parse(String(filters?.fromDate), globalSizes.dates.en.format, new Date()))
    : undefined;
  const toDate = filters?.toDate !== null
    ? new Date(parse(String(filters?.toDate), globalSizes.dates.en.format, new Date()))
    : undefined;

  return (
    <Form className='filters__container action__block z_index_2 position-relative'>
      <Form.Row>
        <Col className='mb-2' xs={12} md={4} lg={5}>
          <DatePicker
            locale='fr'
            selected={fromDate}
            onChange={onSelectDate('fromDate')}
            customInput={
              <CustomDateInput
                name='fromDate'
                label={reportMessages.fromDate.label}
              />
            }
            monthClassName={() => 'calendar-month__date'}
            wrapperClassName='w-100'
            placeholderText={reportMessages.fromDate.label}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            showFullMonthYearPicker />
        </Col>
        <Col className='mb-2' xs={12} md={4} lg={5}>
          <DatePicker
            locale='fr'
            selected={toDate}
            onChange={onSelectDate('toDate')}
            customInput={
              <CustomDateInput
                name='toDate'
                label={reportMessages.toDate.label}
              />
            }
            monthClassName={() => 'calendar-month__date'}
            wrapperClassName='w-100'
            placeholderText={reportMessages.toDate.label}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            showFullMonthYearPicker />
        </Col>
        <Col className='mt-n3 mt-md-0 mb-3' xs={12} md={4} lg={2}>
          <ButtonGroup>
            <Button variant='outline-primary' size='lg' onClick={onFilter('on')}>
              <Sliders size={globalSizes.icon.default} />
              <span className='d-inline d-md-none ml-2'>{uiTexts.global.filter}</span>
            </Button>
            <Button variant='outline-danger' size='lg' onClick={onFilter('off')}>
              <X size={globalSizes.icon.default} />
              <span className='d-inline d-md-none ml-2'>{uiTexts.global.cancel}</span>
            </Button>
          </ButtonGroup>
        </Col>
      </Form.Row>
    </Form>
  );
}

export default ReportsFilterForm;