import Joi, { Types } from "joi";
import { buildUsefulJoiErrorObject } from "./tools";
import libraryMessages from "../config/messages/library";
import globalMessages from "../config/text";
import { KeyValueObject } from "../types/shared";

/**
 * Authentication data validation and verification include all
 * checking before resuming the rest of process like save to DB 
 */
class LibraryValidator {

  /**
   * get list of errors if there any
   * @param dataSchema Joi schema
   * @param data data to validate
   */
  private static getErrorsOfSchema(dataSchema: Joi.ObjectSchema<any> | Joi.ArraySchema, data: any) {
    const { error: joiErrors } = dataSchema.validate(data);

    if (joiErrors !== undefined) {
      return buildUsefulJoiErrorObject(joiErrors.details);
    } else {
      return false;
    }
  }

  /**
   * generate a Joi schema
   * @param msg error message to return
   * @param type type of Joi
   * @param isRequired optional or required
   */
  private static generateJoiSchema(msg: string, type: Types, isRequired = true) {
    const required = isRequired ? 'required' : 'optional';
    return Joi[type]()[required]().label(msg);
  }

  /**
   * generate Joi string schema
   * @param msg error message
   * @param required required or optional
   * @param allow allowed value like empty string ''
   * @param min the number minimum of chars
   * @param max the number maximum of chars
   */
  private static generateJoiStringSchema(msg: string, required?: boolean, allow?: string, min?: number, max?: number) {
    let schema = Joi.string();
    required = required !== undefined ? required : true;

    if (allow) {
      schema = schema.allow(allow, null);
    }
    if (min) {
      schema = schema.min(min)
    }
    if (max) {
      schema = schema.max(max);
    }

    schema = required ? schema.required() : schema.optional();
    schema = schema.label(msg);

    return schema;
  }

  /**
   * generate a string optional value by allowing empty string and  null value
   * @param msg error message
   */
  private static generateOptionalValues(msg: string) {
    return Joi.string().optional().allow('', null).label(msg);
  }

  /**
   * library data validation
   * @param data contain data to validate
   */
  static verifyLibraryData(data: KeyValueObject<string | number | KeyValueObject<string>> | null, withCover?: false) {

    const dateRegExp = new RegExp(/^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/);
    const dataSchema = Joi.object().keys({
      genre: this.generateJoiStringSchema(libraryMessages.genre.errorMsg, true, undefined, 2),
      originalRealeaseDate: this.generateJoiStringSchema(libraryMessages.originalRealeaseDate.errorMsg, true, undefined, 2, 30),
      title: this.generateJoiStringSchema(libraryMessages.title.errorMsg, true, undefined, 1),
      recordingOwnerName: this.generateJoiStringSchema(libraryMessages.recordingOwnerName.errorMsg, true, undefined, 1),
      yearOfRegistration: this.generateJoiSchema(libraryMessages.yearOfRegistration.errorMsg, 'number', true),
      coverDetailsOwner: this.generateJoiStringSchema(libraryMessages.coverDetails.errorMsg, withCover),
      coverDetailsYear: this.generateJoiSchema(libraryMessages.coverDetails.errorMsg, 'number', withCover),
      cover: this.generateJoiSchema(libraryMessages.coverDetails.errorMsg, 'object', false),
      status: this.generateJoiStringSchema(libraryMessages.status.errorMsg, true),
      digitalReleaseDate: Joi.string().regex(dateRegExp).allow(null).required().label(libraryMessages.digitalReleaseDate.errorMsg),

      subgenre: this.generateOptionalValues(libraryMessages.genre.errorMsg),
      publisherLink: this.generateOptionalValues(libraryMessages.publisherLink.errorMsg),
      language: this.generateOptionalValues(libraryMessages.language.errorMsg),
      version: this.generateOptionalValues(libraryMessages.version.errorMsg),
      label: this.generateOptionalValues(libraryMessages.label.errorMsg),
      barcode: this.generateOptionalValues(libraryMessages.barcode.errorMsg),
      catalogNumber: this.generateOptionalValues(libraryMessages.catalogNumber.errorMsg),

    })
      .options({ abortEarly: false, });
    return this.getErrorsOfSchema(dataSchema, data);
  }

  /**
   * track data validation
   * @param data contain track data to validate before processing other stuff
   */
  static verifyTrackData(data: KeyValueObject<string | number | KeyValueObject<any>> | null, withLibrary: boolean = true) {
    if (data === undefined || data === null) {
      return {
        global: globalMessages.global.emptyData,
      }
    }
    const obj = Joi.object({
      fullname: this.generateJoiStringSchema(libraryMessages.fullname.errorMsg, false, ''),
      role: this.generateJoiStringSchema(libraryMessages.role.errorMsg, false, ''),
      id: this.generateJoiSchema(libraryMessages.fullname.errorMsg, 'number', false),
      isMusician: this.generateJoiSchema(libraryMessages.fullname.errorMsg, 'boolean', false),
    }).required();
    const libraryId = this.generateJoiSchema(globalMessages.signup.messages.errors.unauthorized, 'number', withLibrary);
    const commonSchema = (str: string) => Joi.array().items(obj).required().label(`${libraryMessages.membres.errorAtLeast} ${str}`);
    const dataSchema = Joi.object().keys({
      genre: this.generateJoiStringSchema(libraryMessages.genre.errorMsg, true, undefined, 2),
      title: this.generateJoiStringSchema(libraryMessages.title.errorMsg, true, undefined, 1),
      recordingOwnerName: this.generateJoiStringSchema(libraryMessages.recordingOwnerName.errorMsg, true, undefined, 1),
      yearOfRegistration: this.generateJoiSchema(libraryMessages.yearOfRegistration.errorMsg, 'number', true),
      // userId: this.generateJoiSchema(globalMessages.signup.messages.errors.unauthorized, 'number', true),
      libraryId,
      editors: Joi.array().optional().min(0).label(`${libraryMessages.membres.errorAtLeast} editeur`),
      artists: commonSchema('artiste'),
      contributors: commonSchema('contributor'),
      musicians: commonSchema('interprètes'),

      subgenre: this.generateOptionalValues(libraryMessages.genre.errorMsg),
      language: Joi.when('noVoice', {
        is: Joi.boolean().equal(false, ""),
        then: Joi.string().required().label(`${libraryMessages.language.errorMsg}`),
      }),
      version: this.generateOptionalValues(libraryMessages.version.errorMsg),
      starTime: Joi.string().optional().allow('').pattern(/[0-9]{2}:?[0-9]{2}/).label(libraryMessages.starTime.errorMsg),
      isrc: this.generateOptionalValues(libraryMessages.isrc.errorMsg),
      noVoice: Joi.boolean().optional().allow(1, 0, '1', 0).label(libraryMessages.noVoice.errorMsg),
    })
      .options({ abortEarly: false, });
    return this.getErrorsOfSchema(dataSchema, data);
  }

  /**
   * check if the choosen file are valide
   * @param data file to validate
   * @param formats list of allowed format
   */
  static isValidFileFormat(data: File | undefined, formats: string[]): boolean {
    if (!data) {
      return false;
    } else if (data?.type && !formats.includes(data.type)) {
      return false
    } else {
      return true;
    }
  }

  static verifyDeliveryData(data: KeyValueObject<string | number | KeyValueObject<any>> | null) {
    const dataSchema = Joi.object().keys({
      genre: this.generateJoiStringSchema(libraryMessages.genre.errorMsg, true, undefined, 2),
      originalReleaseDate: this.generateJoiStringSchema(libraryMessages.originalRealeaseDate.errorMsg, true, undefined, 2, 30),
      digitalReleaseDate: this.generateJoiStringSchema(libraryMessages.digitalReleaseDate.errorMsg, true, undefined, 2, 30),
      plan: this.generateJoiStringSchema(libraryMessages.plan.errorMsg, true),
      platforms: Joi.array().required().min(1).label(libraryMessages.platforms.errorMsg),
      territories: Joi.array().required().min(0).label(libraryMessages.territories.errorMsg),
      subgenre: this.generateJoiStringSchema(libraryMessages.genre.errorMsg, false, ''),
      label: this.generateJoiStringSchema(libraryMessages.label.errorMsg, true, ''),
    })
      .options({ abortEarly: false, });
    return this.getErrorsOfSchema(dataSchema, data);
  }

  static verifyTerritorie(data: string[] | undefined | null) {
    if (
      (data === null || data === undefined) ||
      (data.includes('all') && data.length)
    ) {
      return {
        territories: libraryMessages.territories.errorMsg,
      }
    }
    return false;
  }

  /**
   * Validate new artist data
   * @param data new artist details
   * @returns list of errors otherwise false
   */
  static verifyNewArtistData(data: Record<string, string> | null) {
    const dataSchema = Joi.object().keys({
      fullname: Joi.string().required().min(2).label(libraryMessages.fullname.errorMsg),
      spotifyId: Joi.string().optional().pattern(/[0-7][0-9a-zA-Z]{21}/).allow("", null).label(libraryMessages.spotifyId.errorMsg),
      appleMusicId: Joi.string().optional().pattern(/^[1-9]+$/).allow("", null).label(libraryMessages.appleMusicId.errorMsg)
    })
      .options({ abortEarly: false, });
    return this.getErrorsOfSchema(dataSchema, data);
  }

  /**
   * validate isrc/barcode bulk update of many tracks/libraries
   * 
   * @param data contain track/library data to validate before processing other stuff
   * @return false if there isnt any errors or an object with invalidate data
   */
  static verifyISRCOrBarcodeBulkUpdateData(data: Record<string, string>[] | null | undefined, type: "isrc" | "barcode" = "isrc") {
    if (data === undefined || data === null) {
      return {
        global: globalMessages.global.inValid,
      }
    }
    let dataKeys = type === "isrc"
      ? { isrc: this.generateJoiStringSchema(libraryMessages.isrc.errorMsg, true, undefined, 1) }
      : { "code-barre": this.generateJoiSchema(libraryMessages.barcode.errorMsg, "number", true) }
    const dataItem = Joi.object().keys({
      title: Joi.any().required().label(libraryMessages.title.errorMsg),
      ...dataKeys
    });
    const dataSchema = Joi.array().items(dataItem).options({ abortEarly: false, });
    let errors = this.getErrorsOfSchema(dataSchema, data);
    // refactore the generated errors to avoid indexed errors
    if (errors !== false) {
      errors = {
        global: errors[Object.keys(errors)?.[0]]
      };
    }

    return errors;
  }

}

export default LibraryValidator;