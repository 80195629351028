import React, { useContext, useEffect, useReducer, useState, } from 'react';
import { useHistory, } from 'react-router-dom';
import Axios from 'axios';
import ListGroup from 'react-bootstrap/ListGroup';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import { Download, Slash } from 'react-feather'
import PageLayout from '../../libs/PageLayout';
import uiTexts from '../../config/text';
import globalLinks from '../../config/links';
import globalSizes from '../../config/sizes';
import { globalReducer } from '../../store/reducer';
import { GlobalReducerActionType, KeyValueObject } from '../../types/shared'
import { formatDateTo } from '../../utils/dates';
import AuthContext from '../../context/auth';

const initState = {
  loading: true,
  errors: {},
  global: null,
  data: [],
};
let generatePayoutPDF: any | null = null;

export default function PayoutsList({ apiUrl, status }: KeyValueObject<string>) {
  let _isMounted = true;
  const axiosCancel = Axios.CancelToken.source();
  const { csrf } = useContext(AuthContext);
  const [state, dispatch] = useReducer(globalReducer, initState);
  const [pdfLoading, setPdfLoading] = useState(false);
  const history = useHistory();
  /**
   * close the alert message
   */
  const onClose = (type: GlobalReducerActionType, payload: any) => () => {
    dispatch({
      type,
      payload,
    })
  }
  /**
   * navigate to bank/paypal account for full see/edit it details
   * @param account user account details
   */
  const onNavigateTo = (account: any) => () => {
    if (account) {
      const historyOptions = getNavigationConfig(account);
      history.push(historyOptions.pathname, historyOptions.state);
    }
  }

  /**
   * get configuration of history.push
   * @param account user account details
   */
  const getNavigationConfig = (account: KeyValueObject<any>) => {
    const pathname = globalLinks.account.payout.replace(':id', account.payoutId);

    return {
      pathname,
      state: account
    }
  }
  const updatePayoutDownloadState = async (payoutId: number) => {
    try {
      const { data } = await Axios.put(
        `${globalLinks.api.account.editPayoutDownloadState}/${payoutId}`,
        {
          download: true,
        },
        {
          headers: {
            'X-Csrf-Token': csrf,
          },
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  /**
   * Generate a downloadable payout document of pdf format
   * @param payoutData payout detail such as amoun, account info and..
   */
  const onGeneratePayout = (payoutData: Record<string, any>) => async (evnt: React.MouseEvent<HTMLSpanElement>) => {
    evnt.stopPropagation();
    const $spanElement = evnt.currentTarget;
    _isMounted && setPdfLoading(true);
    try {
      const payoutCreatedAt = formatDateTo(payoutData.payoutCreatedAt, globalSizes.dates.fr.day);
      const data: Record<string, any> = {
        ...payoutData,
        payoutNetAmount: (payoutData.payoutAmount - globalSizes.netAmountFees)?.toFixed(2),
        payoutCreatedAt
      }

      if (generatePayoutPDF === null) {
        import('../../utils/pdf/contract')
          .then(async ({ generatePayoutPDF: fn }) => {
            generatePayoutPDF = fn;
            await updatePayoutDownloadState(data.payoutId);
            await generatePayoutPDF(data);
            $spanElement.remove()
            _isMounted && setPdfLoading(false);
          })
          .catch((e) => {
            _isMounted && dispatch({
              type: "ERROR",
              payload: {
                global: uiTexts.global.retry
              }
            });
            _isMounted && setPdfLoading(false);
          });
      } else {
        await updatePayoutDownloadState(data.payoutId);
        await generatePayoutPDF(data);
        $spanElement.remove()
        _isMounted && setPdfLoading(false);
      }
    } catch (error) {
      _isMounted && dispatch({
        type: "ERROR",
        payload: {
          global: uiTexts.global.retry
        }
      });
      _isMounted && setPdfLoading(false);
    }
  }

  useEffect(() => {
    const loadPayoutsData = async () => {
      try {
        const { data } = await Axios.get(apiUrl, {
          cancelToken: axiosCancel.token,
        });
        if (data.code === 'success') {
          _isMounted && dispatch({
            type: 'SET_LIST',
            payload: data.data,
          });
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.code) {
          _isMounted && dispatch({
            type: 'ERROR',
            payload: error.response.data.errors,
          });
        } else {
          _isMounted && dispatch({
            type: 'ERROR',
            payload: { global: uiTexts.global.network },
          });
        }
      }
    }
    loadPayoutsData();
    return () => {
      _isMounted = false;
      axiosCancel.cancel('Operation canceled');
    }
  }, []);

  return (
    <PageLayout className='page__bloc pt-3 pt-md-5 pb-3 pb-md-5 main__block' title={uiTexts.account.payout.titles[status]}>
      {
        (state.loading || pdfLoading) && (
          <div className='page__spinner d-flex justify-content-center align-items-center z_index_2' data-testid='spinner'>
            <Spinner className='main-text-color' animation="border" role="status">
              <span className="sr-only">{uiTexts.global.loading}.</span>
            </Spinner>
          </div>
        )
      }
      <div className="action__block d-flex justify-content-between flex-wrap mb-3 mb-md-4">
        <h1 className='h4'>{uiTexts.account.payout.titles[status]}</h1>
      </div>
      {
        !!state.errors.global &&
        <Alert className='w-100' variant='danger' onClose={onClose('ERROR', {})} dismissible transition>{state.errors.global}</Alert>
      }
      {
        !!state.global &&
        <Alert className='w-100' variant='success' onClose={onClose('SUCCESS', null)} dismissible transition>{state.global}</Alert>
      }
      <div className="list__block mt-3 mt-md-4">
        {
          !state.data.length
            ? <h4 className='h5 text-muted text-center'>
              <Slash size={globalSizes.icon.xm} className='mr-2' />
              {uiTexts.global.empty}
            </h4>
            : state.data.map((payout: any) => {
              let createdAt;
              try {
                createdAt = formatDateTo(payout.payoutCreatedAt, globalSizes.dates.fr.day);
              } catch (err) {
                createdAt = 'y a long temps';
              }
              return (
                <ListGroup.Item role='listitem' key={payout.payoutId} onClick={onNavigateTo(payout)} action className='d-flex justify-content-between align-items-center flex-wrap border_color_light-blue'>
                  <div className="account-detail__block">
                    <div className='font-weight-bold text-capitalize'>
                      {`${payout.userFname} ${payout.userLname}`}
                    </div>
                    <div>
                      <a href={`mailto://${payout.userEmail}`}>{payout.userEmail}</a>
                    </div>
                    <div className='font-weight-bold'>{`${payout.payoutAmount} ${globalSizes.currency}`}</div>
                  </div>
                  <div className="account-date__block d-flex flex-column">
                    <time dateTime={createdAt}>
                      {createdAt}
                    </time>
                    {
                      (payout.payoutState === "PAID" && payout.payoutDownloaded === 0) && (
                        <span
                          onClick={onGeneratePayout(payout)}
                          className='btn outline-link border-0 border_radius_3'
                          role='button'
                        >
                          <Download size={globalSizes.icon.default} className='mr-2' />
                          {uiTexts.global.download}
                        </span>
                      )
                    }
                  </div>
                </ListGroup.Item>
              );
            })
        }
      </div>

    </PageLayout>
  )
}
