import React from 'react';
import { Route, Redirect, } from 'react-router-dom';
import { AuthRouteProps, } from '../../types/shared';

function GuestRoute({ auth, redirectTo, children, ...rest }: AuthRouteProps) {
  return (
    <Route
      {...rest}
      render={() =>
        !auth
          ? children
          : <Redirect to={redirectTo || '/'} />
      }
    />
  );
}

export default GuestRoute;