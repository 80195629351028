import React, { useContext } from 'react';
import PrivateRoute from '../../libs/PrivateRoute';
import globalLinks from '../../config/links';
import { CustomRoutesContainerProps } from '../../types/shared';
import PromoCodesList from '../../pages/services/PromoCodeList';
import CreatePromoCode from '../../pages/services/CreatePromoCode';
import SmartlinkList from '../../pages/services/SmartlinkList';
import SmartlinkEdit from '../../pages/services/SmartlinkEdit';
import SubscriptionsList from '../../pages/services/SubscriptionsList';
import AuthContext from '../../context/auth';
import { renderRouteIfAllowedTo } from '../../utils/ui/capabilities';

const { smartlink, promoCode, subscriptions } = globalLinks.services;
export default function ServicesRoutes({ auth }: CustomRoutesContainerProps) {
  const { userCapabilities } = useContext(AuthContext);

  return (
    <React.Fragment>
      {
        renderRouteIfAllowedTo(
          userCapabilities,
          ["read_coupon"],
          <PrivateRoute exact path={promoCode.list} auth={auth}>
            <PromoCodesList />
          </PrivateRoute>
        )
      }
      {
        renderRouteIfAllowedTo(
          userCapabilities,
          ["edit_coupon"],
          <PrivateRoute exact path={promoCode.add} auth={auth}>
            <CreatePromoCode />
          </PrivateRoute>
        )
      }
      {
        renderRouteIfAllowedTo(
          userCapabilities,
          ["read_smartlink"],
          <PrivateRoute exact path={smartlink.list} auth={auth}>
            <SmartlinkList />
          </PrivateRoute>
        )
      }
      {
        renderRouteIfAllowedTo(
          userCapabilities,
          ["edit_smartlink"],
          <PrivateRoute exact path={`${smartlink.edit}/edit`} auth={auth}>
            <SmartlinkEdit />
          </PrivateRoute>
        )
      }
      {
        renderRouteIfAllowedTo(
          userCapabilities,
          ["read_subscriptions"],
          <PrivateRoute path={subscriptions.list} auth={auth}>
            <SubscriptionsList />
          </PrivateRoute>
        )
      }
    </React.Fragment>
  )
}
