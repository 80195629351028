import React, { useRef } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import { Download, RefreshCw } from 'react-feather';

import uiTexts from '../../config/text';
import globalSizes from '../../config/sizes';

interface ReleaseHeroProps {
  genre: string;
  subgenre?: string | undefined;
  yearOfRegistration: string;
  coverDetails: any;
  originalRealeaseDate: string;
  barcode: string | null | undefined;
  title?: string;
  digitalReleaseDate?: string | undefined;
  recordingOwnerName?: string | undefined;
  status?: string;
  downloadReleaseCoverUrl?: string;
  artistLabelNames?: string;
  onUploadImage?: ((file: File) => void) | undefined;
}
// TODO: display artists name like (art1 & art4 Feat art2 vs art3 ...)
export default function ReleaseHero({ title, artistLabelNames, genre, subgenre, yearOfRegistration, recordingOwnerName, coverDetails, originalRealeaseDate, digitalReleaseDate, barcode, status, downloadReleaseCoverUrl, onUploadImage }: ReleaseHeroProps) {
  const coverFileInput = useRef<HTMLInputElement>(null!);
  const onSelectImage = () => {
    coverFileInput.current?.click();
  }
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.currentTarget;
    const newImage = files?.[0];

    if (!!newImage && onUploadImage !== undefined) {
      onUploadImage(newImage);
    }
  }

  return (
    <div className='release-hero__block'>
      <Row className='mb-4'>
        <Col xs={12} sm={6} md={5}>
          <div className="d-flex align-items-center h-100">
            <div className='release-image overflow-hidden border_radius_3 shadow position-relative w-100 d-flex justify-content-center align-items-center'>
              <Image src={`${globalSizes.resourcesMainDomain}${coverDetails?.imageDetails?.url}`} className='h-100 w-100 object-fit_cover' />
              <div className='position-absolute_bottom-right w-100 d-flex justify-content-end'>
                {
                  onUploadImage && (
                    <React.Fragment>
                      <input
                        type='file'
                        name='cover'
                        className='d-none'
                        ref={coverFileInput}
                        onChange={onChange}
                      />
                      <Button variant='secondary' className='mr-3' onClick={onSelectImage}>
                        <RefreshCw size={globalSizes.icon.xm} />
                      </Button>
                    </React.Fragment>
                  )
                }
                <Button variant='primary' href={downloadReleaseCoverUrl}>
                  <Download size={globalSizes.icon.xm} />
                </Button>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={6} md={7}>
          <div className='d-flex flex-column h-100 justify-content-center'>
            <h2 className='h5 text-capitalize'>{title}</h2>
            <h2 className='h6'>{artistLabelNames}</h2>
            <h2 className='h6'>{genre} / {subgenre}</h2>
            <h2 className='h6'>(P) {yearOfRegistration} {recordingOwnerName}</h2>
            <h2 className='h6'>(C) {coverDetails?.year} {coverDetails?.owner}</h2>
            <h2 className='h6'>{uiTexts.libraries.originalRealeaseDate}: {originalRealeaseDate}</h2>
            <h2 className='h6'>{uiTexts.libraries.digitalReleaseDate}: {digitalReleaseDate}</h2>
            <h2 className='h6'>{uiTexts.libraries.barcode}: {barcode}</h2>
            {
              status && (
                <h2 className={`h6`}>
                  {`${uiTexts.global.itemStatus}`}:&nbsp;
                  <span className={`custom__badge badge badge-${globalSizes.statusColor[status]}`}>
                    {uiTexts.global.status[status]}
                  </span>
                </h2>
              )
            }
          </div>
        </Col>
      </Row>
    </div>
  )
}
