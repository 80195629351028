const libraryMessages = {
  genre: {
    label: 'Genre *',
    errorMsg: 'Le genre est requis',
  },
  subgenre: {
    label: 'Sous-genre(optionnel)',
    errorMsg: 'Le sous-genre est incorrect',
  },
  originalRealeaseDate: {
    label: 'Date de sortie originale *',
    errorMsg: 'Le date de sortie originale est requise',
  },
  status: {
    label: 'Statuts',
    errorMsg: 'SVP choisissez une des valeur "En attente"/"Contrôle Qualité"/"livrée"'  
  },
  digitalReleaseDate: {
    label: 'Date de sortie digitale *',
    errorMsg: 'La date de sortie digitale est requise',
  },
  plan: {
    errorMsg: 'Choisissez un plan',
  },
  platforms: {
    label: 'Sélectionner les plateformes *',
    errorMsg: 'Choisissez au moins une plateforme',
    selectedLabel: 'Plateformes sélectionnées',
  },
  territories: {
    label: 'Sélectionner les territoires *',
    errorMsg: 'Choisissez au moins un territoire'
  },
  title: {
    label: 'Titre de la sortie *',
    errorMsg: 'Le titre de la sortie est requis',
  },
  version: {
    label: 'Version(optionnel)',
    errorMsg: 'Version est une chaine de caractères',
  },
  starTime: {
    label: 'Star time(mm:ss)',
    errorMsg: 'Star time should look like 01:24 or 0124 by minutes:seconds',
  },
  label: {
    label: 'Label *',
    errorMsg: 'Le label est requis',
    name: 'Dropzik Digital(gratuit)',
    value: 'Dropzik Digital',
    digital: 'Label digitale'
  },
  publisherLink: {
    label: 'Lien dans la platform d\'éditeur',
    errorMsg: 'L\'éditeur est une chaine de caractère ou vide',
  },
  yearOfRegistration: {
    label: '℗ Année de l\'enregistrement *',
    errorMsg: 'L’année de l\'enregistrement est requise',
  },
  recordingOwnerName: {
    label: '℗ Nom du propriétaire de l\'enregistrement (visible sur les plateformes) *',
    errorMsg: 'Le nom du propriétaire de l\'enregistrement est requis',
  },
  coverDetails: {
    label: 'Pochette *',
    errorMsg: 'Cover détails est requis',
    dimensionsError: 'S.V.P selectionnez une image de 3000x3000(en pixel)',
    fileFormat: 'Nous acceptons les fichiers de format .png, .jpg ou .jpeg',
  },
  coverDetailsOwner: {
    label: '© Nom du propriétaire de la pochette (visible sur les plateformes)*',
    errorMsg: 'Le nom du propriétaire de la pochette est requis',
  },
  coverDetailsYear: {
    label: '© Année de création de la pochette*',
    errorMsg: 'L’année de création de la pochette est requis',
  },
  language: {
    label: 'Langue',
    errorMsg: 'Langue est une chaine de caractères'
  },
  barcode: {
    label: 'Code-barre - EAN ou UPC (Optionnel)',
    errorMsg: 'Code-barre est une combinaison de numéros'
  },
  catalogNumber: {
    label: 'Numéro de catalogue (Optionnel)',
    errorMsg: 'Numéro de catalogue est une combinaison de numéros et caractères'
  },
  isrc: {
    label: 'ISRC',
    errorMsg: 'ISRC est une combinaison de numéros et caractères'
  },
  audioPath: {
    label: 'Fichier audio *',
    errorMsg: 'Audio est requis de format .WAV',
    labelFormat: 'Format .WAV',
    labelSelect: 'Choisir l\'audio de la piste'
  },
  noVoice: {
    label: 'Instrumental(Pas de paroles/voix)?',
    errorMsg: 'Cochez ou laisser la case décoche'
  },
  editor: {
    label: 'Ajouter chaque éditeur/trice séparément *',
    errorMsg: 'Éditeur est requis'
  },
  fullname: {
    label: 'Nom d’artiste',
    errorMsg: 'Nom complet est requis'
  },
  spotifyId: {
    label: 'Profil artiste sur Spotify',
    errorMsg: 'Profil Spotify non valide, il doit commencer par un chiffre compris entre 0 et 7, suivi de 21 caractères.'
  },
  appleMusicId: {
    label: 'Profil artiste sur Apple Music/iTunes',
    errorMsg: 'Profil Apple Music/iTunes non valide, il ne doit être composé que de chiffres',
  },
  role: {
    label: '',
    errorMsg: 'Le rôle complet est requis'
  },
  membres: {
    label: '',
    errorMsg: 'Ce champs est requis',
    errorAtLeast: 'Ce champs est requis ajouter'
  },
  track: {
    success: {
      create: 'Vous avez créé une piste avec succès',
      updated: 'Vous avez uploadez l\'audio de la sortie',
    },
    errors: {
      filetype: 'Nous acceptons les fichiers .wav',
    }
  },
  delivery: {
    plan: 'Forfait *',
    selectAllPlatform: 'Sélectionner toutes les plateformes',
    date: 'Dates*',
    selectedAll: 'Tout sélectionner',
    all: 'Toutes',
    limited: 'Tous sauf',
    order: 'Vérifiez votre dernière commande',
  },
  summary: {
    delivery: 'Livraison',
    expressDelivery: 'Livraison express',
    normalDelivery: 'Livraison simple',
    label: 'Label Premium',
    plan: 'Type de sortie',
    labelPremium: 'Label Premium',
    saveLabel: 'Enregistrement du label premium',
    totalAmount: 'Total du montant à payer',
    conditions: 'Conditions',
    distribute: 'Distribuer',
    loadingPaymentScripts: 'Wait till load dependencies or refresh your browser',
    paymentFailed: 'Payement est erroné',
    paymentSucceed: 'Payments fait avec succès',
    declinedCard: "Il n'y a pas d’argent dans votre carte(card declined/card declined)",
    pay: 'Payer'
  }
}
export default libraryMessages;