import React from 'react';
import Logo from '../../assets/images/dropzik-logo-icon.png';

import './style.css';
export default function Splash() {
  return (
    <div className={`splash__bloc`}>
      <picture className={`splash-picture__container`}>
        <img src={Logo} alt='Dropzik Inc' className={`splash-picture__image`} />
      </picture>
    </div>
  )
}
