import React, { useContext } from 'react';
import PrivateRoute from '../../libs/PrivateRoute';
import globalLinks from '../../config/links';
import Releases from '../../pages/Releases';
import Release from '../../pages/Release';
import EditRelease from '../../pages/EditRelease';
import { CustomRoutesContainerProps } from '../../types/shared';
import UnpublishedReleases from '../../pages/UnpublishedReleases';
import AuthContext from '../../context/auth';
import { renderRouteIfAllowedTo } from '../../utils/ui/capabilities';

export default function LibraryRoutes({ auth }: CustomRoutesContainerProps) {
  const { userCapabilities } = useContext(AuthContext);

  return (
    <>
      {
        renderRouteIfAllowedTo(
          userCapabilities,
          ["read_none_paid_libraries", "read_paid_libraries", "read_delivered_libraries"],
          <PrivateRoute path={globalLinks.library.releases} auth={auth}>
            <Releases />
          </PrivateRoute>
        )
      }
      {
        renderRouteIfAllowedTo(
          userCapabilities,
          ["read_none_published_libraries"],
          <PrivateRoute path={globalLinks.library.unpublishedReleases} auth={auth}>
            <UnpublishedReleases />
          </PrivateRoute>
        )
      }
      <PrivateRoute exact path={`${globalLinks.library.release}/:id`} auth={auth}>
        <Release />
      </PrivateRoute>
      {
        renderRouteIfAllowedTo(
          userCapabilities,
          ["edit_none_paid_libraries", "edit_paid_libraries", "edit_delivered_libraries", "edit_none_published_libraries"],
          <PrivateRoute path={`${globalLinks.library.editRelease}`} auth={auth}>
            <EditRelease />
          </PrivateRoute>
        )
      }
    </>
  )
}
