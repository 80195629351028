export const toolsMessages = {
  ticket: {
    title: {
      label: "Sujet de ticket",
      errorMsg: "Sujet de ticket est requis"
    },
    track: {
      label: "Titre",
      errorMsg: "Title est requis",
    },
    startAt: {
      label: "Heure de début",
      errorMsg: "Heure de début est requis",
    },
    endAt: {
      label: "Heure de fin",
      errorMsg: " Heure de fin est requis",
    },
    url: {
      label: "Liens(separer par saut de line)",
      errorMsg: "Liens est requis",
    },
    agreements: {
      title: "Veuillez cocher tout ce qui s'applique :",
      youtubeElementNotClaimed: "Aucun de mes autres éléments YouTube ne revendique actuellement cette vidéo",
      contentAlreadyProtected: "La vidéo que je souhaite revendiquer contient du contenu protégé par des droits d'auteur que je  possède DANS LE MONDE ENTIER et EXCLUSIVEMENT",
      iAgree:{
        title: "Confirmation de l'exactitude et de la véracité * ",
        title2: "Je confirme par la présente que toutes les informations ci-dessus sont véridiques et exactes. Je comprends qu'en raison des normes imposées par YouTube, fournir des informations inexactes ou trompeuses amènera YouTube à pénaliser Revelator, et je perdrai donc la possibilité de faire des réclamations manuelles supplémentaires, ou même potentiellement la capacité de distribuer sur YouTube.",
        label: "Je confirme par la présente avoir lu et compris la déclaration ci-dessus.",
      }
    }
  }
}