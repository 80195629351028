import React, { useEffect, useReducer, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Calendar, Slash } from 'react-feather';
import Spinner from 'react-bootstrap/Spinner';
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import Axios from 'axios';
import { Youtube } from 'react-feather';
import format from 'date-fns/format';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';

import { globalReducer } from '../../store/reducer';
import PageLayout from '../../libs/PageLayout';
import uiTexts from '../../config/text';
import { GlobalReducerActionType, KeyValueObject } from '../../types/shared';
import globalLinks from '../../config/links';
import Alert from 'react-bootstrap/Alert';
import globalSizes from '../../config/sizes';
import { formatDateTo } from '../../utils/dates';
import ReportsFilterForm from '../../components/ReportsFilterForm';

import './style.css';
import LoaderIndicator from '../../components/LoaderIndicator';
import AlertMessage from '../../components/AlertMessage';

const initState: any = {
  loading: true,
  errors: {},
  global: null,
  data: [],
};
const initFiltersState = {
  fromDate: null,
  toDate: null,
  reports: [],
}
export default function ReportingDetails() {
  let _isMounted = true;
  const axiosCancel = Axios.CancelToken.source();
  const history = useHistory();
  const params = useParams<{ type: string }>();
  const [state, dispatch] = useReducer(globalReducer, initState);
  const [filters, setFilters] = useState(initFiltersState);
  const reportsList = filters.reports.length > 0
    ? filters.reports
    : state.data;
  /**
   * close the global messages(success/errors)
   * @param type type of action
   * @param payload the payload to dispatch
   */
  const onClose = (type: GlobalReducerActionType, payload: any) => () => {
    dispatch({
      type,
      payload,
    })
  }
  /**
   * navigate to bank/paypal account for full see/edit it details
   * @param account user account details
   */
  const onNavigateTo = (report: any) => () => {
    if (report) {
      history.push(`${globalLinks.reporting.list}/report/${report.id}`, report);
    }
  }
  const onSelectDate = (key: string) => (originalDate: any) => {
    originalDate = key === 'fromDate'
      ? originalDate
      : lastDayOfMonth(originalDate);
    const value = format(originalDate, globalSizes.dates.en.format);
    _isMounted && setFilters({
      ...filters,
      [key]: value,
    });
  }
  const onFilter = (type: "on" | "off") => () => {
    let reports = [];
    if (type === 'on' && filters.fromDate !== null && filters.toDate !== null) {
      const _fromTo = new Date(String(filters.fromDate)).getTime();
      const _toTo = new Date(String(filters.toDate)).getTime();
      reports = state.data.filter((report: Record<string, any>) => {
        return report.fromDate >= _fromTo && report.toDate <= _toTo;
      });
    } else if (type === 'off') {
      reports = [];
    }

    _isMounted && setFilters({
      ...filters,
      reports
    });
  }

  useEffect(() => {
    const loadReportsData = async () => {
      try {
        const { data } = await Axios.get(`${globalLinks.api.reporting.all}?type=${params.type}`, {
          cancelToken: axiosCancel.token,
        });
        if (data?.data && data?.data?.coverDetails) {
          data.data = {
            ...data.data,
            coverDetails: JSON.parse(data.data.coverDetails)
          }
        }
        if (data.code === 'success') {
          _isMounted && dispatch({
            type: 'SET_LIST_WITH_MESSAGE',
            payload: {
              data: data.data,
              message: null
            },
          });
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.code) {
          _isMounted && dispatch({
            type: 'ERROR',
            payload: error.response.data.errors,
          });
        } else {
          _isMounted && dispatch({
            type: 'ERROR',
            payload: { global: uiTexts.global.network },
          });
        }
      }
    }
    loadReportsData();
    return () => {
      _isMounted = false;
      axiosCancel.cancel('Operation canceled');
    }
  }, [params.type]);

  return (
    <PageLayout className='page__bloc pt-3 pt-md-5 pb-3 pb-md-5 main__block' title={uiTexts.reporting.mainTitle}>
      <LoaderIndicator
        loading={state.loading}
        testid='spinner'
        className='z_index_3'
      />
      <div className="action__block d-flex justify-content-between flex-wrap mb-3 mb-md-4">
        <h1 className='h4'>{uiTexts.reporting.mainTitle}</h1>
      </div>
      <ReportsFilterForm
        filters={filters}
        onSelectDate={onSelectDate}
        onFilter={onFilter}
      />
      <AlertMessage
        message={state.errors.global}
        variant={"danger"}
        onClose={onClose('ERROR', {})}
        show={!!state.errors.global}
        className={"mb-4"}
      />
      <div className='table-list__wrapper border border_color_light-blue border_radius_3 overflow-hidden'>
        {
          state.data.length === 0
            ? (
              <div className='py-3'>
                <h4 className='h5 text-muted text-center mb-0'>
                  <Slash size={globalSizes.icon.xm} className='mr-2' />
                  {uiTexts.global.noOptions}
                </h4>
              </div>
            )
            : reportsList.map((report: KeyValueObject<any>) => {
              const reportTitle = report?.Track?.title || `${report.Account.fname} ${report.Account.lname}`;
              let toDate = formatDateTo(report.toDate, globalSizes.dates.fr.month);

              return (
                <ListGroup.Item role='listitem' key={`report-${report.id}`} onClick={onNavigateTo(report)} action className='d-flex justify-content-between align-items-center flex-wrap border_color_light-blue'>
                  <div className="account-detail__block flex_1">
                    <div>
                      {
                        report.Account !== null && (
                          <Badge variant='danger' className='mr-2'>
                            <Youtube size={globalSizes.icon.default} />
                          </Badge>
                        )
                      }
                      <span>{reportTitle}</span>
                    </div>
                    <div>{report?.Track?.isrc}</div>
                  </div>
                  <div className="account-date__block">
                    <Calendar size={globalSizes.icon.default} className='mr-2' />
                    {toDate}
                  </div>
                </ListGroup.Item>
              );
            })
        }
      </div>
    </PageLayout>
  )
}
