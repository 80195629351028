import React from 'react';
import Seo from '../Seo';

interface PageLayoutProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  title?: string;
  htmlLang?: string;
  containerClassName?: string;
}

export default function PageLayout({ children, title = '', className = '', htmlLang = 'fr', ...rest }: PageLayoutProps) {
  return (
    <main role="main" className={`col-md-9 px-md-4 ${className}`} {...rest}>
      <Seo title={title} lang={htmlLang} />
      {children}
    </main>
  );
}
