const genresList = [
  {
    label: 'Arabic',
    value: 'Arabic',
  },
  {
    label: 'Arabic Pop',
    value: 'Arabic Pop',
  },
  {
    label: 'Country / Folk',
    value: 'Country',
  },
  {
    label: 'Dance / Electronic',
    value: 'Dance',
  },
  {
    label: 'Hip Hop/Rap',
    value: 'Hip Hop/Rap',
  },
  {
    label: 'Pop',
    value: 'Pop',
  },
  {
    label: 'Jazz',
    value: 'Jazz',
  },
  {
    label: 'Reggae / Dancehall',
    value: 'Reggae',
  },
  {
    label: 'Rock',
    value: 'Rock',
  },
  {
    label: 'Soul / Funk',
    value: 'Soul',
  },
  {
    label: 'Spoken Word',
    value: 'Spoken Word',
  },
  {
    label: 'World Music',
    value: 'World',
  }
];

export default genresList;