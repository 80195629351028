import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import uiTexts from '../../config/text';
import GradientButton from '../GradientButton';
import Col from 'react-bootstrap/Col';
import { KeyValueObject, ReleaseMainFormProps } from '../../types/shared';
import languagesList from '../../config/data/languages';
import libraryMessages from '../../config/messages/library';
import FormField from '../FormField';
import yearsList from '../../config/data/years';
import genresList from '../../config/data/genre';
import subgenresList from '../../config/data/subgenres';
import { artistRoles, contributorRoles, musicianRoles } from '../../config/data/roles';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import Button from 'react-bootstrap/esm/Button';
import { Edit, Trash2 } from 'react-feather';
import globalSizes from '../../config/sizes';
import { formatCreateLabel, getArtistsLabel, matchRole } from '../../utils/tools';
import Modal from 'react-bootstrap/Modal';
import { NewArtistTypes } from './types';
import NewArtistForm from '../NewArtistForm';

export default function TracksReleaseForm({ state, onSubmit, onChange, onChangeSelect, onSelectFile, onCreateFormCollectionItem, onChangeCollectionItem, onRemoveCollectionItem, onChangeNoVoice, onCreateNewArtist, data: resourcesData, readonly = false }: ReleaseMainFormProps) {
  const [showNewArtistForm, setShowNewArtistForm] = useState<NewArtistTypes>({
    isOpened: false,
    fullname: "",
    spotifyId: "",
    appleMusicId: "",
    id: null,
    index: null,
    loading: false
  });
  // render a message when suggestion list are empty
  const noOptionsMessage = () => uiTexts.global.noOptions;
  const onOpenModalToEditArtist = (artist: Record<string, string>, index: number) => () => {
    setShowNewArtistForm({
      ...showNewArtistForm,
      index,
      fullname: artist.fullname,
      id: artist.id,
      spotifyId: artist.spotifyId,
      appleMusicId: artist.appleMusicId,
      isOpened: true
    });
  }
  const onToggleModal = () => {
    setShowNewArtistForm({
      ...showNewArtistForm,
      isOpened: !showNewArtistForm.isOpened
    });
  }
  const onChangeArtistData = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setShowNewArtistForm({
      ...showNewArtistForm,
      [name]: value,
    });
  }
  const onSubmitArtistForm = async () => {
    if (onCreateNewArtist) {
      setShowNewArtistForm({
        ...showNewArtistForm,
        loading: true
      });
      const isCreated = await onCreateNewArtist(
        showNewArtistForm.index as number,
        String(showNewArtistForm.id),
        {
          fullname: showNewArtistForm.fullname,
          spotifyId: showNewArtistForm.spotifyId,
          appleMusicId: showNewArtistForm.appleMusicId,
        }
      );
      setShowNewArtistForm({
        ...showNewArtistForm,
        loading: false,
        isOpened: !isCreated
      });
    }
  }

  return (
    <Form className='form__block mw-100' onSubmit={onSubmit}>

      <Form.Row>
        <Col xs={12}>
          <FormField
            defaultValue={state.data?.title} error={state.errors?.title || null}
            label={libraryMessages.title.label} name='title'
            onChange={onChange}
            required autoFocus={true} readonly={readonly} />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12} md={6} className='mb-2'>
          <FormField
            defaultValue={state.data?.version} error={state.errors?.version || null}
            label={libraryMessages.version.label} name='version'
            onChange={onChange}
            readonly={readonly} />
        </Col>
        <Col xs={12} md={6}>
          <FormField
            name='starTime'
            label={libraryMessages.starTime.label}
            placeholder={"00:15"}
            defaultValue={state.data?.starTime}
            error={state.errors?.starTime || null}
            pattern={"[0-9]{2}:?[0-9]{2}"}
            readonly={readonly}
            onChange={onChange}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col sm={12}>
          <FormField
            defaultValue={state.data?.recordingOwnerName} error={state.errors?.recordingOwnerName || null}
            label={libraryMessages.recordingOwnerName.label} name='recordingOwnerName'
            onChange={onChange}
            readonly={readonly} required />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12} lg={6} className='mb-2'>
          <Form.Group id='genre' className='custom__select-block z_index_4'>
            <Select
              options={genresList} name='genre'
              placeholder=''
              defaultValue={{ label: state.data?.genre, value: state.data?.genre }}
              className='custom__select'
              onChange={onChangeSelect}
              isDisabled={readonly} />
            {
              state.errors?.genre && (
                <Form.Control.Feedback className='d-block' type="invalid">
                  {state.errors?.genre}
                </Form.Control.Feedback>
              )
            }
            <Form.Label>{libraryMessages.genre.label}</Form.Label>
          </Form.Group>
        </Col>
        <Col xs={12} lg={6} className='mb-2'>
          <Form.Group id='subgenre' className='custom__select-block'>
            <Select
              options={subgenresList} name='subgenre'
              placeholder=''
              defaultValue={{ label: state.data?.subgenre || '', value: state.data?.subgenre || '' }}
              className='custom__select z_index_3'
              onChange={onChangeSelect} />
            {
              state.errors?.subgenre && (
                <Form.Control.Feedback className='d-block' type="invalid">
                  {state.errors?.subgenre}
                </Form.Control.Feedback>
              )
            }
            <Form.Label className='z_index_3'>{libraryMessages.subgenre.label}</Form.Label>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col sm={12} lg={6} className='mb-2'>
          <Form.Group id='coverDetailsYear' className='custom__select-block'>
            <Select
              options={yearsList} name='yearOfRegistration'
              placeholder=''
              defaultValue={{ label: state.data?.yearOfRegistration, value: state.data?.yearOfRegistration }}
              className='custom__select z_index_2'
              onChange={onChangeSelect}
              isDisabled={readonly} />
            {
              state.errors?.yearOfRegistration && (
                <Form.Control.Feedback className='d-block' type="invalid">
                  {state.errors?.yearOfRegistration}
                </Form.Control.Feedback>
              )
            }
            <Form.Label className='z_index_2'>{libraryMessages.yearOfRegistration.label}</Form.Label>
          </Form.Group>
        </Col>
        <Col sm={12} lg={6}>
          <FormField
            defaultValue={state.data?.isrc} error={state.errors?.isrc || null}
            label={libraryMessages.isrc.label} name='isrc'
            onChange={onChange}
            readonly={readonly} />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col sm={12}>
          <div className={"mt-n2"}>
            <Form.Check
              type="switch"
              id="no-voice"
              label={libraryMessages.noVoice.label}
              name="noVoice"
              defaultValue={`${state.data.noVoice}`}
              defaultChecked={state.data.noVoice}
              className={"mb-4"}
              onChange={onChangeNoVoice}
            />
          </div>
          {
            !state.data.noVoice && (
              <Form.Group id='language' className='custom__select-block'>
                <Select
                  options={languagesList} name='language'
                  placeholder=''
                  defaultValue={{ label: state.data?.language, value: state.data?.language }}
                  className='custom__select'
                  onChange={onChangeSelect}
                  isDisabled={readonly} />
                {
                  state.errors?.language && (
                    <Form.Control.Feedback className='d-block' type="invalid">
                      {state.errors?.language}
                    </Form.Control.Feedback>
                  )
                }
                <Form.Label>{libraryMessages.language.label}</Form.Label>
              </Form.Group>
            )
          }
        </Col>
      </Form.Row>
      <Form.Row>
        <Col sm={12}>
          <Form.Group id='artists' className='custom__select-block'>
            {
              state.data?.artists && (
                <h4 className='h5'>{getArtistsLabel(state.data?.artists)}</h4>
              )
            }
            {
              state.data?.artists.map((artist: KeyValueObject<any>, index: number) => {
                const artistRole = matchRole((artist.TrackArtist || artist) as any);
                const defaultArtistRole = {
                  label: artistRole?.label || artistRoles[0].label,
                  value: artistRole?.value || artistRoles[0].label
                };
                return (
                  <div className='form-collection__item d-flex mb-2' key={`artist-${artist.id}`}>
                    <Select
                      options={artistRoles}
                      name='role'
                      defaultValue={defaultArtistRole}
                      className={`custom__select flex_1 border_radius_left-side_lg`}
                      id={`custom__select_role-${index}`}
                      isDisabled={readonly}
                      onChange={onChangeCollectionItem && onChangeCollectionItem('artists', 'role', index)} />
                    <CreatableSelect
                      className='flex_2 custom__select border_radius_0'
                      name='fullname'
                      options={resourcesData?.artists || []}
                      noOptionsMessage={noOptionsMessage}
                      formatCreateLabel={formatCreateLabel}
                      isDisabled={readonly}
                      autoFocus
                      defaultValue={{ label: artist.fullname, value: artist.id || artist.fullname }}
                      onChange={onChangeCollectionItem && onChangeCollectionItem('artists', 'fullname', index)}
                    />
                    <Button variant='outline-info' className='border_radius_0' onClick={onOpenModalToEditArtist(state.data?.Artists[index], index)} disabled={readonly}>
                      <Edit size={globalSizes.icon.default} />
                    </Button>
                    <Button variant='outline-danger' className='border_radius_right-side_lg' onClick={onRemoveCollectionItem && onRemoveCollectionItem('artists', index)} disabled={readonly}>
                      <Trash2 size={globalSizes.icon.default} />
                    </Button>
                  </div>
                )
              })
            }
            <Button variant='secondary' onClick={onCreateFormCollectionItem && onCreateFormCollectionItem('artists', '')} disabled={readonly} block>
              {uiTexts.libraries.newRelease.addArtists}
            </Button>
            {
              state.errors?.artists && (
                <Form.Control.Feedback className='d-block' type="invalid">{state.errors?.artists}</Form.Control.Feedback>
              )
            }
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col sm={12}>
          <Form.Group id='contributors' className='custom__select-block'>
            {
              state.data?.contributors.map((contributor: KeyValueObject<string>, index: number) => {
                const contributorRole = matchRole(contributor as any, contributorRoles);
                const defaultContributor = { label: contributorRole?.label || contributorRoles[0].label, value: contributorRole?.value || contributorRoles[0].value };
                return (
                  <div className='form-collection__item d-flex mb-2' key={index}>
                    <Select
                      options={contributorRoles}
                      name='role'
                      value={defaultContributor}
                      className={`custom__select flex_1 border_radius_left-side_lg`}
                      id={`custom__select_role-${index}`}
                      isDisabled={readonly}
                      noOptionsMessage={noOptionsMessage}
                      onChange={onChangeCollectionItem && onChangeCollectionItem('contributors', 'role', index)}
                    />
                    <CreatableSelect
                      className='flex_2 custom__select border_radius_0'
                      name='fullname'
                      options={resourcesData?.contributors || []}
                      noOptionsMessage={noOptionsMessage}
                      formatCreateLabel={formatCreateLabel}
                      autoFocus
                      isDisabled={readonly}
                      onChange={onChangeCollectionItem && onChangeCollectionItem('contributors', 'fullname', index)}
                      value={{ label: contributor.fullname, value: contributor?.id || contributor?.fullname }}
                    />
                    <Button variant='outline-danger' className='border_radius_right-side_lg' onClick={onRemoveCollectionItem && onRemoveCollectionItem('contributors', index)} disabled={readonly}>
                      <Trash2 size={globalSizes.icon.default} />
                    </Button>
                  </div>
                );
              })
            }
            <Button variant='secondary' onClick={onCreateFormCollectionItem && onCreateFormCollectionItem('contributors', contributorRoles[0].value)} disabled={readonly} block>
              {uiTexts.libraries.newRelease.addContributors}
            </Button>
            {
              state.errors?.contributors && (
                <Form.Control.Feedback className='d-block' type="invalid">{state.errors?.contributors}</Form.Control.Feedback>
              )
            }
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col sm={12}>
          <Form.Group id='musicians' className='custom__select-block'>
            {
              state.data?.musicians.map((contributor: KeyValueObject<string | any>, index: number) => (
                <div className='form-collection__item d-flex mb-2' key={index}>
                  {contributor?.MusicianArtist?.role}
                  <Select
                    options={musicianRoles}
                    name='role'
                    value={{ label: contributor?.TrackMusician?.role || contributor?.role || contributorRoles[0].label, value: contributor?.role || contributorRoles[0].value }}
                    className={`custom__select flex_1 border_radius_left-side_lg`}
                    id={`custom__select_role-${index}`}
                    isDisabled={readonly}
                    onChange={onChangeCollectionItem && onChangeCollectionItem('musicians', 'role', index)} />
                  <CreatableSelect
                    className='flex_2 custom__select border_radius_0'
                    name='fullname'
                    options={resourcesData?.musicians || []}
                    noOptionsMessage={noOptionsMessage}
                    formatCreateLabel={formatCreateLabel}
                    autoFocus
                    isDisabled={readonly}
                    onChange={onChangeCollectionItem && onChangeCollectionItem('musicians', 'fullname', index)}
                    value={{ label: contributor.fullname, value: contributor?.id || contributor?.fullname }}
                  />
                  <Button variant='outline-danger' className='border_radius_right-side_lg' onClick={onRemoveCollectionItem && onRemoveCollectionItem('musicians', index)} disabled={readonly}>
                    <Trash2 size={globalSizes.icon.default} />
                  </Button>
                </div>
              ))
            }
            <Button variant='secondary' onClick={onCreateFormCollectionItem && onCreateFormCollectionItem('musicians', musicianRoles[0].value)} disabled={readonly} block>
              {uiTexts.libraries.newRelease.addMusicians}
            </Button>
            {
              state.errors?.musicians && (
                <Form.Control.Feedback className='d-block' type="invalid">{state.errors?.musicians}</Form.Control.Feedback>
              )
            }
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col sm={12}>
          <Form.Group id='editors' className='custom__select-block'>
            {
              state.data?.editors.map((editor: KeyValueObject<string>, index: number) => (
                <div className='form-collection__item d-flex mb-2' key={index}>
                  <CreatableSelect
                    className='flex_2 custom__select border_radius_left-side_lg'
                    name='fullname'
                    options={resourcesData?.editors || []}
                    noOptionsMessage={noOptionsMessage}
                    formatCreateLabel={formatCreateLabel}
                    autoFocus
                    isDisabled={readonly}
                    onChange={onChangeCollectionItem && onChangeCollectionItem('editors', 'fullname', index)}
                    value={{ label: editor.fullname, value: editor.id || editor.fullname }}
                  />
                  <Button variant='outline-danger' className='border_radius_right-side_lg' onClick={onRemoveCollectionItem && onRemoveCollectionItem('editors', index)} disabled={readonly}>
                    <Trash2 size={globalSizes.icon.default} />
                  </Button>
                </div>
              ))
            }
            <Button variant='secondary' onClick={onCreateFormCollectionItem && onCreateFormCollectionItem('editors', '')} disabled={readonly} block>
              {uiTexts.libraries.newRelease.addEditors}
            </Button>
            {
              state.errors?.editors && (
                <Form.Control.Feedback className='d-block' type="invalid">{state.errors?.editors}</Form.Control.Feedback>
              )
            }
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col sm={12}>
          <Form.Group id='audio' className='custom__select-block z_index_0'>
            <Form.File id="track-audio" custom>
              <Form.File.Input name='audio' onChange={onSelectFile} isInvalid={!!state.errors?.audio} />
              <Form.File.Label data-browse={libraryMessages.audioPath.labelFormat}>
                {resourcesData?.audio || libraryMessages.audioPath.labelSelect}
              </Form.File.Label>
              {
                state.errors?.audio && (
                  <Form.Control.Feedback type="invalid">{state.errors?.audio}</Form.Control.Feedback>
                )
              }
            </Form.File>
            <Form.Label className='z_index_1'>{libraryMessages.audioPath.label}</Form.Label>
          </Form.Group>
        </Col>
      </Form.Row>
      {
        onSubmit && (
          <GradientButton disabled={state.loading} type='submit' size='lg' className='text-white' block>
            {
              state.loading
                ? uiTexts.global.loading
                : uiTexts.global.save
            }
          </GradientButton>
        )
      }
      <Modal
        show={showNewArtistForm.isOpened} onHide={onToggleModal}
        className='z_index_max' backdropClassName='z_index_max'
        scrollable animation centered aria-labelledby={'Add new artist'}>
        <Modal.Header className='border-0' closeButton>
          <Modal.Title>
            {uiTexts.libraries.newRelease.addArtists}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='show-grid pl-0 pr-0'>
          <NewArtistForm
            data={showNewArtistForm}
            errors={state.errors}
            onChange={onChangeArtistData}
            onCreateNewArtist={onSubmitArtistForm}
          />
        </Modal.Body>
      </Modal>
    </Form>
  )
}
