import React, { useContext, useEffect, useReducer } from 'react';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

import LoaderIndicator from '../../../components/LoaderIndicator';
import globalLinks from '../../../config/links';
import uiTexts from '../../../config/text';
import AuthContext from '../../../context/auth';
import PageLayout from '../../../libs/PageLayout'
import { globalReducer } from '../../../store/reducer';
import { GlobalReducerActionType } from '../../../types/shared';
import { useParams } from 'react-router';
import FormField from '../../../components/FormField';
import { servicesMessages } from '../../../config/messages/services';
import GradientButton from '../../../components/GradientButton';
import ServiceValidator from '../../../utils/validators/services';

const initState = {
  loading: true,
  errors: {},
  global: null,
  data: {},
};
export default function SmartlinkEdit() {
  const [state, dispatch] = useReducer(globalReducer, initState);
  const pageTitle = state.loading
    ? uiTexts.global.loading
    : `${uiTexts.global.edit} ${state.data?.Library?.title} smartlink`;
  const { csrf } = useContext(AuthContext);
  const { id } = useParams<Record<'id', string>>();
  const slug = `${globalLinks.fenanPro}/${state.data?.slug || ''}`;
  const axiosOptions = {
    headers: {
      'X-Csrf-Token': csrf,
    },
  }
  let _isMounted = true;

  const onClose = (type: GlobalReducerActionType, payload: any) => () => {
    dispatch({
      type,
      payload,
    })
  }
  /**
   * Change the new value type by user
   * @param isUrls check if will change platforms urls or other field
   */
  const onChange = (isUrls = true) => (evnt: React.ChangeEvent<HTMLInputElement>) => {
    const { name: key, value } = evnt.target
    if (isUrls) {
      _isMounted && dispatch({
        type: 'SET_FIELDS',
        payload: {
          urls: state.data.urls
            ? { ...state.data.urls, [key]: value }
            : { [key]: value }
        }
      });
    } else {
      _isMounted && dispatch({
        type: 'SET_FIELD',
        payload: {
          key,
          value
        }
      });
    }
  }
  /**
   * update smartlink data by submitting the form
   */
  const onSubmit = async (evnt: React.FormEvent<HTMLElement>) => {
    evnt.preventDefault()
    _isMounted && dispatch({
      type: 'LOADING',
      payload: true
    });

    try {
      const newSmartlinkData = {
        status: state.data.status,
        urls: state.data.urls || {},
      };
      const errors = ServiceValidator.verifySmartlink(newSmartlinkData);

      if (errors) {
        return _isMounted && dispatch({
          type: 'ERROR',
          payload: errors,
        });
      }
      const { data } = await axios.put(
        `${globalLinks.api.services.smartlink.item}/${state.data.id}/edit`,
        newSmartlinkData,
        axiosOptions
      );

      if (data.code === 'success') {
        _isMounted && dispatch({
          type: 'SUCCESS',
          payload: data.message
        });
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.code) {
        _isMounted && dispatch({
          type: 'ERROR',
          payload: error.response.data.errors,
        });
      } else {
        _isMounted && dispatch({
          type: 'ERROR',
          payload: { global: uiTexts.global.network },
        });
      }
    }
  }

  useEffect(() => {
    const fetchSmartlinkItem = async () => {
      _isMounted && !state.loading && dispatch({
        type: 'LOADING',
        payload: true,
      })
      try {
        const { data } = await axios.get(`${globalLinks.api.services.smartlink.item}/${id}`);
        if (data.code === 'success') {
          data.data.urls = JSON.parse(data.data.urls);
          _isMounted && dispatch({
            type: 'SET_FIELDS',
            payload: data.data
          });
        } else {
          throw new Error(data.code);
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.code) {
          _isMounted && dispatch({
            type: 'ERROR',
            payload: error.response.data.errors,
          });
        } else {
          _isMounted && dispatch({
            type: 'ERROR',
            payload: { global: uiTexts.global.network },
          });
        }
      }
    }
    fetchSmartlinkItem();

    return () => {
      _isMounted = false;
    }
  }, []);

  return (
    <PageLayout className='page__bloc pt-3 pt-md-5 pb-3 pb-md-5 main__block' title={pageTitle}>
      <LoaderIndicator loading={state.loading} testid='spinner' />
      <div className="action__block d-flex justify-content-between flex-wrap mb-3 mb-md-4">
        <h1 className='h4 fl-uppercase'>{pageTitle}</h1>
      </div>
      {
        !!state.errors.global &&
        <Alert className='w-100' variant='danger' onClose={onClose('ERROR', {})} dismissible transition>{state.errors.global}</Alert>
      }
      {
        !!state.global &&
        <Alert className='w-100' variant='success' onClose={onClose('SUCCESS', null)} dismissible transition>{state.global}</Alert>
      }
      <Form onSubmit={onSubmit}>
        <Form.Row>
          <Col sm={12}>
            <FormField name='slug' label={servicesMessages.slug.label} value={slug} onChange={onChange(false)} readonly />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Form.Group controlId="status">
              <Form.Label>{uiTexts.global.itemStatus}</Form.Label>
              <Form.Control as="select" name='status' value={state.data?.status} onChange={onChange(false)} size='lg' isInvalid={!!state.errors?.status} required>
                <option value='PENDING'>En attente</option>
                <option value='ENABLED'>Active</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">{state.errors?.status}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col sm={12} md={6}>
            <FormField name='facebook' label={servicesMessages.facebook.label} onChange={onChange()} value={`${state.data?.urls?.facebook || ''}`} error={state.errors?.facebook} />
          </Col>
          <Col sm={12} md={6}>
            <FormField name='instagram' label={servicesMessages.instagram.label} onChange={onChange()} value={`${state.data?.urls?.instagram || ''}`} error={state.errors?.instagram} />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col sm={12} md={6}>
            <FormField name='deezer' label={servicesMessages.deezer.label} onChange={onChange()} value={`${state.data?.urls?.deezer || ''}`} error={state.errors?.deezer} />
          </Col>
          <Col sm={12} md={6}>
            <FormField name='spotify' label={servicesMessages.spotify.label} onChange={onChange()} value={`${state.data?.urls?.spotify || ''}`} error={state.errors?.spotify} />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col sm={12} md={6}>
            <FormField name='youtube' label={servicesMessages.youtube.label} onChange={onChange()} value={`${state.data?.urls?.youtube || ''}`} error={state.errors?.youtube} />
          </Col>
          <Col sm={12} md={6}>
            <FormField name='youtube-music' label={servicesMessages.youtubeMusic.label} onChange={onChange()} value={`${state.data?.urls && state.data?.urls['youtube-music'] ? state.data?.urls['youtube-music'] : ''}`} error={state.errors['youtube-music'] || null} />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col sm={12} md={6}>
            <FormField name='anghami' label={servicesMessages.anghami.label} onChange={onChange()} value={`${state.data?.urls?.anghami || ''}`} error={state.errors?.anghami} />
          </Col>
          <Col sm={12} md={6}>
            <FormField name='apple-music' label={servicesMessages.appleMusic.label} onChange={onChange()} value={`${state.data?.urls && state.data?.urls['apple-music'] ? state.data?.urls['apple-music'] : ''}`} error={state.errors['apple-music'] || null} />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <div className="d-flex justify-content-sm-end justify-content-start">
              <GradientButton size='lg' disabled={state.loading} className='text-white pl-4 pr-4' type="submit">
                {
                  state.loading
                    ? uiTexts.global.loading
                    : uiTexts.global.save
                }
              </GradientButton>
            </div>
          </Col>
        </Form.Row>
      </Form>
    </PageLayout>
  )
}
