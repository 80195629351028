import React, { useContext, useReducer } from 'react';
import axios from 'axios';
import DatePicker, { registerLocale } from 'react-datepicker';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { format, parse } from 'date-fns';
import { fr } from 'date-fns/locale';

import PageLayout from '../../../libs/PageLayout';
import AuthContext from '../../../context/auth';
import FormField from '../../../components/FormField';
import globalLinks from '../../../config/links';
import globalSizes from '../../../config/sizes';
import uiTexts from '../../../config/text';
import { globalReducer } from '../../../store/reducer';
import { GlobalReducerActionType } from '../../../types/shared';
import GradientButton from '../../../components/GradientButton';
import { servicesMessages } from '../../../config/messages/services';
import ServicesValidator from '../../../utils/validators/services';

import "react-datepicker/dist/react-datepicker.css";

registerLocale('fr', fr);

const initState = {
  loading: false,
  errors: {},
  global: null,
  data: {},
};
export default function CreatePromoCode() {
  const [state, dispatch] = useReducer(globalReducer, initState);
  const { csrf } = useContext(AuthContext);
  const axiosOptions = {
    headers: {
      'X-Csrf-Token': csrf,
    },
  };
  const { code, amount, dueDate, type } = uiTexts.services.promoCode;
  const dueDateSelected = state.data?.dueDate
    ? new Date(parse(state.data?.dueDate, 'dd/MM/yyyy', new Date()))
    : new Date();
  const onClose = (type: GlobalReducerActionType, payload: any) => () => {
    dispatch({
      type,
      payload,
    })
  }
  /**
   * save the value of input field
   * @param evnt change event
   */
  const onChange = (evnt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name: key, value } = evnt.target;
    dispatch({
      type: 'SET_FIELD',
      payload: { key, value },
    });
  }
  /**
   * change promo code due date
   * @param dueDate
   */
  const onSelectDueDate = (dueDate: any) => {
    const value = format(dueDate, globalSizes.dates.fr.format);

    dispatch({
      type: 'SET_FIELD',
      payload: {
        key: 'dueDate',
        value,
      }
    });
  }

  const onSubmit = async (evnt: React.FormEvent<HTMLElement>) => {
    evnt.preventDefault();
    dispatch({
      type: 'LOADING',
      payload: true,
    });

    try {
      const errors = ServicesValidator.verifyPromoCode(state.data);

      if (errors !== false) {
        return dispatch({
          type: 'ERROR',
          payload: errors,
        });
      }
      const { data } = await axios.post(
        globalLinks.api.services.promoCode.add,
        state.data,
        axiosOptions
      );
      if (data.code === 'success') {
        dispatch({
          type: 'SUCCESS',
          payload: data.message,
        });
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.code) {
        dispatch({
          type: 'ERROR',
          payload: error.response.data.errors,
        });
      } else {
        dispatch({
          type: 'ERROR',
          payload: { global: uiTexts.global.network },
        });
      }
    }
  }

  const CustomDateInput = React.forwardRef(({ value, onClick, }: any, ref: any) => {
    return (
      <FormField
        defaultValue={value}
        error={state.errors?.dueDate || null}
        label={uiTexts.services.promoCode.dueDate}
        name='dueDate'
        onClick={onClick}
        onChange={onChange}
        required
        readonly={false}
      />
    );
  })

  return (
    <PageLayout className='page__bloc pt-3 pt-md-5 pb-3 pb-md-5 main__block' title={uiTexts.services.promoCode.add}>
      {
        state.loading && (
          <div className='page__spinner d-flex justify-content-center align-items-center' data-testid='spinner'>
            <Spinner className='main-text-color' animation="border" role="status">
              <span className="sr-only">{uiTexts.global.loading}.</span>
            </Spinner>
          </div>
        )
      }
      <div className="action__block d-flex justify-content-between flex-wrap mb-3 mb-md-4">
        <h1 className='h4'>{uiTexts.services.promoCode.add}</h1>
      </div>
      {
        !!state.errors.global &&
        <Alert className='w-100' variant='danger' onClose={onClose('ERROR', {})} dismissible transition>{state.errors.global}</Alert>
      }
      {
        !!state.global &&
        <Alert className='w-100' variant='success' onClose={onClose('ERROR', {})} dismissible transition>{state.global}</Alert>
      }
      <Form onSubmit={onSubmit}>
        <Form.Row>
          <Col sm={12} md={6}>
            <FormField
              size='lg'
              defaultValue={state.data.name}
              error={state.errors.name}
              name="name"
              label={servicesMessages.name.label}
              onChange={onChange}
            />
          </Col>
          <Col sm={12} md={6}>
            <FormField
              size='lg'
              defaultValue={state.data.description}
              error={state.errors.description}
              name="description"
              label={servicesMessages.description.label}
              onChange={onChange}
              as='textarea'
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col sm={12} md={6}>
            <FormField
              size='lg'
              type='number'
              defaultValue={state.data.amount}
              error={state.errors.amount}
              name="amount"
              label={servicesMessages.amount.label}
              onChange={onChange}
              text={servicesMessages.amount.note}
            />
          </Col>
          <Col sm={12} md={6}>
            <Form.Group className='mb-2' controlId='city'>
              <DatePicker
                locale='fr'
                selected={dueDateSelected}
                customInput={<CustomDateInput />}
                wrapperClassName='w-100'
                onChange={onSelectDueDate}
              />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <div className="d-flex justify-content-sm-end justify-content-start">
              <GradientButton size='lg' disabled={state.loading || !!state.global} className='text-white pl-4 pr-4' type="submit">
                {
                  state.loading
                    ? uiTexts.global.loading
                    : uiTexts.global.save
                }
              </GradientButton>
            </div>
          </Col>
        </Form.Row>
      </Form>
    </PageLayout>
  )
}
