import React, { useContext, useEffect, useReducer } from 'react';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import { Slash, Trash2 } from 'react-feather';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

import TableList from '../../../components/TableList';
import globalLinks from '../../../config/links';
import globalSizes from '../../../config/sizes';
import uiTexts from '../../../config/text';
import PageLayout from '../../../libs/PageLayout';
import { globalReducer } from '../../../store/reducer';
import { GlobalReducerActionType } from '../../../types/shared';
import AuthContext from '../../../context/auth';

const initState = {
  loading: true,
  errors: {},
  global: null,
  data: [],
};
export default function PromoCodesList() {
  const [state, dispatch] = useReducer(globalReducer, initState);
  const { csrf } = useContext(AuthContext);
  const axiosOptions = {
    headers: {
      'X-Csrf-Token': csrf,
    },
  }
  const history = useHistory();
  const { code, amount, dueDate, type } = uiTexts.services.promoCode;
  const headers = [code, type, amount, dueDate, uiTexts.global.remove];
  let _isMounted = true;

  const onClose = (type: GlobalReducerActionType, payload: any) => () => {
    dispatch({
      type,
      payload,
    })
  }
  /**
   * navigate to release order(invoice)
   * @param libraryId library(release) id
   */
  const onNavigateToProfile = (userId: any) => async () => {
    history.push(`${globalLinks.account.profile}/${userId}`);
  }

  const onRemovePromoCode = (id: number) => async () => {
    _isMounted && dispatch({
      type: 'LOADING',
      payload: true
    });

    try {
      const { data } = await axios.delete(
        `${globalLinks.api.services.promoCode.item}/${id}`,
        axiosOptions
      );

      if (data.code === 'success') {
        const newData = state.data.filter((item: Record<'id', number>) => item.id !== id);
        _isMounted && dispatch({
          type: 'SET_LIST_WITH_MESSAGE',
          payload: {
            message: data.message,
            data: newData,
          }
        });
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.code) {
        _isMounted && dispatch({
          type: 'ERROR',
          payload: error.response.data.errors,
        });
      } else {
        _isMounted && dispatch({
          type: 'ERROR',
          payload: { global: uiTexts.global.network },
        });
      }
    }
  }

  useEffect(() => {
    const fetchPromoCodesList = async () => {
      try {
        const { data } = await axios.get(globalLinks.api.services.promoCode.list);
        if (data.code === 'success') {
          _isMounted && dispatch({
            type: 'SET_LIST',
            payload: data.data,
          });
        } else {
          throw new Error(data.code);
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.code) {
          _isMounted && dispatch({
            type: 'ERROR',
            payload: error.response.data.errors,
          });
        } else {
          _isMounted && dispatch({
            type: 'ERROR',
            payload: { global: uiTexts.global.network },
          });
        }
      }
    }
    fetchPromoCodesList();

    return () => {
      _isMounted = false;
    }
  }, []);

  return (
    <PageLayout className='page__bloc pt-3 pt-md-5 pb-3 pb-md-5 main__block' title={uiTexts.services.promoCode.list}>
      {
        state.loading && (
          <div className='page__spinner d-flex justify-content-center align-items-center' data-testid='spinner'>
            <Spinner className='main-text-color' animation="border" role="status">
              <span className="sr-only">{uiTexts.global.loading}.</span>
            </Spinner>
          </div>
        )
      }
      <div className="action__block d-flex justify-content-between flex-wrap mb-3 mb-md-4">
        <h1 className='h4'>{uiTexts.services.promoCode.list}</h1>
      </div>
      {
        !!state.errors.global &&
        <Alert className='w-100' variant='danger' onClose={onClose('ERROR', {})} dismissible transition>{state.errors.global}</Alert>
      }
      {
        !!state.global &&
        <Alert className='w-100' variant='success' onClose={onClose('ERROR', {})} dismissible transition>{state.global}</Alert>
      }
      <div className="list__block mt-3 mt-md-4">
        {
          !state.data.length
            ? <h4 className='h5 text-muted text-center'>
              <Slash size={globalSizes.icon.xm} className='mr-2' />
              {uiTexts.global.noOptions}
            </h4>
            : <TableList headers={headers}>
              {
                state.data.map(
                  (item: Record<string, string | number>) => {
                    const dueDate = format(new Date(item.dueDate), globalSizes.dates.fr.format, {
                      locale: fr
                    });

                    return (
                      <tr key={item.id} className={`table-list__item`} onClick={undefined}>
                        <td key={`${item.id}-name`} className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                          {item.name}
                        </td>
                        <td key={`${item.id}-type`} className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                          {item.type}
                        </td>
                        <td key={`${item.id}-amount`} className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                          {item.amount}
                        </td>
                        <td key={`${item.id}-due-date`} className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                          {dueDate}
                        </td>
                        <td key={`${item.id}-remove-btn`} className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                          <Button onClick={onRemovePromoCode(Number(item.id))} variant='outline-danger' title={uiTexts.global.remove} className='d-flex p-2' data-testid={`remove-${item.id}`}>
                            <Trash2 size={globalSizes.icon.default} />
                          </Button>
                        </td>
                      </tr>
                    );
                  }
                )
              }
            </TableList>
        }
      </div>
    </PageLayout>
  )
}
