import React, { useContext, useReducer, useState, } from 'react';
import { useHistory } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import Axios from 'axios';
import { format } from 'date-fns';
import { ActionMeta, ValueType } from 'react-select';
import PageLayout from '../../libs/PageLayout';
import uiTexts from '../../config/text';
import libraryMessages from '../../config/messages/library';
import globalLinks from '../../config/links';
import { globalReducer } from '../../store/reducer';
import { GlobalReducerActionType } from '../../types/shared'
import globalSizes from '../../config/sizes';
import { KeyValueObject } from '../../types/shared';

import LibraryForm from '../../components/LibraryForm';
import LibraryValidator from '../../utils/LibraryValidator';
import AuthContext from '../../context/auth';
import './style.css';

const initState: any = {
  loading: false,
  errors: {},
  global: null,
  data: {
    originalRealeaseDate: format(new Date(), globalSizes.dates.fr.format),
  },
};
const releaseText = uiTexts.libraries.newRelease;
interface NewReleaseDetailsProps {
  data?: KeyValueObject<any> | null | undefined;
  title?: string | null | undefined;
  loading?: boolean;
  isEdit?: boolean;
}
export default function NewReleaseDetails({ data, loading, title, isEdit }: NewReleaseDetailsProps) {
  let _isMounted = true;
  const history = useHistory();
  const [coverFile, setCoverFile] = useState<any>(null);
  const authContext = useContext(AuthContext);
  const axiosOptions = {
    headers: {
      'X-Csrf-Token': authContext.csrf,
    },
  };
  if (data) {
    initState.data = {
      ...initState.data,
      ...data,
      coverDetailsOwner: data?.coverDetails?.owner,
      coverDetailsYear: data?.coverDetails?.year,
    }
    initState.loading = loading;
  }
  const [state, dispatch] = useReducer(globalReducer, initState);
  const onClose = (type: GlobalReducerActionType, payload: any) => () => {
    dispatch({
      type,
      payload,
    })
  }
  const onSelectOriginalDate = (field: string) => (originaleDate: any) => {
    const value = format(originaleDate, globalSizes.dates.fr.format);
    dispatch({
      type: 'SET_FIELD',
      payload: {
        key: field || 'originalRealeaseDate',
        value,
      }
    })
  }
  // input onchange event and saving new details
  const onChange = (evnt: React.ChangeEvent<HTMLInputElement>) => {
    const { name: key, value } = evnt.target;
    dispatch({
      type: 'SET_FIELD',
      payload: {
        key,
        value,
      }
    })
  }
  // change event of <Select />/<Creatable />
  const onChangeSelect = (value: ValueType<any>, action: ActionMeta<any>) => {
    dispatch({
      type: 'SET_FIELD',
      payload: {
        key: action.name,
        value: value.value,
      }
    })
  }
  // edit release details
  const onEditRelease = async (newData: any) => {
    newData.catalogNumber = newData?.catalogNumber || '';
    const {
      title, genre, subgenre, catalogNumber, barcode, version,
      label, yearOfRegistration, recordingOwnerName, language,
      originalRealeaseDate, status, digitalReleaseDate, publisherLink
    } = newData;
    let releaseData: any = {
      title, genre, subgenre, version, originalRealeaseDate, status, publisherLink,
      label, yearOfRegistration, recordingOwnerName, language, digitalReleaseDate
    };
    if (barcode) {
      releaseData = { ...releaseData, barcode };
    }
    if (catalogNumber) {
      releaseData = { ...releaseData, catalogNumber };
    }
    if (publisherLink) {
      releaseData = { ...releaseData, publisherLink };
    }
    
    const errors = LibraryValidator.verifyLibraryData(releaseData, false);

    try {
      if (errors) {
        dispatch({
          type: 'ERROR',
          payload: errors,
        });
      } else {
        const { data } = await Axios.put(
          `${globalLinks.api.libraries.release}/${state.data.id}/edit`,
          releaseData,
          axiosOptions
        );
        if (data.code === 'success') {
          _isMounted && dispatch({
            type: 'SUCCESS',
            payload: data.message,
          });
          window.scrollTo(0, 0);
        }
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.code) {
        _isMounted && dispatch({
          type: 'ERROR',
          payload: error.response.data.errors,
        });
      } else {
        _isMounted && dispatch({
          type: 'ERROR',
          payload: { global: uiTexts.global.network },
        });
      }
      window.scrollTo(0, 0);
    }
  }
  // handle edit form of release(library)
  const onSubmit = async (evnt: React.FormEvent<HTMLFormElement>) => {
    evnt.preventDefault();
    _isMounted && dispatch({
      type: 'LOADING',
      payload: true,
    });
    onEditRelease(state.data);
  }
  /**
   * select release cover image
   */
  const onSelectReleaseCover = (evnt: React.ChangeEvent<HTMLInputElement>) => {
    const releaseCover = evnt.target?.files;

    if (!releaseCover?.length) return;
    else if (!globalSizes.cover.format.includes(releaseCover[0].type)) {
      _isMounted && dispatch({
        type: 'ERROR',
        payload: {
          cover: libraryMessages.coverDetails.errorMsg
        },
      });
    } else {
      _isMounted && dispatch({
        type: 'ERROR',
        payload: {
          cover: undefined
        },
      });
      _isMounted && setCoverFile(releaseCover);
    }
  }

  return (
    <PageLayout className='page__bloc pt-3 pt-md-5 pb-3 pb-md-5 main__block' title={title || releaseText.details}>
      {
        state.loading && (
          <div className='page__spinner d-flex justify-content-center align-items-center' data-testid='spinner'>
            <Spinner className='main-text-color' animation="border" role="status">
              <span className="sr-only">{uiTexts.global.loading}.</span>
            </Spinner>
          </div>
        )
      }
      <div className="action__block mb-5">
        <h1 className='h2 mb-0'>{title || releaseText.details}</h1>
      </div>
      {
        !state.loading && (
          <LibraryForm
            isEdit={isEdit || false}
            onSubmit={onSubmit} state={state} onClose={onClose}
            onChange={onChange} onChangeSelect={onChangeSelect}
            onSelectReleaseCover={onSelectReleaseCover}
            onSelectDate={onSelectOriginalDate}
          />
        )
      }

    </PageLayout >
  )
}
