import axios from 'axios';
import React, { useEffect, useReducer, useRef } from 'react'
import { useHistory } from 'react-router-dom';
import AlertMessage from '../../components/AlertMessage';
import CardsList from '../../components/CardsList';
import LoaderIndicator from '../../components/LoaderIndicator';
import globalLinks from '../../config/links';
import uiTexts from '../../config/text';
import PageLayout from '../../libs/PageLayout';
import { globalReducer } from '../../store/reducer';

const initState = {
  loading: true,
  errors: {},
  global: null,
  data: [],
};
export default function UnpublishedReleases() {
  const [state, dispatch] = useReducer(globalReducer, initState);
  const _isMounted = useRef(true);
  const history = useHistory();
  /**
   * navigate to release main ui
   * @param release detain of library release
   */
  const onNavigateToReleasePage = (release: Record<string, any>) => () => {
    history.push(`${globalLinks.library.release}/${release.id}`, release);
  }

  useEffect(() => {
    const loadData = async () => {
      try {
        const { data: releases } = await axios.get(globalLinks.api.libraries.unpublished);

        if (releases.code === 'success') {
          _isMounted && dispatch({
            type: 'SET_LIST',
            payload: releases.data || []
          });
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.code) {
          _isMounted && dispatch({
            type: 'ERROR',
            payload: error.response.data.errors,
          });
        } else {
          _isMounted && dispatch({
            type: 'ERROR',
            payload: { global: uiTexts.global.network },
          });
        }
      }
    }
    loadData();

    return () => {
      _isMounted.current = false;
    }
  }, []);

  return (
    <PageLayout className='page__bloc pt-3 pt-md-5 pb-3 pb-md-5 main__block' title={uiTexts.libraries.unpublished.title}>
      <LoaderIndicator
        loading={state.loading}
        testid='spinner'
      />
      <div className="action__block mb-3 mb-md-4">
        <h1 className='h4'>{uiTexts.libraries.unpublished.title}</h1>
        <blockquote className='note__block text-muted mb-0 p-2 pt-1 pb-1 rounded'>
          {uiTexts.libraries.releaseNote}
        </blockquote>
      </div>
      <AlertMessage
        message={state.errors.global}
        variant={"danger"}
        show={!!state.errors.global}
      />
      <CardsList
        data={state?.data}
        onNavigateToReleasePage={onNavigateToReleasePage}
      />
    </PageLayout>
  );
}
