import React, { useContext, useEffect, useReducer, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Calendar, Slash, Trash2 } from 'react-feather';
import Table from 'react-bootstrap/Table';
import Axios from 'axios';
import ButtonGroup from 'react-bootstrap/esm/ButtonGroup';
import Button from 'react-bootstrap/Button';
import Toast from 'react-bootstrap/Toast';

import AuthContext from '../../context/auth';
import PageLayout from '../../libs/PageLayout';
import uiTexts from '../../config/text';
import { KeyValueObject } from '../../types/shared';
import globalSizes from '../../config/sizes';
import globalLinks from '../../config/links';
import { globalReducer } from '../../store/reducer';
import { formatDateTo } from '../../utils/dates';
import LoaderIndicator from '../../components/LoaderIndicator';
import AlertMessage from '../../components/AlertMessage';

const initState: any = {
  loading: true,
  errors: {},
  global: null,
  data: {},
};
export default function TrackReportingsDetails() {
  let _isMounted = true;
  const axiosCancel = Axios.CancelToken.source();
  const [state, dispatch] = useReducer(globalReducer, initState);
  const params = useParams<KeyValueObject<string>>();
  const history = useHistory();
  const [showTaost, setShowTaost] = useState(false);
  const { csrf } = useContext(AuthContext);
  const title = state.data.Track?.title ||
    `${state.data.Account?.fname} ${state.data.Account?.lname}` ||
    uiTexts.global.loading;
  const axiosOptions = {
    headers: {
      'X-Csrf-Token': csrf,
    },
  };
  // render report details
  const renderReportDetails = () => {
    const report = state.data;
    let toDate: any = new Date(report?.toDate);
    let totalIncome =
      report?.incomeOfStreams
      + report?.incomeOfYoutube
      + report?.incomeOfDownloads;

    try {
      toDate = formatDateTo(toDate, globalSizes.dates.fr.month);
    } catch (error) {
      toDate = uiTexts.global.longTimeAgo;
    }
    if (!report?.id) return (
      <tr>
        <td colSpan={5}>
          <h4 className='h5 text-muted text-center mb-0'>
            <Slash size={globalSizes.icon.xm} className='mr-2' />
            {uiTexts.global.noOptions}
          </h4>
        </td>
      </tr>
    );
    return (
      <React.Fragment key={report?.id}>
        <tr className='bg-muted'>
          <td className='table-cell__title text-capitalize align-middle' colSpan={5}>
            <Calendar size={globalSizes.icon.xm} className='mr-2' />
            {toDate}
          </td>
        </tr>
        {
          state.data.type === "platform" && (
            <tr>
              <td className='align-middle'>{uiTexts.global.quantity}</td>
              <td className='align-middle px-4'>{report?.numOfStreams}</td>
              <td className='align-middle px-4'>{report?.numOfDownloads}</td>
              <td className='align-middle px-4'>{report?.numOfYoutube}</td>
              <td className='align-middle'></td>
            </tr>
          )
        }
        <tr>
          <td className='align-middle'>Revenus (USD)</td>
          {
            state.data.type === "platform" && (
              <>
                <td className='align-middle px-4'>{report?.incomeOfStreams?.toFixed(2)}</td>
                <td className='align-middle px-4'>{report?.incomeOfDownloads?.toFixed(2)}</td>
              </>
            )
          }
          <td className='align-middle px-4'>{report?.incomeOfYoutube?.toFixed(2)}</td>
          <td className='align-middle'>{totalIncome?.toFixed(2)}</td>
        </tr>
      </React.Fragment>
    )
  }
  // open/close <Toast />
  const onToggleTaostForRemove = () => setShowTaost(!showTaost);
  // send DELETE request to remove report
  const onRemoveReport = async () => {
    _isMounted && dispatch({
      type: 'LOADING',
      payload: true,
    });
    try {
      const { data } = await Axios.delete(
        `${globalLinks.api.reporting.delete}/${state.data.id}`,
        axiosOptions
      );

      if (data.code === 'success') {
        _isMounted && dispatch({
          type: 'SUCCESS',
          payload: data.message,
        });
        history.push(globalLinks.reporting.all);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.code) {
        _isMounted && dispatch({
          type: 'ERROR',
          payload: error.response.data.errors,
        });
      } else {
        _isMounted && dispatch({
          type: 'ERROR',
          payload: { global: uiTexts.global.network },
        });
      }
    }
  }

  useEffect(() => {
    const loadReportData = async () => {
      try {
        const { data } = await Axios.get(`${globalLinks.api.reporting.root}/${params.id}`, {
          cancelToken: axiosCancel.token,
        });
        if (data?.data && data?.data?.coverDetails) {
          data.data = {
            ...data.data,
            coverDetails: JSON.parse(data.data.coverDetails)
          }
        }
        if (data.code === 'success') {
          _isMounted && dispatch({
            type: 'SET_FIELDS',
            payload: data.data,
          });
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.code) {
          _isMounted && dispatch({
            type: 'ERROR',
            payload: error.response.data.errors,
          });
        } else {
          _isMounted && dispatch({
            type: 'ERROR',
            payload: { global: uiTexts.global.network },
          });
        }
      }
    }
    loadReportData();
    return () => {
      _isMounted = false;
      axiosCancel.cancel('Operation canceled');
    }
  }, []);

  return (
    <PageLayout className='page__bloc pt-3 pt-md-5 pb-3 pb-md-5 main__block' title={title}>
      <LoaderIndicator
        loading={state.loading}
        testid='spinner'
      />
      <div className="action__block d-flex justify-content-between flex-wrap mb-3 mb-md-4">
        <h1 className='h4 text-capitalize'>{title}</h1>
        <div className='action-block'>
          <Button variant='outline-danger' onClick={onToggleTaostForRemove}>
            <Trash2 className='mr-2' size={globalSizes.icon.default} />
            <span>{uiTexts.global.remove}</span>
          </Button>
          <Toast show={showTaost} onClose={onToggleTaostForRemove}>
            <Toast.Header>
              <Trash2 className='mr-2' size={globalSizes.icon.default} />
              <strong className="mr-auto">{uiTexts.global.remove}</strong>
              <small>
                status:{` `}
                <span className={`custom__badge badge badge-${globalSizes.statusColor[state?.data?.status]}`}>{state?.data?.status}</span>
              </small>
            </Toast.Header>
            <Toast.Body>
              <h4 className='h6 mb-3'>
                {uiTexts.global.notes.delete}
              </h4>
              <ButtonGroup>
                <Button variant='outline-primary' type='button' onClick={onToggleTaostForRemove}>
                  <span>{uiTexts.global.cancel}</span>
                </Button>
                <Button variant='outline-danger' onClick={onRemoveReport}>
                  <Trash2 className='mr-2' size={globalSizes.icon.default} />
                  <span>{uiTexts.global.sure}</span>
                </Button>
              </ButtonGroup>
            </Toast.Body>
          </Toast>
        </div>
      </div>
      <AlertMessage
        message={state.errors.global || state.global}
        show={!!state.errors.global || !!state.global}
        variant={!!state.global ? "success" : "danger"}
        className={"mb-4"}
      />
      <div className='table-list__wrapper border border_color_light-blue border_radius_3 overflow-hidden'>
        <Table className='table-list__block bg-white mb-0' responsive hover>
          <thead className='table-list__header'>
            <tr className='table-list__item'>
              <th className='table-list__header-cell text-capitalize pt-3 pl-4 pb-3 pr-4 align-middle'></th>
              {
                state.data.type === "platform" && (
                  <>
                    <th className='table-list__header-cell text-capitalize pt-3 pl-4 pb-3 pr-4 align-middle'>{uiTexts.reporting.streams}</th>
                    <th className='table-list__header-cell text-capitalize pt-3 pl-4 pb-3 pr-4 align-middle'>{uiTexts.reporting.downloads}</th>
                  </>
                )
              }
              <th className='table-list__header-cell text-capitalize pt-3 pl-4 pb-3 pr-4 align-middle'>{uiTexts.reporting.youtube}</th>
              <th className='table-list__header-cell text-capitalize pt-3 pl-4 pb-3 pr-4 align-middle'>{uiTexts.global.total}</th>
            </tr>
          </thead>
          <tbody className='table-list__body fs-14'>
            <React.Fragment>
              {renderReportDetails()}
            </React.Fragment>
          </tbody>
        </Table>
      </div>

    </PageLayout>
  )
}
