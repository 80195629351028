import React from 'react';
import Button, { ButtonProps } from 'react-bootstrap/Button';

export const gradientStyle = {
  backgroundColor: '#f32445',
  backgroundImage: 'linear-gradient(45deg, #f32445, #a52874)'
}
export default function GradientButton({ children, className, style, variant='light', ...rest }: ButtonProps) {
  return (
    <Button style={{ ...gradientStyle, ...style, }} className={`btn__gradient ${className}`} variant={variant} {...rest}>
      {children}
    </Button>
  )
}
