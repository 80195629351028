import format from 'date-fns/format';
import { fr } from 'date-fns/locale';

export const formatDateTo = (value: string | number, dateFormat: string, locale = fr) => {
  let result;

  try {
    result = format(
      new Date(value),
      dateFormat,
      {
        locale
      }
    );
  } catch (error) {
    result = '';
  }

  return result;
}