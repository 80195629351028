import React, { useContext } from 'react';
import LoaderIndicator from '../../components/LoaderIndicator';
import globalLinks from '../../config/links';
import PrivateRoute from '../../libs/PrivateRoute';
import { CustomRoutesContainerProps } from '../../types/shared';
import { renderRouteIfAllowedTo } from '../../utils/ui/capabilities';
import AuthContext from '../../context/auth';
const CreateContract = React.lazy(() => import('../../pages/CreateContract'));

export default function ContractsRoutes({ auth }: CustomRoutesContainerProps) {
  const { userCapabilities } = useContext(AuthContext);

  return (
    <React.Fragment>
      <React.Suspense
        fallback={<LoaderIndicator loading={true} />}
      >
        {
          renderRouteIfAllowedTo(
            userCapabilities,
            ["edit_tools"],
            <PrivateRoute
              exact
              path={globalLinks.constracts.root}
              auth={auth}
            >
              <CreateContract />
            </PrivateRoute>
          )
        }
      </React.Suspense>
    </React.Fragment>
  );
}
