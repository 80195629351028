import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import globalSizes from "../../config/sizes";

/**
 * Upload a give file to the server
 * @param file new file to upload
 * @param url enpoint url
 * @param options http client in this case axios
 * @param method http method
 * @returns formatted response from server
 */
export async function uploadFile(file: File, url: string, options: AxiosRequestConfig = {}, method: "post" | "put" = "put") {
  const fileData = new FormData();
  fileData.append('cover', file);
  const { data: fileResponse } = await axios[method](
    url,
    fileData,
    options
  );

  return fileResponse;
}

/**
 * Slice a given file to chunks then upload it to the server
 * @param mainFile file to upload
 * @param url enpoint link
 * @param axiosOptions http client options
 * @param method http method
 * @param cb callback called each slice og the file was uploaded contains server response and uploaded chunks size
 * @returns server response of the last chunk uploaded
 */
export async function uploadFileInChunks(mainFile: File, url: string, axiosOptions: AxiosRequestConfig = {}, method: "post" | "put" = "put", cb?: (data: AxiosResponse<any>["data"]) => void) {
  let isDone = false;
  let file = mainFile;
  let fileResponse;
  let i = 0;

  while (!isDone) {
    let startSlice = i * globalSizes.MAX_FILE_CHUNK_SIZE;
    let endSlice = (i + 1) * globalSizes.MAX_FILE_CHUNK_SIZE;
    let chunkSize;

    if (file?.size - (i * globalSizes.MAX_FILE_CHUNK_SIZE) > globalSizes.MAX_FILE_CHUNK_SIZE) {
      chunkSize = i * globalSizes.MAX_FILE_CHUNK_SIZE;
    } else {
      chunkSize = file?.size - (i * globalSizes.MAX_FILE_CHUNK_SIZE);
      endSlice = file?.size;
      isDone = true;
    }
    const blobFile = file.slice(startSlice, endSlice, file?.type);
    const formData = new FormData();
    formData.append('track', blobFile);
    const response = await axios[method](
      url,
      formData,
      {
        ...axiosOptions,
        headers: {
          ...axiosOptions.headers,
          "X-File-Start": startSlice.toString(),
          "X-File-Name": encodeURIComponent(file.name),
          "X-File-Size": mainFile?.size,
        }
      }
    );
    fileResponse = response.data;
    cb && cb({
      total: file?.size,
      loaded: endSlice,
      response: response
    });
    i++;
  }

  return fileResponse;
}