import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Trash } from 'react-feather';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';

import uiTexts from '../../config/text';
import GradientButton from '../GradientButton';
import globalSizes from '../../config/sizes';
import FormField from '../FormField';
import reportMessages from '../../config/messages/report';
import CSVReportFormPropsTypes from '../CSVReportForm/types';
import { formatDateTo } from '../../utils/dates';

import "react-datepicker/dist/react-datepicker.css";

registerLocale('fr', fr);
export default function DeleteCSVReportsForm({ data, errors, loading, disabled, onSelectDate, onSubmit }: Pick<CSVReportFormPropsTypes, "onSubmit" | "onSelectDate" | "data" | "errors" | "loading" | "disabled">) {
  const [show, setShow] = useState(false);
  // custom input to use with <DatePicker />
  const CustomDateInput = React.forwardRef(({ value, onClick, error, name, label }: any, ref: any) => {
    return (
      <FormField
        autoComplete='off'
        defaultValue={value}
        error={error || null}
        label={label}
        name={name}
        onClick={onClick}
        readonly={false} />
    );
  });
  const fromDate = data?.fromDate
    ? new Date(format(data?.fromDate, globalSizes.dates.en.format))
    : undefined;
  const onToggleModal = () => {
    setShow(!show);
  }
  const onRemoveReports = async (event: React.FormEvent<HTMLFormElement>) => {
    onSubmit && onSubmit(event);
    onToggleModal();
  }

  return (
    <div className='form__block mw-100 mt-4'>
      <Form.Row>
        <Col className='mb-4'>
          <DatePicker
            locale='fr'
            selected={fromDate}
            onChange={onSelectDate}
            customInput={
              <CustomDateInput
                name='fromDate'
                label={reportMessages.fromDate.label}
                error={errors?.date}
              />
            }
            monthClassName={() => 'calendar-month__date'}
            wrapperClassName='w-100'
            placeholderText={reportMessages.fromDate.label}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            showFullMonthYearPicker
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <GradientButton
            disabled={disabled}
            type='button'
            className='text-white'
            size={"lg"}
            onClick={onToggleModal}
            block
          >
            {uiTexts.global.remove}
          </GradientButton>
        </Col>
      </Form.Row>
      <Modal
        show={show}
        onHide={onToggleModal}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className='mb-0 position-sticky bg-white sticky_top'>
            <Trash size={globalSizes.icon.default} className='mr-2' />
            {uiTexts.reporting.deleteReport}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onRemoveReports}>
            <div className='mb-3'>
              Voulez vous supprimer les rapports de{` `}
              <strong className='font-weight-bold'>{formatDateTo(data?.fromDate, globalSizes.dates.fr.month)}</strong>
              {`?`}
            </div>
            <div className="d-flex justify-content-end position-sticky bg-white sticky_bottom">
              <Button variant='outline-primary' className='border_radius_left-side_lg' onClick={onToggleModal}>
                {uiTexts.global.close}
              </Button>
              <Button
                variant='outline-danger'
                className='border_radius_right-side_lg'
                disabled={disabled}
                type='submit'
              >
                {
                  loading
                    ? uiTexts.global.loading
                    : uiTexts.global.remove
                }
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  )
}
