import React, { useContext, useEffect, useReducer, useState } from 'react';
import Axios from 'axios';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { Link, useParams } from 'react-router-dom';
import { CreditCard, Music, List, FilePlus, Youtube, Gift, Slash } from 'react-feather';
import Button from 'react-bootstrap/Button';
import uiTexts from '../../config/text';
import PageLayout from '../../libs/PageLayout';
import ProfilValidator from '../../utils/ProfilValidator';
import { GlobalReducerActionType, KeyValueObject } from '../../types/shared';
import globalLinks from '../../config/links';
import AuthContext from '../../context/auth';
import { globalReducer } from '../../store/reducer';
import globalSizes from '../../config/sizes';
import ProfileForm from '../../components/ProfileForm';
import ProfileReleases from '../../components/ProfileReleases';
import { ReleasesTypes } from '../../components/ProfileReleases/types';
import ProfileCSVReleasesForm from '../../components/ProfileCSVReleasesForm';
import ProfileReferralsForm from '../../components/ProfileReferralsForm';
import LoaderIndicator from '../../components/LoaderIndicator';
import { useReferrals } from '../../hooks/use-referrals';
import TableList from '../../components/TableList';
import Modal from 'react-bootstrap/Modal';
import useAbortController from '../../hooks/use-abort-controller';
import AlertMessage from '../../components/AlertMessage';

const fields = {
  fname: '',
  lname: '',
  email: '',
  city: '',
  postcode: '',
  address: '',
  address2: '',
  company: '',
  country: '',
  tax: '',
  timezone: '',
};
const initState = {
  loading: true,
  errors: fields,
  global: null,
  data: fields,
};
export default function Profile() {
  let _isMounted = true;
  const { newAbortSignal, cancelPreviousRequest } = useAbortController();
  const params = useParams<KeyValueObject<string>>();
  const axiosCancel = Axios.CancelToken.source();
  const [state, dispatch] = useReducer(globalReducer, initState);
  const [{ data: listOfReferrals }, dispatchReferrals] = useReferrals(params.id);
  const [releases, setReleases] = useState<ReleasesTypes>({
    loading: true,
    show: false,
    data: null,
  });
  const [isCSVReleasesOpened, setIsCSVReleasesOpened] = useState(false);
  const [isReferralsFormOpen, setIsReferralsFormOpen] = useState(false);
  const [referralRemoval, setReferralRemoval] = useState({
    showModal: false,
    id: null
  });
  const authContext = useContext(AuthContext);
  const youtubeReportLinkState = {
    pathname: globalLinks.reporting.youtube,
    state: params.id
  };
  const alertMessage = state.global || state.errors.global;
  const alertVariant = !!state.global ? "success" : "danger";
  const showAlert = !!state.global || !!state.errors.global;
  const csvFilename = `sorties-de-${state.data?.fname?.toUpperCase()}-${state.data?.lname}.csv`;
  const isCsvDownloadable = releases.loading || releases.data === null || releases.data.length === 0;
  const onChange = (evnt: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name: key, } = evnt.target;
    dispatch({
      type: 'SET_FIELD',
      payload: { key, value, }
    });
  }
  /**
   * switch the role of user from to customer/label
   */
  const onChangeRole = (evnt: React.ChangeEvent<HTMLInputElement>) => {
    const { name: key, checked } = evnt.target;
    dispatch({
      type: 'SET_FIELD',
      payload: { key, value: checked ? "LABEL" : "CUSTOMER", }
    });
  }
  const onSubmit = async (evnt: React.FormEvent<HTMLFormElement>) => {
    evnt.preventDefault();
    dispatch({
      type: 'LOADING',
      payload: true,
    });

    try {
      const errors = ProfilValidator.profilData(state.data);
      if (errors) {
        dispatch({
          type: 'ERROR',
          payload: errors,
        });
      } else {
        const { data } = await Axios.put(`${globalLinks.api.account.profile}/${params.id}/profile`, state.data, {
          headers: {
            'X-Csrf-Token': authContext.csrf,
          },
        });
        if (data.code === 'success') {
          dispatch({
            type: 'SUCCESS',
            payload: data.message,
          });
        } else {
          throw new Error('failed');
        }
      }
    } catch (error) {
      // @ts-ignore
      if (error.response && error.response.data && error.response.data.code) {
        dispatch({
          type: 'ERROR',
          // @ts-ignore
          payload: error.response.data.errors,
        });
      } else {
        dispatch({
          type: 'ERROR',
          payload: { global: uiTexts.global.network },
        });
      }
    }
  }
  const onClose = (type: GlobalReducerActionType, payload: any) => () => {
    dispatch({
      type,
      payload,
    })
  }
  const onToggleModal = () => {
    _isMounted && setReleases({
      ...releases,
      show: !releases.show
    });
  }
  const onLoadReleases = async () => {
    if (releases.data !== null) return;
    try {
      const { data: releasesDetails } = await Axios.get(
        `${globalLinks.api.account.profile}/${params.id}/libraries`,
        {
          cancelToken: axiosCancel.token
        }
      );
      _isMounted && setReleases({
        ...releases,
        loading: false,
        data: releasesDetails?.data
      });
    } catch (error) {
      _isMounted && setReleases({
        ...releases,
        loading: false,
        show: false,
      });
      // @ts-ignore
      if (error.response && error.response.data && error.response.data.code) {
        _isMounted && dispatch({
          type: 'ERROR',
          // @ts-ignore
          payload: error.response.data.errors,
        });
      } else {
        _isMounted && dispatch({
          type: 'ERROR',
          payload: { global: uiTexts.global.network },
        });
      }
    }
  }
  const onOpenCSVReleasesForm = () => {
    _isMounted && setIsCSVReleasesOpened(!isCSVReleasesOpened);
  }
  const onToggleReferralsForm = () => {
    setIsReferralsFormOpen(!isReferralsFormOpen);
  }
  const onToggleReferralRemovalModal = (referralId?: string) => () => {
    let newreferralRemovalData;

    if (referralId !== undefined) {
      newreferralRemovalData = {
        id: referralId,
        showModal: true
      };
    } else {
      newreferralRemovalData = {
        id: null,
        showModal: false
      }
    }
    setReferralRemoval(newreferralRemovalData as any);
  }
  const onRemoveReferral = async () => {
    dispatch({
      type: "LOADING",
      payload: true
    });

    try {
      cancelPreviousRequest();
      const referralId = referralRemoval.id;
      const { data: response } = await Axios.delete(
        `${globalLinks.api.account.referrals.root}/${referralId}/delete`,
        {
          headers: {
            'X-Csrf-Token': authContext.csrf,
          },
          signal: newAbortSignal()
        }
      );

      if (response.code === 'success') {
        const newReferrals = [...listOfReferrals].filter((referral: any) => `${referral.id}` !== `${referralId}`);
        dispatch({
          type: 'SUCCESS',
          payload: response.message,
        });
        // update referrals list after each removal
        dispatchReferrals({
          type: "SET_LIST_WITH_MESSAGE",
          payload: {
            message: null,
            data: newReferrals
          }
        });
      } else {
        throw new Error('failed');
      }
    } catch (error) {
      let payload;
      // @ts-ignore
      if (error.response && error.response.data && error.response.data.code) {
        // @ts-ignore
        payload = error.response.data.errors;
      } else {
        payload = { global: uiTexts.global.network };
      }
      dispatch({
        type: 'ERROR',
        payload
      });
    }
    setReferralRemoval({
      id: null,
      showModal: false
    });
  }
  const onChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name: key, value } = event.target;
    dispatch({
      type: "SET_FIELD",
      payload: {
        key,
        value
      }
    });
  }
  // set the existing platform checkboxes
  const onChangeExistingPlatforms = (event: React.ChangeEvent<HTMLInputElement>) => {
    let existing_platforms = [...(state.data?.existing_platforms ?? [])];
    const { name: key, value, checked } = event.target;

    if (checked === true && !existing_platforms.includes(value)) {
      existing_platforms.push(value)
    } else {
      const newPlatforms = existing_platforms.filter((platform: string) => {
        return platform !== value;
      });
      existing_platforms = newPlatforms;
    }

    dispatch({
      type: "SET_FIELD",
      payload: {
        key,
        value: existing_platforms
      }
    });
  }

  useEffect(() => {
    const loadprofileData = async () => {
      try {
        const { data } = await Axios.get(`${globalLinks.api.account.profile}/${params.id}/profile`, {
          cancelToken: axiosCancel.token,
        });
        if (data.code === 'success') {
          data.data.existing_platforms = JSON.parse(data.data.existing_platforms || "[]");
          _isMounted && dispatch({
            type: 'SET_FIELDS',
            payload: data.data,
          });
        }
      } catch (error) {
        // @ts-ignore
        if (error.response && error.response.data && error.response.data.code) {
          _isMounted && dispatch({
            type: 'ERROR',
            // @ts-ignore
            payload: error.response.data.errors,
          });
        } else {
          _isMounted && dispatch({
            type: 'ERROR',
            payload: { global: uiTexts.global.network },
          });
        }
      }
    }
    loadprofileData();
    return () => {
      _isMounted = false;
      axiosCancel.cancel('Operation canceled');
    }
  }, []);

  return (
    <PageLayout className='page__bloc pt-3 pt-md-5 pb-3 pb-md-5' title={uiTexts.account.profil.title}>
      <LoaderIndicator
        loading={state.loading}
        testid='spinner'
        className='z_index_max position-fixed'
      />
      <div className="action__block mb-4">
        <h1 className='h5 mb-0'>{uiTexts.account.profil.title}</h1>
      </div>

      <ButtonGroup className="mb-5">
        <Link className='btn btn-outline-primary' to={`/accounts/${params.id}/billings`}>
          <CreditCard size={globalSizes.icon.default} />
          <span className="ml-2 d-none d-sm-inline">{uiTexts.account.billing.main.title}</span>
        </Link>
      </ButtonGroup>

      <AlertMessage
        message={alertMessage}
        variant={alertVariant}
        show={showAlert}
        className='mb-4'
        onClose={onClose("SUCCESS", null)}
      />

      <ProfileForm
        onSubmit={onSubmit}
        state={state}
        onChange={onChange}
        onChangeRole={onChangeRole}
        onChangeCheckBox={onChangeCheckBox}
        onChangeExistingPlatforms={onChangeExistingPlatforms}
      />

      <div className="my-4">
        <h2 className='h5'>{uiTexts.account.profil.referrals.referralsList}</h2>
      </div>
      <TableList
        headers={['#', uiTexts.account.profil.referrals.referred, uiTexts.account.profil.referrals.percentile, "Supprimer"]}
      >
        {
          listOfReferrals?.length === 0
            ? (
              <tr>
                <td colSpan={4}>
                  <h4 className='h5 text-muted text-center'>
                    <Slash size={globalSizes.icon.xm} className='mr-2' />
                    {uiTexts.global.noOptions}
                  </h4>
                </td>
              </tr>
            )
            : (
              listOfReferrals?.map((referral: Record<string, any>) => (
                <tr
                  key={`referral-${referral?.id}`}
                  className={`table-list__item`}
                >
                  <td className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                    {referral?.id}
                  </td>
                  <td className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                    {`${referral?.referred?.fname} ${referral?.referred?.lname}`}
                  </td>
                  <td className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                    {`${referral.percentile}%`}
                  </td>
                  <td className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                    <Button variant='outline-danger' onClick={onToggleReferralRemovalModal(referral.id)}>
                      {uiTexts.global.remove}
                    </Button>
                  </td>
                </tr>
              ))
            )
        }
      </TableList>

      <Modal
        show={referralRemoval.showModal}
        onHide={onToggleReferralRemovalModal()}
        centered
      >
        <Modal.Body>
          <Modal.Title className='mb-4 position-sticky bg-white sticky_top'>
            {uiTexts.account.profil.referrals.deleteItem}
          </Modal.Title>
          <div className="d-flex justify-content-end mt-n2 position-sticky bg-white sticky_bottom">
            <Button
              variant='outline-secondary'
              className='border_radius_left-side_lg'
              onClick={onToggleReferralRemovalModal()}
            >
              {uiTexts.global.close}
            </Button>
            <Button
              variant='outline-danger'
              className='border_radius_right-side_lg'
              disabled={state.loading}
              onClick={onRemoveReferral}
            >
              {uiTexts.global.remove}
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <ProfileReleases
        releases={releases}
        onToggleModal={onToggleModal}
        onLoadReleases={onLoadReleases}
        isCsvDownloadable={isCsvDownloadable}
        csvFilename={csvFilename}
      />

      <ProfileCSVReleasesForm
        user={params.id}
        onToggleModal={onOpenCSVReleasesForm}
        show={isCSVReleasesOpened}
      />

      {
        isReferralsFormOpen && (
          <ProfileReferralsForm
            show={isReferralsFormOpen}
            onToggleModal={onToggleReferralsForm}
          />
        )
      }

    </PageLayout>
  );
}
