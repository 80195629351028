/**
 * Render routes/ui depend on admin capabilities
 * 
 * @param ucapabilities current admin(user) capabilities has
 * @param requiredCapabilities list of capabilities must to have to reach the given route(component/ui)
 * @param route which JSX to render
 * @returns the JSX to render/null
 */
export const renderRouteIfAllowedTo = (ucapabilities: string[], requiredCapabilities: string[], route: JSX.Element) => {
  let isAllowed = true;

  for (const capability of requiredCapabilities) {
    if (!ucapabilities.includes(capability)) {
      isAllowed = false;
      break;
    }
  }

  return isAllowed && route;
}

/**
 * Render routes/ui if admin has one of the required capabilities
 * 
 * @param ucapabilities current admin(user) capabilities has
 * @param requiredCapabilities list of capabilities to have
 * @param route which JSX to render
 * @returns the JSX to render/null
 */
export const renderRouteIfOneOfExist = (ucapabilities: string[], requiredCapabilities: string[], route: JSX.Element) => {
  let isAllowed = false;

  for (const capability of requiredCapabilities) {
    if (ucapabilities.includes(capability)) {
      isAllowed = true;
      break;
    }
  }

  return isAllowed && route;
}