import React, { useContext, useReducer } from 'react';
import PageLayout from '../../libs/PageLayout';
import LoaderIndicator from '../../components/LoaderIndicator';
import uiTexts from '../../config/text';
import GradientButton from '../../components/GradientButton';
import { Plus } from 'react-feather';
import globalSizes from '../../config/sizes';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import { globalReducer } from '../../store/reducer';
import AdminForm from '../../components/AdminForm';
import { useManageAdmins } from '../../hooks/use-manage-admins';
import useAbortController from '../../hooks/use-abort-controller';
import axios, { AxiosRequestConfig } from 'axios';
import AuthContext from '../../context/auth';
import globalLinks from '../../config/links';
import AlertMessage from '../../components/AlertMessage';
import TableList from '../../components/TableList';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const initialState = {
  loading: false,
  errors: {},
  global: null,
  data: {
    showModal: false,
    adminDetails: {},
    showDeleteModal: false,
    adminId: null
  },
}
export default function ManageAdmins() {
  const [state, dispatch] = useReducer(globalReducer, initialState);
  const [{ loading, data: { admins, capabilities }, errors }, dispatchInitialData] = useManageAdmins();
  const { newAbortSignal, cancelPreviousRequest } = useAbortController();
  const { csrf } = useContext(AuthContext);
  const alertMessage = state?.global || state.errors?.global || errors?.global;
  const alertVariant = !!state?.global ? "success" : "danger";
  const onClose = () => {
    dispatch({
      type: "SUCCESS",
      payload: null,
    })
  }
  const onToggleModal = (adminDetails: object | null = null) => () => {
    dispatch({
      type: 'SET_FIELDS',
      payload: {
        ...state.data,
        adminDetails: adminDetails ?? {},
        showModal: !state.data.showModal,
      }
    });
  }
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, } = event.currentTarget;

    dispatch({
      type: "SET_FIELD",
      payload: {
        key: "adminDetails",
        value: {
          ...state.data.adminDetails,
          [name]: value
        }
      }
    });
  }
  const onSelectCapabilities = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = event.currentTarget;
    let selectedCapabilities: string[] = state.data.adminDetails?.capabilities || [];

    if (checked) {
      selectedCapabilities.push(value);
    } else {
      selectedCapabilities = selectedCapabilities.filter((capId) => `${capId}` !== `${value}`);
    }

    dispatch({
      type: "SET_FIELD",
      payload: {
        key: "adminDetails",
        value: {
          ...state.data.adminDetails,
          [name]: selectedCapabilities
        }
      }
    });
  }
  const onSaveAdminDetails = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch({
      type: "LOADING",
      payload: true
    });

    try {
      cancelPreviousRequest();
      const options: AxiosRequestConfig<any> = {
        headers: {
          'X-Csrf-Token': csrf,
        },
        signal: newAbortSignal()
      };
      const { adminDetails } = state.data;
      const endpoint = !!adminDetails?.id
        ? `${globalLinks.api.account.manageAdmins.item}/${adminDetails?.id}`
        : `${globalLinks.api.account.manageAdmins.addAdmin}`;
      const method = !!adminDetails?.id ? "put" : "post";
      const { data: response } = await axios[method](
        endpoint,
        adminDetails,
        options
      );

      if (response.code === 'success') {
        dispatch({
          type: 'RESET_STATE',
          payload: {
            ...initialState,
            global: response.message,
            data: {
              ...initialState.data,
              showModal: false,
            }
          }
        });
      } else {
        throw new Error('failed');
      }
    } catch (error) {
      let payload;
      // @ts-ignore
      if (error?.response?.data?.code) {
        // @ts-ignore
        payload = error.response.data.errors;
      } else {
        payload = { global: uiTexts.global.network };
      }
      dispatch({
        type: 'ERROR',
        payload
      });
    }
  }
  const onOpenDeleteAdminModal = (adminId: string | null = null) => (event: React.MouseEvent<HTMLButtonElement>) => {
    event?.stopPropagation();
    dispatch({
      type: 'SET_FIELDS',
      payload: {
        ...state.data,
        showDeleteModal: !state.data.showDeleteModal,
        adminId
      }
    });
  }
  const onRemoveAdmin = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    dispatch({
      type: "LOADING",
      payload: true
    });

    try {
      cancelPreviousRequest();
      const options: AxiosRequestConfig<any> = {
        headers: {
          'X-Csrf-Token': csrf,
        },
        signal: newAbortSignal()
      };
      const { adminId } = state.data;
      const { data: response } = await axios.delete(
        `${globalLinks.api.account.manageAdmins.item}/${adminId}`,
        options
      );

      if (response.code === 'success') {
        const newAdmins = admins.filter((admin: Record<string, string>) => admin.id !== adminId);
        dispatch({
          type: 'RESET_STATE',
          payload: {
            ...initialState,
            global: response.message,
            data: {
              ...initialState.data,
              showModal: false,
            }
          }
        });
        dispatchInitialData({
          type: 'SET_FIELD',
          payload: {
            key: 'admins',
            value: newAdmins
          }
        });
      } else {
        throw new Error('failed');
      }
    } catch (error) {
      let payload;
      // @ts-ignore
      if (error?.response?.data?.code) {
        // @ts-ignore
        payload = error.response.data.errors;
      } else {
        payload = { global: uiTexts.global.network };
      }
      dispatch({
        type: 'ERROR',
        payload
      });
    }
  }

  return (
    <PageLayout
      className='page__bloc pt-3 pt-md-5 pb-3 pb-md-5 main__block'
      title={uiTexts.account.manageAdmins.title}
    >
      <LoaderIndicator
        loading={loading || state.loading}
        testid='spinner'
        className='z_index_max'
      />
      <div className="action__block mb-3 mb-md-5">
        <h1 className='h4'>{uiTexts.tools.ticket.title}</h1>
        <blockquote className='note__block text-muted mb-0 p-2 pt-1 pb-1 rounded'>
          Gérer les administrateurs
        </blockquote>
      </div>
      <AlertMessage
        message={alertMessage}
        variant={alertVariant}
        show={!!state.errors.global || !!state.global}
        onClose={onClose}
      />
      <div className="d-flex justify-content-between mb-3">
        <h2 className='h5'>{uiTexts.account.manageAdmins.list}</h2>
        <GradientButton
          className='text-white'
          onClick={onToggleModal()}
        >
          <Plus size={globalSizes.icon.default} className='mr-2' />
          {uiTexts.account.manageAdmins.addAdmin}
        </GradientButton>
      </div>
      <TableList headers={["#", `${uiTexts.signup.form.fname} - ${uiTexts.signup.form.lname}`, uiTexts.signup.form.email, uiTexts.global.remove]}>
        {
          admins?.map(
            (admin: Record<string, string>) => {
              return (
                <tr key={`admin-${admin.id}`}
                  className={`table-list__item`}
                  onClick={onToggleModal(admin)}
                >
                  <td key={`${admin.id}-id`} className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                    #{admin.id}
                  </td>
                  <td key={`${admin.id}-lname`} className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                    {admin.lname} {admin.fname}
                  </td>
                  <td key={`${admin.id}-fname`} className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                    {admin.email}
                  </td>
                  <td key={`${admin.id}-email`} className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                    <Button
                      variant='outline-danger'
                      onClick={onOpenDeleteAdminModal(admin.id)}
                    >
                      {uiTexts.global.remove}
                    </Button>
                  </td>
                </tr>
              );
            }
          )
        }
      </TableList>
      <Modal
        show={state.data.showModal}
        onHide={onToggleModal()}
        aria-labelledby={uiTexts.account.manageAdmins.addAdmin}
        animation
        centered
      >
        <Modal.Header className='border-0' closeButton>
          <Modal.Title>
            {uiTexts.account.manageAdmins.addAdmin}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='show-grid pl-0 pr-0'>
          <Container>
            <AdminForm
              data={state.data.adminDetails}
              capabilities={capabilities}
              loading={state.loading}
              errors={state.errors}
              onChange={onChange}
              onSelectCapabilities={onSelectCapabilities}
              onSubmit={onSaveAdminDetails}
            />
          </Container>
        </Modal.Body>
      </Modal>
      <Modal
        show={state.data.showDeleteModal}
        onHide={onOpenDeleteAdminModal()}
        aria-labelledby={uiTexts.account.manageAdmins.addAdmin}
        animation
        centered
      >
        <Modal.Header className='border-0' closeButton>
          <Modal.Title>
            {uiTexts.account.manageAdmins.deleteAdmin}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='show-grid pl-0 pr-0'>
          <Container>
            <Row>
              <Col>
                {uiTexts.account.manageAdmins.deleteAdminConfirmation}
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col className='d-flex gap-3 justify-content-end'>
                <Button
                  onClick={onOpenDeleteAdminModal()}
                  variant='outline-secondary'
                >
                  {uiTexts.global.cancel}
                </Button>
                <Button
                  variant='outline-danger'
                  onClick={onRemoveAdmin}
                >
                  {uiTexts.global.remove}
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </PageLayout>
  )
}
