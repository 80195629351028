import React from 'react';
import Form from 'react-bootstrap/Form';

interface FormField {
  label: string;
  name: string;
  value?: string;
  defaultValue?: string;
  placeholder?: string;
  required?: boolean;
  autoFocus?: boolean;
  error?: string | null;
  size?: 'lg' | 'sm';
  readonly?: boolean;
  type?: string;
  as?: React.ElementType;
  autoComplete?: string;
  text?: string;
  step?: any;
  pattern?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
}

export default function FormField({ label, name, value, type, defaultValue, onChange, onClick, placeholder = '', required = false, autoFocus = false, readonly = false, error = null, size = 'lg', autoComplete = 'off', as, text, step,pattern }: FormField) {
  return (
    <Form.Group controlId={name}>
      <Form.Label className='fl-uppercase'>{label}</Form.Label>
      <Form.Control type={type} size={size} onChange={onChange} as={as} step={step} onClick={onClick} defaultValue={defaultValue} value={value} name={name} placeholder={placeholder} required={required} isInvalid={!!error} autoFocus={autoFocus} readOnly={readonly} autoComplete={autoComplete} pattern={pattern} />
      {
        !!error &&
        <Form.Control.Feedback type="invalid">
          {error}
        </Form.Control.Feedback>
      }
      {
        text && (
          <Form.Text className="text-muted fl-text-capitalize">
            {text}
          </Form.Text>
        )
      }
    </Form.Group>
  )
}
