const languagesList = [
  {
    "label": "Arabic",
    "value": "Arabic"
  },
  {
    "label": "Abkhazian",
    "value": "Abkhazian"
  },
  {
    "label": "Afar",
    "value": "Afar"
  },
  {
    "label": "Afrikaans",
    "value": "Afrikaans"
  },
  {
    "label": "Akan",
    "value": "Akan"
  },
  {
    "label": "Albanian",
    "value": "Albanian"
  },
  {
    "label": "Amharic",
    "value": "Amharic"
  },
  {
    "label": "Aragonese",
    "value": "Aragonese"
  },
  {
    "label": "Armenian",
    "value": "Armenian"
  },
  {
    "label": "Assamese",
    "value": "Assamese"
  },
  {
    "label": "Avaric",
    "value": "Avaric"
  },
  {
    "label": "Avestan",
    "value": "Avestan"
  },
  {
    "label": "Aymara",
    "value": "Aymara"
  },
  {
    "label": "Azerbaijani",
    "value": "Azerbaijani"
  },
  {
    "label": "Bambara",
    "value": "Bambara"
  },
  {
    "label": "Bashkir",
    "value": "Bashkir"
  },
  {
    "label": "Basque",
    "value": "Basque"
  },
  {
    "label": "Belarusian",
    "value": "Belarusian"
  },
  {
    "label": "Bengali",
    "value": "Bengali"
  },
  {
    "label": "Bihari languages",
    "value": "Bihari languages"
  },
  {
    "label": "Bislama",
    "value": "Bislama"
  },
  {
    "label": "Bosnian",
    "value": "Bosnian"
  },
  {
    "label": "Breton",
    "value": "Breton"
  },
  {
    "label": "Bulgarian",
    "value": "Bulgarian"
  },
  {
    "label": "Burmese",
    "value": "Burmese"
  },
  {
    "label": "Cantonese",
    "value": "Cantonese"
  },
  {
    "label": "Catalan",
    "value": "Catalan"
  },
  {
    "label": "Central Khmer",
    "value": "Central Khmer"
  },
  {
    "label": "Chamorro",
    "value": "Chamorro"
  },
  {
    "label": "Chechen",
    "value": "Chechen"
  },
  {
    "label": "Chichewa",
    "value": "Chichewa"
  },
  {
    "label": "Chinese Simplified",
    "value": "Chinese Simplified"
  },
  {
    "label": "Chinese Traditional",
    "value": "Chinese Traditional"
  },
  {
    "label": "Church Slavic",
    "value": "Church Slavic"
  },
  {
    "label": "Chuvash",
    "value": "Chuvash"
  },
  {
    "label": "Cornish",
    "value": "Cornish"
  },
  {
    "label": "Corsican",
    "value": "Corsican"
  },
  {
    "label": "Cree",
    "value": "Cree"
  },
  {
    "label": "Croatian",
    "value": "Croatian"
  },
  {
    "label": "Czech",
    "value": "Czech"
  },
  {
    "label": "Danish",
    "value": "Danish"
  },
  {
    "label": "Divehi",
    "value": "Divehi"
  },
  {
    "label": "Dutch",
    "value": "Dutch"
  },
  {
    "label": "Dzongkha",
    "value": "Dzongkha"
  },
  {
    "label": "English",
    "value": "English"
  },
  {
    "label": "Esperanto",
    "value": "Esperanto"
  },
  {
    "label": "Estonian",
    "value": "Estonian"
  },
  {
    "label": "Ewe",
    "value": "Ewe"
  },
  {
    "label": "Faroese",
    "value": "Faroese"
  },
  {
    "label": "Fijian",
    "value": "Fijian"
  },
  {
    "label": "Finnish",
    "value": "Finnish"
  },
  {
    "label": "French",
    "value": "French"
  },
  {
    "label": "Fulah",
    "value": "Fulah"
  },
  {
    "label": "Gaelic",
    "value": "Gaelic"
  },
  {
    "label": "Galician",
    "value": "Galician"
  },
  {
    "label": "Ganda",
    "value": "Ganda"
  },
  {
    "label": "Georgian",
    "value": "Georgian"
  },
  {
    "label": "German",
    "value": "German"
  },
  {
    "label": "Greek",
    "value": "Greek"
  },
  {
    "label": "Guarani",
    "value": "Guarani"
  },
  {
    "label": "Gujarati",
    "value": "Gujarati"
  },
  {
    "label": "Haitian",
    "value": "Haitian"
  },
  {
    "label": "Hausa",
    "value": "Hausa"
  },
  {
    "label": "Hebrew",
    "value": "Hebrew"
  },
  {
    "label": "Herero",
    "value": "Herero"
  },
  {
    "label": "Hindi",
    "value": "Hindi"
  },
  {
    "label": "Hiri Motu",
    "value": "Hiri Motu"
  },
  {
    "label": "Hungarian",
    "value": "Hungarian"
  },
  {
    "label": "Icelandic",
    "value": "Icelandic"
  },
  {
    "label": "Ido",
    "value": "Ido"
  },
  {
    "label": "Igbo",
    "value": "Igbo"
  },
  {
    "label": "Indonesian",
    "value": "Indonesian"
  },
  {
    "label": "Instrumental",
    "value": "Instrumental"
  },
  {
    "label": "Interlingua",
    "value": "Interlingua"
  },
  {
    "label": "Interlingue",
    "value": "Interlingue"
  },
  {
    "label": "Inuktitut",
    "value": "Inuktitut"
  },
  {
    "label": "Inupiaq",
    "value": "Inupiaq"
  },
  {
    "label": "Irish",
    "value": "Irish"
  },
  {
    "label": "Italian",
    "value": "Italian"
  },
  {
    "label": "Japanese",
    "value": "Japanese"
  },
  {
    "label": "Javanese",
    "value": "Javanese"
  },
  {
    "label": "Kalaallisut",
    "value": "Kalaallisut"
  },
  {
    "label": "Kannada",
    "value": "Kannada"
  },
  {
    "label": "Kanuri",
    "value": "Kanuri"
  },
  {
    "label": "Kashmiri",
    "value": "Kashmiri"
  },
  {
    "label": "Kazakh",
    "value": "Kazakh"
  },
  {
    "label": "Kikuyu",
    "value": "Kikuyu"
  },
  {
    "label": "Kinyarwanda",
    "value": "Kinyarwanda"
  },
  {
    "label": "Kirghiz",
    "value": "Kirghiz"
  },
  {
    "label": "Komi",
    "value": "Komi"
  },
  {
    "label": "Kongo",
    "value": "Kongo"
  },
  {
    "label": "Korean",
    "value": "Korean"
  },
  {
    "label": "Kuanyama",
    "value": "Kuanyama"
  },
  {
    "label": "Kurdish",
    "value": "Kurdish"
  },
  {
    "label": "Lao",
    "value": "Lao"
  },
  {
    "label": "Latin",
    "value": "Latin"
  },
  {
    "label": "Latvian",
    "value": "Latvian"
  },
  {
    "label": "Limburgan",
    "value": "Limburgan"
  },
  {
    "label": "Lingala",
    "value": "Lingala"
  },
  {
    "label": "Lithuanian",
    "value": "Lithuanian"
  },
  {
    "label": "Luba-Katanga",
    "value": "Luba-Katanga"
  },
  {
    "label": "Luxembourgish",
    "value": "Luxembourgish"
  },
  {
    "label": "Macedonian",
    "value": "Macedonian"
  },
  {
    "label": "Malagasy",
    "value": "Malagasy"
  },
  {
    "label": "Malay",
    "value": "Malay"
  },
  {
    "label": "Malayalam",
    "value": "Malayalam"
  },
  {
    "label": "Maltese",
    "value": "Maltese"
  },
  {
    "label": "Mandarin",
    "value": "Mandarin"
  },
  {
    "label": "Manx",
    "value": "Manx"
  },
  {
    "label": "Maori",
    "value": "Maori"
  },
  {
    "label": "Marathi",
    "value": "Marathi"
  },
  {
    "label": "Marshallese",
    "value": "Marshallese"
  },
  {
    "label": "Mongolian",
    "value": "Mongolian"
  },
  {
    "label": "Nauru",
    "value": "Nauru"
  },
  {
    "label": "Navajo",
    "value": "Navajo"
  },
  {
    "label": "Ndonga",
    "value": "Ndonga"
  },
  {
    "label": "Nepali",
    "value": "Nepali"
  },
  {
    "label": "North Ndebele",
    "value": "North Ndebele"
  },
  {
    "label": "Northern Sami",
    "value": "Northern Sami"
  },
  {
    "label": "Norwegian",
    "value": "Norwegian"
  },
  {
    "label": "Norwegian Bokmål",
    "value": "Norwegian Bokmål"
  },
  {
    "label": "Norwegian Nynorsk",
    "value": "Norwegian Nynorsk"
  },
  {
    "label": "Occitan",
    "value": "Occitan"
  },
  {
    "label": "Ojibwa",
    "value": "Ojibwa"
  },
  {
    "label": "Oriya",
    "value": "Oriya"
  },
  {
    "label": "Oromo",
    "value": "Oromo"
  },
  {
    "label": "Ossetian",
    "value": "Ossetian"
  },
  {
    "label": "Pali",
    "value": "Pali"
  },
  {
    "label": "Pashto",
    "value": "Pashto"
  },
  {
    "label": "Persian",
    "value": "Persian"
  },
  {
    "label": "Polish",
    "value": "Polish"
  },
  {
    "label": "Portuguese",
    "value": "Portuguese"
  },
  {
    "label": "Punjabi",
    "value": "Punjabi"
  },
  {
    "label": "Quechua",
    "value": "Quechua"
  },
  {
    "label": "Romanian",
    "value": "Romanian"
  },
  {
    "label": "Romansh",
    "value": "Romansh"
  },
  {
    "label": "Rundi",
    "value": "Rundi"
  },
  {
    "label": "Russian",
    "value": "Russian"
  },
  {
    "label": "Samoan",
    "value": "Samoan"
  },
  {
    "label": "Sango",
    "value": "Sango"
  },
  {
    "label": "Sanskrit",
    "value": "Sanskrit"
  },
  {
    "label": "Sardinian",
    "value": "Sardinian"
  },
  {
    "label": "Serbian",
    "value": "Serbian"
  },
  {
    "label": "Shona",
    "value": "Shona"
  },
  {
    "label": "Sichuan Yi",
    "value": "Sichuan Yi"
  },
  {
    "label": "Sindhi",
    "value": "Sindhi"
  },
  {
    "label": "Sinhala",
    "value": "Sinhala"
  },
  {
    "label": "Slovak",
    "value": "Slovak"
  },
  {
    "label": "Slovenian",
    "value": "Slovenian"
  },
  {
    "label": "Somali",
    "value": "Somali"
  },
  {
    "label": "South Ndebele",
    "value": "South Ndebele"
  },
  {
    "label": "Southern Sotho",
    "value": "Southern Sotho"
  },
  {
    "label": "Spanish",
    "value": "Spanish"
  },
  {
    "label": "Spanish (Latin America)",
    "value": "Spanish (Latin America)"
  },
  {
    "label": "Sundanese",
    "value": "Sundanese"
  },
  {
    "label": "Swahili",
    "value": "Swahili"
  },
  {
    "label": "Swati",
    "value": "Swati"
  },
  {
    "label": "Swedish",
    "value": "Swedish"
  },
  {
    "label": "Tagalog",
    "value": "Tagalog"
  },
  {
    "label": "Tahitian",
    "value": "Tahitian"
  },
  {
    "label": "Tajik",
    "value": "Tajik"
  },
  {
    "label": "Tamil",
    "value": "Tamil"
  },
  {
    "label": "Tatar",
    "value": "Tatar"
  },
  {
    "label": "Telugu",
    "value": "Telugu"
  },
  {
    "label": "Thai",
    "value": "Thai"
  },
  {
    "label": "Tibetan",
    "value": "Tibetan"
  },
  {
    "label": "Tigrinya",
    "value": "Tigrinya"
  },
  {
    "label": "Tonga",
    "value": "Tonga"
  },
  {
    "label": "Tsonga",
    "value": "Tsonga"
  },
  {
    "label": "Tswana",
    "value": "Tswana"
  },
  {
    "label": "Turkish",
    "value": "Turkish"
  },
  {
    "label": "Turkmen",
    "value": "Turkmen"
  },
  {
    "label": "Twi",
    "value": "Twi"
  },
  {
    "label": "Uighur",
    "value": "Uighur"
  },
  {
    "label": "Ukrainian",
    "value": "Ukrainian"
  },
  {
    "label": "Urdu",
    "value": "Urdu"
  },
  {
    "label": "Uzbek",
    "value": "Uzbek"
  },
  {
    "label": "Venda",
    "value": "Venda"
  },
  {
    "label": "Vietnamese",
    "value": "Vietnamese"
  },
  {
    "label": "Volapük",
    "value": "Volapük"
  },
  {
    "label": "Walloon",
    "value": "Walloon"
  },
  {
    "label": "Welsh",
    "value": "Welsh"
  },
  {
    "label": "Western Frisian",
    "value": "Western Frisian"
  },
  {
    "label": "Wolof",
    "value": "Wolof"
  },
  {
    "label": "Xhosa",
    "value": "Xhosa"
  },
  {
    "label": "Yiddish",
    "value": "Yiddish"
  },
  {
    "label": "Yoruba",
    "value": "Yoruba"
  },
  {
    "label": "Zhuang",
    "value": "Zhuang"
  },
  {
    "label": "Zulu",
    "value": "Zulu"
  }
];

export default languagesList;