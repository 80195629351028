import React from 'react';
import globalSizes from '../../config/sizes'
import CardIconPropsTypes from './types';

import './style.css';

export default function CardIcon({ Icon, title, content, variant, size = globalSizes.icon.default }: CardIconPropsTypes) {
  const variantClassName = `card-icon__${variant}`;

  return (
    <div className={`card-icon d-flex justify-content-between align-items-center px-3 py-4 px-lg-4 py-lg-5 border_radius_4 ${variantClassName}`}>
      <div className='card-icon__icon-block d-flex align-items-center justify-content-center p-2 border_radius_3'>
        <div className='card-icon__icon-wrapper d-flex align-items-center justify-content-center rounded-circle p-1'>
          <Icon
            size={size}
            className='card-icon__icon'
          />
        </div>
      </div>
      <div className='card-icon__text flex_1 text-center'>
        <h3 className='h6 card-icon__title'>
          {title}
        </h3>
        <h3 className='h5 card-icon__content font-weight-bold'>
          {content}
        </h3>
      </div>
    </div>
  )
}
