const instrumentsList = [
  { label: 'Guitare Acoustique', value: 'Guitare Acoustique' },
  { label: 'Alto', value: 'Alto' },
  { label: 'Saxophone alto', value: 'Saxophone alto' },
  { label: 'Alto solo', value: 'Alto solo' },
  { label: 'Chœurs', value: 'Chœurs' },
  { label: 'Banjo', value: 'Banjo' },
  { label: 'Baryton', value: 'Baryton' },
  { label: 'Saxophone baryton', value: 'Saxophone baryton' },
  { label: 'Basse', value: 'Basse' },
  { label: 'Baryton-Basse', value: 'Baryton-Basse' },
  { label: 'Clarinette basse', value: 'Clarinette basse' },
  { label: 'Trombone basse', value: 'Trombone basse' },
  { label: 'Viole de gambe', value: 'Viole de gambe' },
  { label: 'Basson', value: 'Basson' },
  { label: 'Bongos', value: 'Bongos' },
  { label: 'Garçon Soprano', value: 'Garçon Soprano' },
  { label: 'Cajon', value: 'Cajon' },
  { label: 'Violoncelle', value: 'Violoncelle' },
  { label: 'Chef de chœur', value: 'Chef de chœur' },
  { label: 'Chorale', value: 'Chorale' },
  { label: 'Clarinette', value: 'Clarinette' },
  { label: 'Artiste classique', value: 'Artiste classique' },
  { label: 'Guitare classique', value: 'Guitare classique' },
  { label: 'Clavecin', value: 'Clavecin' },
  { label: 'Congas', value: 'Congas' },
  { label: 'Cornet', value: 'Cornet' },
  { label: 'Contre-ténor', value: 'Contre-ténor' },
  { label: 'Dj', value: 'Dj' },
  { label: 'Djembe', value: 'Djembe' },
  { label: 'Contrebasse', value: 'Contrebasse' },
  { label: 'Batterie programmée', value: 'Batterie programmée' },
  { label: 'Batterie', value: 'Batterie' },
  { label: 'Guitare électrique', value: 'Guitare électrique' },
  { label: 'Ingénieur', value: 'Ingénieur' },
  { label: 'Chant (Featuring)', value: 'Chant (Featuring)' },
  { label: 'Premier violon', value: 'Premier violon' },
  { label: 'Bugle', value: 'Bugle' },
  { label: 'Flute', value: 'Flute' },
  { label: 'Cor d’harmonie', value: 'Cor d’harmonie' },
  {
    label: 'Guitare basse électrique fretless',
    value: 'Guitare basse électrique fretless'
  },
  { label: 'Guitare', value: 'Guitare' },
  { label: 'Technicien guitares', value: 'Technicien guitares' },
  { label: 'Orgue Hammond', value: 'Orgue Hammond' },
  { label: 'Harmonica', value: 'Harmonica' },
  { label: 'Harpe', value: 'Harpe' },
  { label: 'Clavecin', value: 'Clavecin' },
  { label: 'Cor', value: 'Cor' },
  { label: 'Clavier', value: 'Clavier' },
  { label: 'Kora', value: 'Kora' },
  { label: 'Guitare principale', value: 'Guitare principale' },
  { label: 'Voix principale', value: 'Voix principale' },
  { label: 'Librettiste', value: 'Librettiste' },
  { label: 'Mandoline', value: 'Mandoline' },
  { label: 'Ingénieur mastering', value: 'Ingénieur mastering' },
  { label: 'Mezzo-soprano', value: 'Mezzo-soprano' },
  { label: 'Mixeur', value: 'Mixeur' },
  { label: 'Directeur musical', value: 'Directeur musical' },
  { label: 'Hautbois', value: 'Hautbois' },
  { label: 'Orgue', value: 'Orgue' },
  { label: 'Autre instrument', value: 'Autre instrument' },
  { label: 'Steel Guitare a pédale', value: 'Steel Guitare a pédale' },
  { label: 'Percussion', value: 'Percussion' },
  { label: 'Piano', value: 'Piano' },
  { label: 'Piccolo', value: 'Piccolo' },
  {
    label: 'Assistant de production',
    value: 'Assistant de production'
  },
  { label: 'Programmation', value: 'Programmation' },
  { label: 'Piano Rhodes', value: 'Piano Rhodes' },
  { label: 'Guitare rythmique', value: 'Guitare rythmique' },
  { label: 'Saxophone', value: 'Saxophone' },
  { label: 'Deuxième violon', value: 'Deuxième violon' },
  { label: 'Chanteur/chanteuse', value: 'Chanteur/chanteuse' },
  { label: 'Soprano', value: 'Soprano' },
  { label: 'Saxophone soprano', value: 'Saxophone soprano' },
  { label: 'Ingénieur du son', value: 'Ingénieur du son' },
  { label: 'Orateur', value: 'Orateur' },
  { label: 'Steel Guitar', value: 'Steel Guitar' },
  { label: 'Basse à cordes', value: 'Basse à cordes' },
  { label: 'Tabla', value: 'Tabla' },
  { label: 'Tambourin', value: 'Tambourin' },
  { label: 'Ténor', value: 'Ténor' },
  { label: 'Saxophone ténor', value: 'Saxophone ténor' },
  { label: 'Timbales', value: 'Timbales' },
  { label: 'Timpani', value: 'Timpani' },
  { label: 'Trombone', value: 'Trombone' },
  { label: 'Trompette', value: 'Trompette' },
  { label: 'Tuba', value: 'Tuba' },
  { label: 'Guitare 12 cordes', value: 'Guitare 12 cordes' },
  { label: 'Ukulélé', value: 'Ukulélé' },
  { label: 'Alto', value: 'Alto' },
  { label: 'Viola d’Amore', value: 'Viola d’Amore' },
  { label: 'Violon', value: 'Violon' },
  { label: 'Adaptation vocale', value: 'Adaptation vocale' },
  { label: 'Ingénieur vocal', value: 'Ingénieur vocal' },
  { label: 'Voix d’ensemble', value: 'Voix d’ensemble' },
  { label: 'Voix Solo', value: 'Voix Solo' },
  { label: 'Cithare', value: 'Cithare' }
];

export default instrumentsList;