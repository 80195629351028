import React from 'react';
import Alert from 'react-bootstrap/Alert';

interface AlertMessagePropsTypes {
  message: string;
  variant: "success" | "danger";
  show?: boolean;
  className?: string;
  onClose?: () => void | undefined;
}
export default function AlertMessage({ message, show = false, variant, className, onClose }: AlertMessagePropsTypes) {
  if (show === false) return null;
  return (
    <Alert
      className={className}
      variant={variant}
      onClose={onClose}
      dismissible={!!onClose}
      transition
    >
      {message}
    </Alert>
  )
}
