import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import DatePicker, { registerLocale } from 'react-datepicker';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';

import uiTexts from '../../config/text';
import GradientButton from '../GradientButton';
import CSVReportFormPropsTypes from './types';
import globalSizes from '../../config/sizes';
import FormField from '../FormField';
import reportMessages from '../../config/messages/report';
import "react-datepicker/dist/react-datepicker.css";
import './style.css';

registerLocale('fr', fr);
export default function CSVReportForm({ data, errors, loading, disabled, onSelectDate, onChooseFile, onSubmit }: CSVReportFormPropsTypes) {
  const invalidFileData = errors?.file;
  // custom input to use with <DatePicker />
  const CustomDateInput = React.forwardRef(({ value, onClick, error, name, label }: any, ref: any) => {
    return (
      <FormField
        autoComplete='off'
        defaultValue={value}
        error={error || null}
        label={label}
        name={name}
        onClick={onClick}
        readonly={false} />
    );
  });
  const fromDate = data?.fromDate
    ? new Date(format(data?.fromDate, globalSizes.dates.en.format))
    : undefined;

  return (
    <Form className='form__block mw-100 mt-4' onSubmit={onSubmit}>
      <Form.Row>
        <Col xs={12} md={6} className='mb-4'>
          <DatePicker
            locale='fr'
            selected={fromDate}
            onChange={onSelectDate}
            customInput={
              <CustomDateInput
                name='fromDate'
                label={reportMessages.fromDate.label}
                error={errors?.fromDate || errors?.toDate}
              />
            }
            monthClassName={() => 'calendar-month__date'}
            wrapperClassName='w-100'
            placeholderText={reportMessages.fromDate.label}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            showFullMonthYearPicker
          />
        </Col>
        <Col xs={12} md={6} className='mb-4 pr-0'>
          <Form.Row className='w-100 pl-1'>
            <Form.Group className='w-100 form-file-size_lg' controlId='csv-file'>
              <Form.Control size={"lg"} as={"div"} className={"border-0 p-0"}>
                <Form.File id="csv-file" className="h-100" custom>
                  <Form.File.Input
                    isInvalid={invalidFileData}
                    onChange={onChooseFile}
                  />
                  <Form.File.Label
                    data-browse="Choisir"
                    className='text-truncate'
                  >
                    {
                      data.file?.name
                    }
                  </Form.File.Label>
                  {
                    invalidFileData && (
                      <Form.Control.Feedback type="invalid" className='mb-2'>
                        {invalidFileData}
                      </Form.Control.Feedback>
                    )
                  }
                </Form.File>
              </Form.Control>
              <Form.Label className='fl-uppercase z_index_1'>
                Fichier CSV
              </Form.Label>
            </Form.Group>
          </Form.Row>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <GradientButton
            disabled={disabled}
            type='submit'
            className='text-white'
            size={"lg"}
            block
          >
            {
              loading
                ? uiTexts.global.loading
                : uiTexts.global.save
            }
          </GradientButton>
        </Col>
      </Form.Row>
    </Form>
  )
}
