import React, { useContext, useReducer } from 'react';
import Axios from 'axios';
import { Slash } from 'react-feather';
import uiTexts from '../../config/text';
import PageLayout from '../../libs/PageLayout';
import { globalReducer } from '../../store/reducer';
import globalLinks from '../../config/links';
import globalSizes from '../../config/sizes';
import TableList from '../../components/TableList';
import { useAccounts } from '../../hooks/use-accounts';
import AlertMessage from '../../components/AlertMessage';
import Modal from 'react-bootstrap/Modal';
import Popover from 'react-bootstrap/Popover';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import LoaderIndicator from '../../components/LoaderIndicator';
import AuthContext from '../../context/auth';
import useAbortController from '../../hooks/use-abort-controller';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const { inactivatedProfiles: profilesListTexts } = uiTexts.account;
const initState = {
  loading: false,
  errors: {},
  global: null,
  data: null,
};
const headers = ['id', 'Nom', 'Prenom', 'email'];

export default function ProfileInvitation() {
  let _isMounted = true;
  const { cancelPreviousRequest, newAbortSignal } = useAbortController();
  const { csrf } = useContext(AuthContext);
  const [activationState, activationDispatch] = useReducer(globalReducer, initState);
  const [state, dispatch] = useAccounts(true);
  const alertVariant = !!state.global || !!activationState.global
    ? "success"
    : "danger";
  const alertMessage = (
    state.global ||
    activationState.global ||
    state.errors?.global ||
    activationState.errors?.global
  );
  const alertShow = (
    !!state?.global ||
    !!activationState?.global ||
    !!state.errors?.global ||
    !!activationState?.errors?.global
  );
  const onClose = () => {
    dispatch({
      type: "RESET_STATE",
      payload: {
        ...state,
        errors: null,
        global: null
      },
    });
    activationDispatch({
      type: "RESET_STATE",
      payload: {
        ...activationState,
        errors: null,
        global: null
      },
    });
  }
  const onToggleModal = (profile?: Record<string, string>) => () => {
    activationDispatch({
      type: "RESET_STATE",
      payload: {
        ...activationState,
        loading: false,
        data: profile ?? null
      }
    })
  }
  const onActivateAccount = async () => {
    if (activationState.data === null) {
      return false;
    }

    activationDispatch({
      type: "LOADING",
      payload: true
    });

    try {
      cancelPreviousRequest();
      const { data: response } = await Axios.put(
        `${globalLinks.api.account.profileRoot}/${activationState.data.id}/activate`,
        null,
        {
          signal: newAbortSignal(),
          headers: {
            'X-Csrf-Token': csrf,
          },
        }
      );
      activationDispatch({
        type: "RESET_STATE",
        payload: {
          loading: false,
          errors: response?.errors || null,
          global: response?.message || null,
          data: null,
        }
      });
      if (response.code === "success") {
        const inactiveProfiles = state.data.filter((account: Record<string, string>) => account.id !== activationState.data.id);
        dispatch({
          type: "RESET_STATE",
          payload: {
            ...state,
            data: inactiveProfiles,
          }
        });
      }
    } catch (error) {
      cancelPreviousRequest();
      // @ts-ignore
      const errors = error?.response?.data?.errors ?? {
        global: uiTexts.global.network
      };
      activationDispatch({
        type: "RESET_STATE",
        payload: {
          loading: false,
          errors: errors,
          global: null,
          data: null,
        }
      })
    }
  }
  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">
        Activer le compte de{` `}
        {activationState?.data?.fname} {activationState?.data?.lname}
      </Popover.Title>
      <Popover.Content>
        <Button
          variant='success'
          disabled={activationState.loading}
          onClick={onActivateAccount}
        >
          {
            activationState.loading
              ? uiTexts.global.loading
              : uiTexts.global.activate
          }
        </Button>
      </Popover.Content>
    </Popover>
  );

  return (
    <PageLayout
      className='page__bloc pt-3 pt-md-5 pb-3 pb-md-5 main__block'
      title={profilesListTexts.title}
    >
      <LoaderIndicator
        loading={state.loading}
        testid='spinner'
      />
      <div className="action__block d-flex justify-content-between flex-wrap mb-3 mb-md-4">
        <h1 className='h4 w-100'>{profilesListTexts.title}</h1>
      </div>
      <AlertMessage
        variant={alertVariant}
        message={alertMessage}
        show={alertShow}
        onClose={onClose}
      />
      <div className="list__block mt-3 mt-md-4">
        {
          !state.data?.length
            ? <h4 className='h5 text-muted text-center'>
              <Slash size={globalSizes.icon.xm} className='mr-2' />
              {uiTexts.global.noOptions}
            </h4>
            : <TableList headers={headers}>
              {
                state.data?.map(
                  (item: Record<string, string>) => {
                    return (
                      <tr
                        key={`inactive-profile-${item.id}`}
                        className={`table-list__item`}
                        onClick={onToggleModal(item)}
                      >
                        <td className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                          {item.id}
                        </td>
                        <td className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                          {item.lname}
                        </td>
                        <td className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                          {item.fname}
                        </td>
                        <td className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                          <div>{item.email}</div>
                          <div>{item.phoneNumber}</div>
                        </td>
                      </tr>
                    );
                  }
                )
              }
            </TableList>
        }
        <Modal
          show={activationState.data !== null}
          onHide={onToggleModal()}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className='mb-0 position-sticky bg-white sticky_top'>
              {activationState?.data?.fname} {activationState?.data?.lname}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={onActivateAccount}>
              <div className='mb-3'>
                <div>Email: {activationState?.data?.email}</div>
                <div>Phone: {activationState?.data?.phoneNumber}</div>
              </div>
              <div className='mb-3'>
                <div>Role: {activationState?.data?.owner_type}</div>
                <div>Nom de artist/label/organisation: {activationState?.data?.owner_name}</div>
              </div>
              <div className='mb-3'>
                <div>has_music: {activationState?.data?.has_music}</div>
                <div>Music in platforms: {activationState?.data?.music_in_platforms}</div>
                <div>number_of_releases_to_transfer: {activationState?.data?.number_of_releases_to_transfer}</div>
                <div>existing_platforms: {activationState?.data?.existing_platforms}</div>
              </div>
              <div className='mb-3'>
                <div>spotify_url: {activationState?.data?.spotify_url || ""}</div>
                <div>apple music url: {activationState?.data?.apple_music_url || ""}</div>
                <div>facebook url: {activationState?.data?.facebook_url || ""}</div>
                <div>instagram url: {activationState?.data?.instagram_url || ""}</div>
                <div>youtube url: {activationState?.data?.youtube_url || ""}</div>
                <div>tiktok url: {activationState?.data?.tiktok_url || ""}</div>
                <div>website_url: {activationState?.data?.website_url || ""}</div>
                <div>extra: {activationState?.data?.extra}</div>
                <div>social_media_urls: {activationState?.data?.social_media_urls}</div>
              </div>
              <div className="d-flex justify-content-end position-sticky bg-white sticky_bottom">
                <Button
                  variant='outline-primary'
                  className='border_radius_left-side_lg'
                  onClick={onToggleModal()}
                >
                  {uiTexts.global.close}
                </Button>
                <OverlayTrigger trigger="click" placement="top" overlay={popover}>
                  <Button
                    variant='outline-success'
                    className='border_radius_right-side_lg'
                    disabled={activationState.loading}
                    type='button'
                  >
                    {
                      activationState.loading
                        ? uiTexts.global.loading
                        : uiTexts.global.activate
                    }
                  </Button>
                </OverlayTrigger>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    </PageLayout>
  )
}
