import React, { useEffect, useReducer, } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import globalLinks from '../../config/links';
import Axios from 'axios';
import { globalReducer } from '../../store/reducer';
import { KeyValueObject } from '../../types/shared';
import NewReleaseDetails from '../NewReleaseDetails';

const getCoverDetails = (releaseData: KeyValueObject<any>) => {
  let coverDetails = releaseData?.coverDetails;
  if (releaseData?.coverDetails && typeof releaseData?.coverDetails === 'string') {
    coverDetails = JSON.parse(releaseData.coverDetails) || releaseData.coverDetails;
  }
  return coverDetails;
}
const initState: any = {
  loading: false,
  errors: {},
  global: null,
  data: {},
};
export default function EditRelease() {
  const source = Axios.CancelToken.source();
  let _isMounted = true;
  const history = useHistory();
  const params = useParams<KeyValueObject<any> | null>();
  const releaseId = params?.id ? parseInt(params?.id, 10) : null;
  if (!releaseId) {
    history.push(globalLinks.library.releases);
  }
  let { state: releaseData, } = useLocation<KeyValueObject<any> | null>();
  if (releaseData?.id) {
    initState.loading = false;
    releaseData = {
      ...releaseData,
      coverDetails: getCoverDetails(releaseData),
    }
    initState.data = releaseData;
  }
  const [state, dispatch] = useReducer(globalReducer, initState);

  useEffect(() => {
    const loadReleasData = async () => {
      try {
        const { data } = await Axios.get(`${globalLinks.api.libraries.release}/${releaseId}`);
        if (data?.code === 'success') {
          _isMounted && dispatch({
            type: 'SET_FIELDS',
            payload: {
              ...data.data,
              coverDetails: getCoverDetails(data.data),
            },
          })
        }
      } catch (error) {
        history.push(globalLinks.library.releases);
      }
    }
    !releaseData && loadReleasData();
    return () => {
      _isMounted = false;
      source.cancel('Cancel by user');
    }
  }, []);

  return (
    <NewReleaseDetails data={state.data} title={state.data?.title} loading={!state.data?.id} isEdit={true} />
  )
}
