import React, { useEffect, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import { Slash } from 'react-feather';
import uiTexts from '../../config/text';
import PageLayout from '../../libs/PageLayout';
import { globalReducer } from '../../store/reducer';
import globalLinks from '../../config/links';
import globalSizes from '../../config/sizes';
import { GlobalReducerActionType } from '../../types/shared';
import TableList from '../../components/TableList';

const { profilesList: profilesListTexts } = uiTexts.account;
const initState = {
  loading: true,
  errors: {},
  global: null,
  data: {
    accounts: [],
    accountsWithLabelSubscription: []
  },
};
const headers = ['id', 'Nom', 'Prenom', 'email'];

export default function ProfilesList() {
  let _isMounted = true;
  const axiosCancel = Axios.CancelToken.source();
  const [state, dispatch] = useReducer(globalReducer, initState);
  const history = useHistory();

  const onClose = (type: GlobalReducerActionType, payload: any) => () => {
    dispatch({
      type,
      payload,
    })
  }
  /**
   * navigate to release order(invoice)
   * @param libraryId library(release) id
   */
  const onNavigateToProfile = (userId: any) => async () => {
    history.push(`${globalLinks.account.profile}/${userId}`);
  }
  useEffect(() => {
    const loadProfilesListData = async () => {
      try {
        const { data } = await Axios.get(globalLinks.api.account.allProfiles, {
          cancelToken: axiosCancel.token,
        });
        if (data.code === 'success') {
          const { accountsWithLabelSubscription: labelAccounts, accounts } = data.data;
          const accountsWithLabelSubscription = labelAccounts.map((item: Record<string, number>) => item.userId);
          _isMounted && dispatch({
            type: 'SET_FIELDS',
            payload: {
              accounts,
              accountsWithLabelSubscription
            },
          });
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.code) {
          _isMounted && dispatch({
            type: 'ERROR',
            payload: error.response.data.errors,
          });
        } else {
          _isMounted && dispatch({
            type: 'ERROR',
            payload: { global: uiTexts.global.network },
          });
        }
      }
    }
    loadProfilesListData();
    return () => {
      _isMounted = false;
      axiosCancel.cancel('Operation canceled');
    }
  }, []);

  return (
    <PageLayout className='page__bloc pt-3 pt-md-5 pb-3 pb-md-5 main__block' title={profilesListTexts.title}>
      {
        state.loading && (
          <div className='page__spinner d-flex justify-content-center align-items-center' data-testid='spinner'>
            <Spinner className='main-text-color' animation="border" role="status">
              <span className="sr-only">{uiTexts.global.loading}.</span>
            </Spinner>
          </div>
        )
      }
      <div className="action__block d-flex justify-content-between flex-wrap mb-3 mb-md-4">
        <h1 className='h4 w-100'>{profilesListTexts.title}</h1>
        <blockquote className='blockquote'>
          <span className='badge badge-success table-list__item_theme_sucess px-3 py-2 fs-16'>
            Label
          </span>
          <span className='badge badge-danger table-list__item_theme_danger px-3 py-2 fs-16'>
            Bloque
          </span>
          <span className='badge badge-secondary table-list__item_theme_muted px-3 py-2 fs-16'>
            Desactive
          </span>
        </blockquote>
      </div>
      {
        !!state.errors.global &&
        <Alert className='w-100' variant='danger' onClose={onClose('ERROR', {})} dismissible transition>{state.errors.global}</Alert>
      }
      <div className="list__block mt-3 mt-md-4">
        {
          !state.data.accounts?.length
            ? <h4 className='h5 text-muted text-center'>
              <Slash size={globalSizes.icon.xm} className='mr-2' />
              {uiTexts.global.noOptions}
            </h4>
            : <TableList headers={headers}>
              {
                state.data?.accounts?.map(
                  (item: Record<string, string | number>) => {
                    const isSubscribedToLabelPlan = state.data.accountsWithLabelSubscription?.includes(item.id);
                    const labeldAccount = item.role === 'LABEL' || isSubscribedToLabelPlan
                      ? ' table-list__item_theme_sucess'
                      : '';
                    const disabledAccount = item.state === 'DISABLED' ? ' table-list__item_theme_muted' : '';
                    const holdedAccount = item.state === 'HOLD' ? ' table-list__item_theme_danger' : '';

                    return (
                      <tr key={item.id} className={`table-list__item${holdedAccount}${labeldAccount}${disabledAccount}`} onClick={onNavigateToProfile(item.id)}>
                        <td key={`${item.id}-id`} className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                          {item.id}
                        </td>
                        <td key={`${item.id}-lname`} className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                          {item.lname}
                        </td>
                        <td key={`${item.id}-fname`} className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                          {item.fname}
                        </td>
                        <td key={`${item.id}-email`} className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                          {item.email}
                        </td>
                      </tr>
                    );
                  }
                )
              }
            </TableList>
        }
      </div>
    </PageLayout>
  )
}
