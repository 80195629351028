import Joi, { Types } from "joi";
import { buildUsefulJoiErrorObject } from "./tools";
import reportMessages from "../config/messages/report";
import { KeyValueObject } from "../types/shared";
import uiTexts from "../config/text";

/**
 * @name ReportValidator
 * @author siemah
 * @description verification of report data(check if data are valid or not and then return a list of errors with the name of data)
 */
class ReportValidator {

  /**
   * get list of errors if there any
   * @param dataSchema Joi schema
   * @param data data to validate
   */
  private static getErrorsOfSchema(dataSchema: Joi.ObjectSchema<any>, data: any) {
    const { error: joiErrors } = dataSchema.validate(data);

    if (joiErrors !== undefined) {
      return buildUsefulJoiErrorObject(joiErrors.details);
    } else {
      return false;
    }
  }

  /**
   * generate a Joi schema
   * @param msg error message to return
   * @param type type of Joi
   * @param isRequired optional or required
   */
  private static generateJoiSchema(msg: string, type: Types, isRequired = true) {
    const required = isRequired ? 'required' : 'optional';
    return Joi[type]()[required]().label(msg);
  }

  /**
   * generate Joi string schema
   * @param msg error message
   * @param required required or optional
   * @param allow allowed value like empty string ''
   * @param min the number minimum of chars
   * @param max the number maximum of chars
   */
  private static generateJoiStringSchema(msg: string, required?: boolean, allow?: string | any[], min?: number, max?: number) {
    let schema = Joi.string();
    required = required !== undefined ? required : true;

    if (allow) {
      schema = schema.allow(allow);
    }
    if (min) {
      schema = schema.min(min)
    }
    if (max) {
      schema = schema.max(max);
    }

    schema = required ? schema.required() : schema.optional();
    schema = schema.label(msg);

    return schema;
  }

  /**
   * create report data validation
   * @param data contain data to validate
   */
  static verifyReportData(data: KeyValueObject<string | number | KeyValueObject<string>> | null) {
    const dataSchema = Joi.object().keys({
      numOfStreams: this.generateJoiSchema(reportMessages.numOfStreams.errorMsg, 'number', true),
      incomeOfStreams: this.generateJoiSchema(reportMessages.incomeOfStreams.errorMsg, 'number', true),
      numOfYoutube: this.generateJoiSchema(reportMessages.numOfYoutube.errorMsg, 'number', true),
      incomeOfYoutube: this.generateJoiSchema(reportMessages.incomeOfYoutube.errorMsg, 'number', true),
      numOfDownloads: this.generateJoiSchema(reportMessages.numOfDownloads.errorMsg, 'number', true),
      incomeOfDownloads: this.generateJoiSchema(reportMessages.incomeOfDownloads.errorMsg, 'number', true),
      trackId: this.generateJoiSchema(reportMessages.track.errorMsg, 'number', true),
      fromDate: this.generateJoiSchema(reportMessages.fromDate.errorMsg, 'date', true),
    })
      .options({ abortEarly: false, });
    return this.getErrorsOfSchema(dataSchema, data);
  }

  /**
  * youtube report data validation
  * @param data contain data to validate
  */
  static verifyYoutubeReportData(data: Record<string, any> | null) {
    const dataSchema = Joi.object().keys({
      incomeOfYoutube: this.generateJoiSchema(reportMessages.incomeOfYoutube.errorMsg, 'number', true),
      userId: this.generateJoiSchema(reportMessages.account.errorMsg, 'number', true),
      fromDate: this.generateJoiSchema(reportMessages.fromDate.errorMsg, 'date', true),
    })
      .options({ abortEarly: false, });
    return this.getErrorsOfSchema(dataSchema, data);
  }

  /**
   * Data validation of automatic report
   * @param data contain data to validate
   */
  static verifyAutomaticReportData(data: KeyValueObject<string | number | KeyValueObject<string>> | null) {
    const dataSchema = Joi.object().keys({
      fromDate: this.generateJoiSchema(reportMessages.fromDate.errorMsg, 'date', true),
    })
      .options({ abortEarly: false, });
    return this.getErrorsOfSchema(dataSchema, data);
  }

  /**
   * Data validation of csv reports
   * @param data contain data to validate
   */
  static verifyCSVReportData(data: KeyValueObject<string | number | KeyValueObject<string>> | null) {
    const dataSchema = Joi.object().keys({
      file: Joi.object().required().label(uiTexts.global.inValid),
      fromDate: this.generateJoiSchema(reportMessages.fromDate.errorMsg, 'number', true),
      toDate: this.generateJoiSchema(reportMessages.fromDate.errorMsg, 'number', true),
    })
      .options({ abortEarly: false, });
    return this.getErrorsOfSchema(dataSchema, data);
  }

  /**
   * Validate deletion of monthly reports data
   * @param data contain data to validate
   * @returns false if there isnt any error otherwise object of errors
   */
  static verifyMonthlyReportsData(data: Record<string, number>) {
    const dataSchema = Joi.object().keys({
      date: Joi.number().min(1577833200000).required().label(reportMessages.fromDate.errorMsg),
    })
      .options({ abortEarly: false, });
    return this.getErrorsOfSchema(dataSchema, data);
  }

}

export default ReportValidator;