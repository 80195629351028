import Joi, { Types } from "joi";
import { buildUsefulJoiErrorObject } from "../tools";

class AbstractValidator {

  /**
   * build a useful errors from errors generated via Joi
   * @param errors list of errors return via Joi validation
   */
  protected buildUsefulJoiErrorObject(errors: Joi.ValidationErrorItem[]): Record<any, string> {
    const usefulErrors: Record<string, string> = {};
    errors.map(({ path, context }) => {
      if (!usefulErrors.hasOwnProperty(path.join('_'))) {
        usefulErrors[path.join('_')] = context?.label || '';
      }
    });
    return usefulErrors;
  }

  /**
   * get list of errors if there any
   * @param dataSchema Joi schema
   * @param data data to validate
   */
  protected static getErrorsOfSchema(dataSchema: Joi.ObjectSchema<any>, data: any) {
    const { error: joiErrors } = dataSchema.validate(data);

    if (joiErrors !== undefined) {
      return buildUsefulJoiErrorObject(joiErrors.details);
    } else {
      return false;
    }
  }

  /**
   * generate a Joi schema
   * @param msg error message to return
   * @param type type of Joi
   * @param isRequired optional or required
   */
  protected static generateJoiSchema(msg: string, type: Types, isRequired = true) {
    const required = isRequired ? 'required' : 'optional';
    return Joi[type]()[required]().label(msg);
  }

}

export default AbstractValidator;