import Joi from "joi";
import authMessages from "../config/text";
import { buildUsefulJoiErrorObject } from "./tools";
import { KeyValueObject } from "../types/shared";

export interface SignupData {
  fname?: string;
  lname?: string;
  email?: string;
  password?: string;
  password_confirmation?: string;
}

export interface SigninData {
  email?: string;
  password?: string;
}

/**
 * Authentication data validation and verification include all
 * checking before resuming the rest of process like save to DB 
 */
class AuthValidator {

  /**
   * validate signin data
   * @param data object containing user credentials
   */
  static signInData(credentials: SigninData) {
    const dataSchema = Joi.object().keys({
      email: Joi.string().email({ tlds: { allow: false } }).required().label(authMessages.signup.messages.errors.email),
      password: Joi.string().min(6).required().label(authMessages.signup.messages.errors.password),
    })
      .options({ abortEarly: false, });

    const { error: joiErrors } = dataSchema.validate(credentials);
    if (joiErrors !== undefined) {
      return buildUsefulJoiErrorObject(joiErrors.details);
    } else {
      return false;
    }
  }

  /**
   * signup data validation
   * @param data necessary user details to signup
   */
  static signUpData(data: SignupData | null) {
    const dataSchema = Joi.object().keys({
      fname: Joi.string().min(3).max(40).required().label(authMessages.signup.messages.errors.fname),
      lname: Joi.string().min(3).max(40).required().label(authMessages.signup.messages.errors.lname),
      email: Joi.string().email({ tlds: { allow: false } }).required().label(authMessages.signup.messages.errors.email),
      password: Joi.string().min(6).required().label(authMessages.signup.messages.errors.password),
      password_confirmation: Joi.string().min(6).required().valid(Joi.ref('password')).label(authMessages.signup.messages.errors.password_confirmation),
    })
      .options({ abortEarly: false, });
    const { error: joiErrors } = dataSchema.validate(data);

    if (joiErrors !== undefined) {
      return buildUsefulJoiErrorObject(joiErrors.details);
    } else {
      return false;
    }
  }

  /**
   * reset password data validation(just email)
   * @param data contain email to verify
   */
  static resetPassword(data: any): boolean | KeyValueObject<any> {
    const dataSchema = Joi.object().keys({
      email: Joi.string().email({ tlds: { allow: false } }).required().label(authMessages.signup.messages.errors.email),
    });
    const { error: joiErrors } = dataSchema.validate(data);
    if (joiErrors !== undefined) {
      return buildUsefulJoiErrorObject(joiErrors.details);
    } else {
      return false;
    }
  }

  /**
   * reset password action data validation(password and confirmation)
   * @param data contain email to verify
   */
  static resetPasswordAction(data: any): boolean | KeyValueObject<any> {
    const dataSchema = Joi.object().keys({
      password: Joi.string().min(6).required().label(authMessages.signup.messages.errors.password),
      password_confirmation: Joi.string().min(6).required().valid(Joi.ref('password')).label(authMessages.signup.messages.errors.password_confirmation),
      token: Joi.string().required().label(authMessages.signup.messages.errors.unauthorized)
    });
    const { error: joiErrors } = dataSchema.validate(data);
    if (joiErrors !== undefined) {
      return buildUsefulJoiErrorObject(joiErrors.details);
    } else {
      return false;
    }
  }

}

export default AuthValidator;