import React, { useEffect, useReducer, } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Axios from 'axios';
import ListGroup from 'react-bootstrap/ListGroup';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import { Slash } from 'react-feather'
import PageLayout from '../../libs/PageLayout';
import uiTexts from '../../config/text';
import globalLinks from '../../config/links';
import globalSizes from '../../config/sizes';
import { globalReducer } from '../../store/reducer';
import { GlobalReducerActionType, KeyValueObject } from '../../types/shared'
import Button from 'react-bootstrap/Button';

const initState = {
  loading: true,
  errors: {},
  global: null,
  data: [],
};

export default function ProfileBillingAccounts() {
  const axiosCancel = Axios.CancelToken.source();
  let _isMounted = true;
  const params = useParams<KeyValueObject<any>>();
  const [state, dispatch] = useReducer(globalReducer, initState);
  const history = useHistory();
  const newBankAccountPath = `${globalLinks.account.billing.newBankAccount}/`.replace(":id", params.id);
  /**
   * close the alert message
   */
  const onClose = (type: GlobalReducerActionType, payload: any) => () => {
    dispatch({
      type,
      payload,
    })
  }
  /**
   * navigate to bank/paypal account for full see/edit it details
   * @param account user account details
   */
  const onNavigateTo = (account: any) => () => {
    if (account) {
      const historyOptions = getNavigationConfig(account);
      history.push(historyOptions.pathname, historyOptions.state);
    }
  }

  /**
   * get configuration of history.push
   * @param account user account details
   */
  const getNavigationConfig = (account: KeyValueObject<any>) => {
    const pathname = account.type === 'bank'
      ? `${globalLinks.account.billing.main}/bank-account/${account.id}`
      : `${globalLinks.account.billing.main}/paypal-account/${account.id}`;

    return {
      pathname,
      state: account
    }
  }

  useEffect(() => {
    const loadBillingAccountsData = async () => {
      try {
        const { data } = await Axios.get(`${globalLinks.api.account.profile}/${params.id}/billing-accounts`, {
          cancelToken: axiosCancel.token,
        });
        if (data.code === 'success') {
          _isMounted && dispatch({
            type: 'SET_LIST',
            payload: data.data,
          });
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.code) {
          _isMounted && dispatch({
            type: 'ERROR',
            payload: error.response.data.errors,
          });
        } else {
          _isMounted && dispatch({
            type: 'ERROR',
            payload: { global: uiTexts.global.network },
          });
        }
      }
    }
    loadBillingAccountsData();
    return () => {
      _isMounted = false;
      axiosCancel.cancel('Operation canceled');
    }
  }, []);

  return (
    <PageLayout className='page__bloc pt-3 pt-md-5 pb-3 pb-md-5 main__block' title={uiTexts.account.billing.main.title}>
      {
        state.loading && (
          <div className='page__spinner d-flex justify-content-center align-items-center' data-testid='spinner'>
            <Spinner className='main-text-color' animation="border" role="status">
              <span className="sr-only">{uiTexts.global.loading}.</span>
            </Spinner>
          </div>
        )
      }
      <div className="action__block d-flex justify-content-between flex-wrap mb-3 mb-md-4">
        <h1 className='h4'>{uiTexts.account.billing.main.title}</h1>
        <Link to={newBankAccountPath}>
          <Button variant='primary'>
            {uiTexts.account.billing.main.add}
          </Button>
        </Link>
      </div>
      {
        !!state.errors.global &&
        <Alert className='w-100' variant='danger' onClose={onClose('ERROR', {})} dismissible transition>{state.errors.global}</Alert>
      }
      {
        !!state.global &&
        <Alert className='w-100' variant='success' onClose={onClose('SUCCESS', null)} dismissible transition>{state.global}</Alert>
      }
      <div className="list__block mt-3 mt-md-4">
        {
          !state.data.length
            ? <h4 className='h5 text-muted text-center'>
              <Slash size={globalSizes.icon.xm} className='mr-2' />
              {uiTexts.global.empty}
            </h4>
            : state.data.map((account: any) => (
              <ListGroup.Item role='listitem' key={account.id} onClick={onNavigateTo(account)} action className='d-flex justify-content-between align-items-center flex-wrap border_color_light-blue'>
                <div className="account-detail__block">
                  <div>{account.email || account.owner}</div>
                  <span>{account.holderAddress}</span>
                </div>
                <div className="account-date__block">
                  14 sep 2020 12:12
                </div>
              </ListGroup.Item>
            ))
        }
      </div>

    </PageLayout>
  )
}
