import React from 'react';
import PrivateRoute from '../../libs/PrivateRoute';
import Container from 'react-bootstrap/Container';
import HeaderMenu from '../../components/HeaderMenu';
import SideBarMenu from '../../components/SideBarMenu';
import Home from '../../pages/Home';
import LibraryRoutes from '../../components/LibraryRoutes';
import AccountingRoutes from '../../components/AccountingRoutes';
import ReportsRoutes from '../../components/ReportsRoutes';
import ServicesRoutes from '../../routes/services';
import ProfileRoutes from '../ProfileRoutes';
import AccountingStatsRoutes from '../../routes/accounting-stats';
import ContractsRoutes from '../../routes/contracts';
import ToolsRoutes from '../../routes/tools';

interface DashboardProps {
  auth: boolean;
}
export default function Dashboard({ auth }: DashboardProps) {
  return (
    <div className='page__bloc'>
      <HeaderMenu />
      <Container fluid>
        <div className="row">
          <SideBarMenu />
          <PrivateRoute
            exact
            path="/"
            auth={auth}
          >
            <Home />
          </PrivateRoute>
          <ProfileRoutes auth={auth} />
          <LibraryRoutes auth={auth} />
          <AccountingRoutes auth={auth} />
          <ReportsRoutes auth={auth} />
          <ServicesRoutes auth={auth} />
          <AccountingStatsRoutes auth={auth} />
          <ContractsRoutes auth={auth} />
          <ToolsRoutes auth={auth} />
        </div>
      </Container>
    </div>
  )
}
