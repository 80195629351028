import React, { useState } from 'react';
import { BrowserRouter, Switch, } from 'react-router-dom';
import ReactGA from 'react-ga';

import { useAuth } from './hooks/auth';
import { AuthProvider } from './context/auth';
import { UIProvider } from './context/ui';
import Dashboard from './components/Dashboard';
import GuestRoute from './libs/GuestRoute';
import globalLinks from './config/links';
import Signin from './pages/Signin';
import ResetPassword from './pages/ResetPassword';
import ResetPasswordAction from './pages/ResetPasswordAction';
import Splash from './libs/Splash';
import { UIContextProps, UIContextState } from './types/shared';
import globalSizes from './config/sizes';

ReactGA.initialize(globalSizes.googleAnalytics);
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  const [ui, setUi] = useState<UIContextState>({
    menuState: false,
  });
  const [auth, setAuth] = useAuth();
  const authProviderValue = {
    isLoggedin: auth.isLoggedin,
    csrf: auth.csrf,
    cash: auth.cash,
    invoices: auth.invoices,
    location: auth.location,
    currency: auth.currency,
    reports: auth.reports,
    userCapabilities: auth.userCapabilities,
    signOut: () => setAuth({ ...auth, isLoggedin: false }),
    signIn: () => setAuth({ ...auth, isLoggedin: true }),
  };
  const uiProviderValue: UIContextProps = {
    menuState: ui.menuState,
    setMenuState(newState: boolean) {
      setUi({ menuState: newState, })
    },
  }

  if (auth.loading) {
    return <Splash />
  }
  return (
    <AuthProvider value={authProviderValue}>
      <UIProvider value={uiProviderValue}>
        <BrowserRouter>
          <Switch>
            <GuestRoute auth={auth.isLoggedin} path={globalLinks.signin}>
              <Signin />
            </GuestRoute>
            <GuestRoute auth={auth.isLoggedin} path={globalLinks.resetPasswordAction}>
              <ResetPasswordAction />
            </GuestRoute>
            <GuestRoute auth={auth.isLoggedin} path={globalLinks.resetPasswordRequest}>
              <ResetPassword />
            </GuestRoute>
            <Dashboard auth={auth.isLoggedin} />
          </Switch>
        </BrowserRouter>
      </UIProvider>
    </AuthProvider>
  );
}

export default App;
