import React from 'react';
import Form from 'react-bootstrap/Form';
import parse from 'date-fns/parse';
import fr from 'date-fns/locale/fr';
import Col from 'react-bootstrap/Col';
import DatePicker, { registerLocale } from 'react-datepicker';

import uiTexts from '../../config/text';
import GradientButton from '../GradientButton';
import reportMessages from '../../config/messages/report';
import FormField from '../FormField';
import globalSizes from '../../config/sizes';
import ReportFormPropsTypes from '../ReportForm/types';

import "react-datepicker/dist/react-datepicker.css";

registerLocale('fr', fr);

type AutomaticReportFormPropsTypes = Omit<ReportFormPropsTypes, "onChangeSelect" | "optionalValues" | "onChange">;
export default function AutomaticReportForm({ onSubmit, onSelectDate, errors, loading, disabled, data }: AutomaticReportFormPropsTypes) {
  // custom input to use with <DatePicker />
  const CustomDateInput = React.forwardRef(({ value, onClick, error, name, label }: any, ref: any) => {
    return (
      <FormField
        autoComplete='off'
        defaultValue={value}
        error={error || null}
        label={label}
        name={name}
        onClick={onClick}
        readonly={false} />
    );
  });
  const fromDate = data?.fromDate
    ? new Date(parse(data?.fromDate, globalSizes.dates.en.format, new Date()))
    : undefined;

  return (
    <Form className='form__block mw-100 mt-4' onSubmit={onSubmit}>
      <Form.Row>
        <Col xs={12} md={6} className='mb-2'>
          <DatePicker
            locale='fr'
            selected={fromDate}
            onChange={onSelectDate('fromDate')}
            customInput={
              <CustomDateInput
                name='fromDate'
                label={reportMessages.fromDate.label}
                error={errors?.fromDate}
              />
            }
            monthClassName={() => 'calendar-month__date'}
            wrapperClassName='w-100'
            placeholderText={reportMessages.fromDate.label}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            showFullMonthYearPicker
          />
        </Col>
        <Col xs={12} md={6} className='=mb-2'>
          <GradientButton
            disabled={disabled}
            type='submit'
            size='lg'
            className='text-white'
            block
          >
            {
              loading
                ? uiTexts.global.loading
                : uiTexts.global.save
            }
          </GradientButton>
        </Col>
      </Form.Row>
    </Form>
  )
}
