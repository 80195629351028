import React, { useContext, useEffect, useReducer, useState, } from 'react';
import { useHistory, useLocation, useParams, Link } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Toast from 'react-bootstrap/Toast';
import Button from 'react-bootstrap/Button';
import { Download, Edit, Trash2, } from 'react-feather';
import Axios, { AxiosRequestConfig } from 'axios';
import { ActionMeta, ValueType } from 'react-select';
import { OnDragEndResponder } from "react-beautiful-dnd";

import PageLayout from '../../libs/PageLayout';
import uiTexts from '../../config/text';
import globalLinks from '../../config/links';
import { globalReducer } from '../../store/reducer';
import { GlobalReducerActionType } from '../../types/shared'
import { KeyValueObject } from '../../types/shared';
import { artistRoles, } from '../../config/data/roles';
import TracksList from '../../components/TracksList';
import ReleaseHero from '../../components/ReleaseHero';
import globalSizes from '../../config/sizes';
import AuthContext from '../../context/auth';
import libraryMessages from '../../config/messages/library';
import TracksReleaseForm from '../../components/TracksReleaseForm';
import LibraryValidator from '../../utils/LibraryValidator';
import { buildSelectOptions, retrievePropsThatMatter } from '../../utils/tools';
import { createArtistsLabel } from '../../utils/data';
import { formatDateTo } from '../../utils/dates';
import LoaderIndicator from '../../components/LoaderIndicator';
import AlertMessage from '../../components/AlertMessage';
import { uploadFile, uploadFileInChunks } from '../../utils/files/upload';
import ProgressIndicator from '../../components/ProgressIndicator';

import './style.css';
import useAbortController from '../../hooks/use-abort-controller';

const initState: any = {
  loading: true,
  errors: {},
  global: null,
  data: null,
};
const releasesText = uiTexts.libraries;

export default function Release() {
  let { state: releaseData, } = useLocation<KeyValueObject<any> | null>();
  const params = useParams<any>();
  const history = useHistory();
  const [audioFile, setAudioFile] = useState<File>(null!);
  const { csrf } = useContext(AuthContext);
  const [initialData, setInitialData] = useState<KeyValueObject<any[]>>({
    artists: [],
    editors: [],
    contributors: [],
    musicians: [],
  });
  let axiosOptions: AxiosRequestConfig = {
    headers: {
      'X-Csrf-Token': csrf,
    },
  };
  const [selectedTrackState, dispatchTrack] = useReducer(globalReducer, { ...initState, loading: false });
  const [showModal, setShowModal] = useState<boolean>(false);
  const axiosCancel = Axios.CancelToken.source();
  let _isMounted = true;
  const pendingReleasesUrl = globalLinks.library.releases.replace(':status', 'unpaid');
  let timeoutId: any;
  if (releaseData?.id) {
    if (releaseData?.coverDetails && typeof releaseData?.coverDetails === 'string') {
      releaseData = {
        ...releaseData,
        coverDetails: JSON.parse(releaseData.coverDetails) || releaseData.coverDetails
      }
    }
    initState.data = releaseData;
  }
  const [state, dispatch] = useReducer(globalReducer, initState);
  const [showTaost, setShowTaost] = useState(false);
  const [progression, setProgression] = useState(0);
  const { newAbortSignal, cancelPreviousRequest } = useAbortController();
  // on close alert by reseting state of messages
  const onClose = (type: GlobalReducerActionType, payload: any) => () => {
    dispatch({
      type,
      payload,
    })
  }
  const showAlert = !!state.global || !!state.errors.global;
  const alertVariant = !!state.global ? "success" : "danger";
  const onCloseAlert = onClose(!!state.global ? 'SUCCESS' : 'ERROR', !!state.global ? null : {});
  const alertMessage = state.global || state.errors.global;
  /**
   * toggle taost
   */
  const onToggleTaostForRemove = () => {
    setShowTaost(!showTaost);
  }
  // delete release
  const onRemoveRelease = async () => {
    _isMounted && dispatch({
      type: 'LOADING',
      payload: true,
    });
    try {
      const { data } = await Axios.delete(
        `${globalLinks.api.libraries.release}/${state.data.id}/delete`,
        axiosOptions
      );

      if (data.code === 'success') {
        _isMounted && dispatch({
          type: 'SUCCESS',
          payload: data.message,
        });
        window.scrollTo(0, 0);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.code) {
        _isMounted && dispatch({
          type: 'ERROR',
          payload: error.response.data.errors,
        });
      } else {
        _isMounted && dispatch({
          type: 'ERROR',
          payload: { global: uiTexts.global.network },
        });
      }
    }
  }
  /**
   * navigate to release edit page
   */
  const onEditReleaseDetails = () => {
    history.push(
      `${globalLinks.library.release}/${state?.data?.id}/edit`,
      state.data
    );
  }
  // open/close modal
  const onToggleEditTrackModal = (trackId: string | number) => () => {
    if (trackId) {
      let middleItem = state.data?.tracks?.find(({ id }: any) => id === trackId);
      _isMounted && dispatchTrack({
        type: 'SET_FIELDS',
        payload: {
          ...middleItem,
          artists: retrievePropsThatMatter(middleItem?.Artists, 'TrackArtist'),
          contributors: retrievePropsThatMatter(middleItem?.Contributors, 'TrackContributor'),
          editors: retrievePropsThatMatter(middleItem?.Editors),
          musicians: retrievePropsThatMatter(middleItem?.Musicians, 'TrackMusician'),
        }
      });
    }
    _isMounted && setShowModal(!showModal);
  };
  const onRemoveTrackById = async (trackId: string | number) => {
    _isMounted && dispatch({
      type: "LOADING",
      payload: true
    });
    try {
      const { data } = await Axios.delete(
        `${globalLinks.api.libraries.tracks.deleteFromDisk}/${trackId}`,
        // null,
        { ...axiosOptions, withCredentials: true }
      );

      if (data.code === 'success') {
        const tracks = state.data?.tracks?.filter((track: Record<"id", number>) => track.id !== trackId);
        _isMounted && dispatch({
          type: 'SET_LIST_WITH_MESSAGE',
          payload: {
            message: data.message,
            data: {
              ...state.data,
              tracks,
            }
          },
        });
        window.scrollTo(0, 0);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.code) {
        _isMounted && dispatch({
          type: 'ERROR',
          payload: error.response.data.errors,
        });
      } else {
        _isMounted && dispatch({
          type: 'ERROR',
          payload: { global: uiTexts.global.network },
        });
      }
    }
  }
  // save track new input changes
  const onChange = (evnt: React.ChangeEvent<HTMLInputElement>) => {
    const { name: key, value } = evnt.target;
    _isMounted && dispatchTrack({
      type: 'SET_FIELD',
      payload: {
        key,
        value
      }
    });
  }
  const onChangeNoVoice = (evnt: React.ChangeEvent<HTMLInputElement>) => {
    const { name: key, checked: value } = evnt.target;

    dispatchTrack({
      type: 'SET_FIELD',
      payload: {
        key,
        value,
      }
    });
  }
  /**
   * change the value
   * @param value contain value of <Select />
   * @param action 
   * @see https://react-select.com/
   */
  const onChangeSelect = (value: ValueType<any>, action: ActionMeta<any>) => {
    dispatchTrack({
      type: 'SET_FIELD',
      payload: {
        key: action.name,
        value: value.value,
      }
    })
  }
  /**
   * create an items on global state like artists, editors & ..
   * @param name the value of input attribute
   * @param defaultValue default value
   */
  const onCreateFormCollectionItem = (name: string, defaultValue = '') => {
    const currentValue = selectedTrackState.data[name] || defaultValue;
    return () => {
      _isMounted && dispatchTrack({
        type: 'SET_FIELD',
        payload: {
          key: name,
          value: [
            ...currentValue,
            {
              fullname: '',
              role: defaultValue || artistRoles[0].value,
            }
          ],
        }
      })
    }
  }
  /**
   * fired when a <CreatableSelect /> changed its value
   * @param value contain value of <Select />
   * @param action 
   * @see https://react-select.com/
   */
  const onChangeCollectionItem = (fieldname: string, key: string, index: number) => {
    return (value: ValueType<any>, action: ActionMeta<any>) => {
      const oldData = selectedTrackState.data[fieldname];
      const currentValue = oldData[index];
      let newData: any = oldData;
      let newValue: any;
      // new value
      if (value?.__isNew__ || action.name !== 'fullname') {
        newValue = {
          ...currentValue,
          [key]: value.value,
        };
        // remove id in case user select an existing artist/editor/...
        action.name === 'fullname' && delete newValue?.id;
      } else {
        newValue = {
          ...currentValue,
          id: value.value,
          [key]: value.label,
        }
      }
      // add new prop "isMusician" for musicians list
      newData = [
        ...newData.slice(0, index),
        newValue,
        ...newData.slice(index + 1)
      ];

      _isMounted && dispatchTrack({
        type: 'SET_FIELD',
        payload: {
          key: fieldname,
          value: newData
        }
      })
    }
  }
  // remove collection item like artist,editor, ..
  const onRemoveCollectionItem = (key: string, index: number) => {
    return () => {
      let oldData = selectedTrackState.data[key];
      const value = oldData.filter((a: any, i: number) => i !== index);

      _isMounted && dispatchTrack({
        type: 'SET_FIELD',
        payload: {
          key,
          value,
        }
      })
    }
  }
  // update(main state) audio details of a given track
  const updateTrackAudio = (newData: Record<string, number>, trackId: number) => {
    const tracks = state.data?.tracks || [];
    const targetTrackIndex = tracks?.findIndex((track: Record<string, number>) => track.id === trackId);
    tracks[targetTrackIndex] = {
      ...tracks[targetTrackIndex],
      ...newData
    };
    _isMounted && dispatch({
      type: "SET_FIELDS",
      payload: tracks
    });
  }
  // edit track data by sending PUT request
  const onEditTrack = async (newData: any) => {
    _isMounted && dispatchTrack({
      type: "LOADING",
      payload: true
    });

    try {
      const { data } = await Axios.put(
        `${globalLinks.api.libraries.tracks.edit}/${selectedTrackState.data.id}/edit`,
        newData,
        axiosOptions
      );

      if (audioFile !== null) {
        axiosOptions.withCredentials = true;
        const audioRes = await uploadFileInChunks(
          audioFile,
          `${globalSizes.resourcesMainDomain}${globalLinks.api.libraries.tracks.edit}/${selectedTrackState.data.id}/edit/audio`,
          axiosOptions,
          "put",
          (uploadProgress: Record<string, any>) => {
            const progressNow = Math.round(uploadProgress.loaded * 100 / uploadProgress.total);
            setProgression(progressNow);
          }
        );
        newData.audioDetails = JSON.stringify(audioRes?.data || {});
      }

      if (data.code === 'success') {
        updateTrackAudio(newData, selectedTrackState.data.id);
        _isMounted && dispatchTrack({
          type: 'SUCCESS',
          payload: data.message,
        });
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.code) {
        _isMounted && dispatchTrack({
          type: 'ERROR',
          payload: error.response.data.errors,
        });
      } else {
        _isMounted && dispatchTrack({
          type: 'ERROR',
          payload: { global: uiTexts.global.network },
        });
      }
    }
    scrollToTheTopOfModal();
  }
  /**
   * edit track by sending new Track details to server
   * @param evnt domevent
   */
  const onSubmitEditTrack = async (evnt: React.FormEvent<HTMLFormElement>) => {
    evnt.preventDefault();
    _isMounted && dispatchTrack({
      type: 'LOADING',
      payload: true,
    });
    const { genre, title, recordingOwnerName, yearOfRegistration, libraryId, editors, artists, contributors, musicians, subgenre, language, version, isrc, noVoice, starTime } = selectedTrackState.data;
    let newDataOfTrack = { genre, title, recordingOwnerName, yearOfRegistration, libraryId, editors, artists, contributors, musicians, subgenre, language, version, isrc, noVoice, starTime };
    let errors = LibraryValidator.verifyTrackData(newDataOfTrack, false);

    if (!!selectedTrackState.errors?.audio) {
      errors = {
        ...(errors || {}),
        audio: selectedTrackState.errors?.audio
      };
    }

    if (errors) {
      _isMounted && dispatchTrack({
        type: 'ERROR',
        payload: errors,
      });
    } else {
      onEditTrack(newDataOfTrack);
    }
  }
  // display a global message using Alert
  const trackEditAlertSetup = {
    className: !!selectedTrackState.errors.global ? 'danger' : 'success',
    textContent: selectedTrackState.errors.global || selectedTrackState.global,
  };
  /**
   * scroll to the top of modal to see success/error message/s
   * Modal component dont support ref
   */
  const scrollToTheTopOfModal = () => {
    const $modal = document.querySelector('.modal');
    $modal?.scrollTo(0, 0);
  }
  // artists label names like art1 Feat art2 vs art3...
  const artistLabelNames = createArtistsLabel(state.data);
  const onGenerateLicenseContract = async () => {
    dispatch({
      type: "LOADING",
      payload: true
    });
    let {
      title,
      Account: { fname, lname, address = '', country = '', city = '', postcode = '' },
      createdAt,
    } = state.data;
    createdAt = formatDateTo(createdAt, globalSizes.dates.fr.day);
    const data = {
      title: title,
      fullname: `${fname} ${lname}`,
      createdAt,
      address,
      city,
      postcode,
      country,
      artistsLabel: artistLabelNames,
    }
    let generateLicenseContract: any | null = null;

    try {
      if (generateLicenseContract === null) {
        import('../../utils/pdf/contract')
          .then(async ({ generateLicenseContract: fn }) => {
            generateLicenseContract = fn;
            await generateLicenseContract(data);
            dispatch({
              type: "LOADING",
              payload: false
            });
          }).catch((error) => {
            throw error
          });
      } else {
        await generateLicenseContract(data);
        dispatch({
          type: "LOADING",
          payload: false
        });
      }
    } catch (error) {
      dispatch({
        type: "ERROR",
        payload: {
          global: uiTexts.global.retry
        }
      });
    }
  }
  /**
   * Save artist details such as fullname, uri ids
   * @param index index of the artist in artist list
   * @param newArtist artist data
   * @returns true if created otherwise false
   */
  const onCreateNewArtist = async (index: number, artistId: string, newArtist: Record<string, string>) => {
    try {
      const errors = LibraryValidator.verifyNewArtistData(newArtist);

      if (errors !== false) {
        dispatchTrack({
          type: 'ERROR',
          payload: errors
        });
        return false;
      }

      const endpoint: any = `${globalLinks.api.libraries.artists.edit}/${artistId}/edit`;
      const response = await Axios.put(
        endpoint,
        newArtist,
        axiosOptions
      );
      // update list of artists
      if (response.data.code === "success") {
        const track = state.data.tracks.find((track: any) => track.id === selectedTrackState.data.id);
        const artist = track.Artists[index];
        artist.spotifyId = newArtist.spotifyId;
        artist.appleMusicId = newArtist.appleMusicId;
        const tracks = state.data.tracks;
        const artists = track.Artists;
        artists[index] = artist;
        tracks[index] = { ...tracks[index], Artists: artists };
        _isMounted && dispatch({
          type: "SET_FIELD",
          payload: {
            ...state.data,
            tracks
          }
        });

        return true;
      }
      throw response;
    } catch (error) {
      // @ts-ignore
      if (error.response && error.response.data && error.response.data.code) {
        _isMounted && dispatch({
          type: 'ERROR',
          // @ts-ignore
          payload: error.response.data.errors,
        });
      } else {
        _isMounted && dispatch({
          type: 'ERROR',
          payload: { global: uiTexts.global.network },
        });
      }
      return false;
    }
  }
  // replace current cover
  const onReplaceCover = async (newCover: File) => {
    _isMounted && dispatch({
      type: "LOADING",
      payload: true
    });
    const url = `${globalSizes.resourcesMainDomain}${globalLinks.api.libraries.release}/${state.data.id}/edit/cover`;
    axiosOptions.withCredentials = true;
    const response = await uploadFile(newCover, url, axiosOptions, "put");

    if (response?.code === "success") {
      _isMounted && dispatch({
        type: "SET_FIELD_WITH_MESSAGE",
        payload: {
          message: response.message,
          key: "coverDetails",
          value: response.data.coverDetails
        }
      });
    } else {
      _isMounted && dispatch({
        type: "ERROR",
        payload: response.errors
      });
    }
  }
  /**
   * select release cover image
   */
  const onSelectTrackAudio = (evnt: React.ChangeEvent<HTMLInputElement>) => {
    const trackAudio = evnt.target?.files;

    if (!trackAudio?.length) return;
    else if (!globalSizes.audio.format.includes(trackAudio[0].type)) {
      _isMounted && dispatchTrack({
        type: 'ERROR',
        payload: {
          audio: libraryMessages.audioPath.errorMsg
        },
      });
      _isMounted && setAudioFile(trackAudio[0]);
    } else {
      _isMounted && dispatchTrack({
        type: 'ERROR',
        payload: {
          audio: undefined
        },
      });
      _isMounted && setAudioFile(trackAudio[0]);
    }
  }
  const trackReleaseFormData = {
    audio: audioFile?.name,
    artists: initialData.artists,
    editors: initialData.editors,
    contributors: initialData.contributors,
    musicians: initialData.musicians,
  };
  // a little function to help us with reordering the result
  const reorder = (list: Record<string, string>[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const onDragEnd: OnDragEndResponder = async (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const sortedTracks = reorder(
      state?.data?.tracks,
      result.source.index,
      result.destination.index
    );

    dispatch({
      type: "SET_FIELD",
      payload: {
        key: "tracks",
        value: sortedTracks
      }
    });

    // update position of each track
    try {
      const unsortedTrackIds = state?.data?.tracks?.map((track: Record<string, string>) => track.id);
      const sortedTrackIds = sortedTracks.map(track => track.id);

      if (JSON.stringify(unsortedTrackIds) !== JSON.stringify(sortedTrackIds)) {
        cancelPreviousRequest();
        await Axios.put(
          `${globalLinks.api.libraries.release}/${params.id}/edit-tracks-position`,
          sortedTrackIds,
          {
            ...axiosOptions,
            signal: newAbortSignal(),
          }
        );
      }
    } catch (error) {
    }
  }

  useEffect(() => {
    const loadReleaseData = async () => {
      try {
        const requestConfig = {
          cancelToken: axiosCancel.token,
        };
        const { data } = await Axios.get(`${globalLinks.api.libraries.resources}/${params.id}`, requestConfig);
        if (data?.data?.release?.coverDetails) {
          data.data.release = {
            ...data?.data?.release,
            coverDetails: JSON.parse(data?.data?.release.coverDetails)
          }
        }
        if (!data?.data?.release) {
          return history.replace(pendingReleasesUrl);
        }
        else if (data.code === 'success') {
          let { orderItems = "{}", transactionDetails = "{}" } = data?.data?.release?.Order || {};
          orderItems = JSON.parse(orderItems);
          transactionDetails = JSON.parse(transactionDetails);
          const releaseData = {
            ...data?.data?.release,
            Order: {
              ...data?.data?.release?.Order,
              orderItems,
              transactionDetails,
            }
          };

          _isMounted && dispatch({
            type: 'SET_FIELDS',
            payload: releaseData
          });
          _isMounted && setInitialData({
            artists: buildSelectOptions(data?.data?.artists || []),
            editors: buildSelectOptions(data?.data?.editors || []),
            contributors: buildSelectOptions(data?.data?.contributors || []),
            musicians: buildSelectOptions(data?.data?.musicians || []),
          });
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.code) {
          _isMounted && dispatch({
            type: 'ERROR',
            payload: error.response.data.errors,
          });
        } else {
          _isMounted && dispatch({
            type: 'ERROR',
            payload: { global: uiTexts.global.network },
          });
        }
      }
    }
    loadReleaseData();
    return () => {
      _isMounted = false;
      axiosCancel.cancel('Operation canceled');
      clearTimeout(timeoutId);
    }
  }, []);

  return (
    <PageLayout className='page__bloc pt-3 pt-md-5 pb-3 pb-md-5 main__block' title={state?.data?.title}>
      <LoaderIndicator
        loading={state.loading || selectedTrackState.loading}
        className='position-fixed z_index_max'
        testid='spinner'
      />
      <ProgressIndicator
        loading={selectedTrackState.loading}
        progression={progression}
        label={`${progression}%`}
        className='progress-container-position position-fixed z_index_max'
      />
      <Row className="action__block mb-3 mb-md-4 d-flex">
        <Col xs={12}>
          <h1 className='h2 text-capitalize'>{state?.data?.title}</h1>
        </Col>
      </Row>
      <Row className="action__block mb-3 mb-md-4 d-flex">
        <Col xs={12}>
          <div className="float-right">
            <ButtonGroup className="mb-2">
              <Button variant='primary' type='button' onClick={onEditReleaseDetails}>
                <Edit className='mr-2' size={globalSizes.icon.default} />
                <span>{uiTexts.global.edit}</span>
              </Button>
              <Button variant='outline-success' type='button' onClick={onGenerateLicenseContract}>
                <Download className='mr-2' size={globalSizes.icon.default} />
                <span>{uiTexts.libraries.contract}</span>
              </Button>
              <Button variant='outline-danger' onClick={onToggleTaostForRemove}>
                <Trash2 className='mr-2' size={globalSizes.icon.default} />
                <span>{uiTexts.global.remove}</span>
              </Button>
            </ButtonGroup>
            <Toast show={showTaost} onClose={onToggleTaostForRemove}>
              <Toast.Header>
                <Trash2 className='mr-2' size={globalSizes.icon.default} />
                <strong className="mr-auto">{uiTexts.global.remove}</strong>
                <small>
                  status:{` `}
                  <span className={`custom__badge badge badge-${globalSizes.statusColor[state?.data?.status]}`}>{state?.data?.status}</span>
                </small>
              </Toast.Header>
              <Toast.Body>
                <h4 className='h6 mb-3'>
                  {uiTexts.global.notes.delete}
                </h4>
                <ButtonGroup>
                  <Button variant='outline-primary' type='button' onClick={onToggleTaostForRemove}>
                    <Edit className='mr-2' size={globalSizes.icon.default} />
                    <span>{uiTexts.global.cancel}</span>
                  </Button>
                  <Button variant='outline-danger' onClick={onRemoveRelease}>
                    <Trash2 className='mr-2' size={globalSizes.icon.default} />
                    <span>{uiTexts.global.sure}</span>
                  </Button>
                </ButtonGroup>
              </Toast.Body>
            </Toast>
          </div>
        </Col>
      </Row>
      <AlertMessage
        message={alertMessage}
        variant={alertVariant}
        onClose={onCloseAlert}
        className='w-100'
        show={showAlert}
      />
      {
        !state.loading && state.data && (
          <ReleaseHero
            title={state.data.title}
            artistLabelNames={artistLabelNames}
            genre={state.data.genre}
            barcode={state.data.barcode}
            originalRealeaseDate={state.data.originalRealeaseDate}
            coverDetails={state.data.coverDetails}
            yearOfRegistration={state.data.yearOfRegistration}
            recordingOwnerName={state.data.recordingOwnerName}
            status={state.data?.status}
            digitalReleaseDate={state.data?.digitalReleaseDate}
            downloadReleaseCoverUrl={`${globalSizes.resourcesMainDomain}${globalLinks.api.libraries.release}/${state.data.id}/cover`}
            onUploadImage={onReplaceCover}
          />
        )
      }
      {
        !state.loading && (
          <div className="d-bloc mb-4">

            <h3 className='h4'>{uiTexts.libraries.releaseInvoice}</h3>
            <Row className='h6'>
              <Col xs={12} sm={6}>
                {uiTexts.account.invoices.plan}:{' '}
                <span className="text-capitalize font-weight-bold">
                  {state.data?.Order?.plan}
                </span>
              </Col>
              <Col xs={12} sm={6}>
                <span className="text-capitalize font-weight-bold">
                  {state.data?.Order?.orderItems?.planPrice}
                  <span className='fs-12'>{state.data?.Order?.orderItems?.currency}</span>
                </span>
              </Col>
            </Row>

            <Row className='h6'>
              <Col xs={12} sm={6}>
                {libraryMessages.label.digital}:{' '}
                <span className="text-capitalize font-weight-bold">
                  {state.data?.digitalLabel}
                </span>
              </Col>
              <Col xs={12} sm={6}>
                <span className="text-capitalize font-weight-bold">
                  {state.data?.Order?.orderItems?.labelFees}
                  <span className='fs-12'>{state.data?.Order?.orderItems?.currency}</span>
                </span>
              </Col>
            </Row>

            <Row className='h6'>
              <Col xs={12} sm={6}>
                ({libraryMessages.summary.expressDelivery}):{' '}
                <span className="text-capitalize font-weight-bold">
                  {state.data?.digitalReleaseDate}
                </span>
              </Col>
              <Col xs={12} sm={6}>
                <span className="text-capitalize font-weight-bold">
                  {state.data?.Order?.orderItems?.expressDeliveryPrice}
                  <span className='fs-12'>{state.data?.Order?.orderItems?.currency}</span>
                </span>
              </Col>
            </Row>

            <Row className='h6'>
              <Col xs={12} sm={6}>
                {libraryMessages.territories.label}:
              </Col>
              <Col xs={12} sm={6}>
                <span className="text-capitalize text-muted">
                  {
                    state.data?.territories?.length === 0
                      ? libraryMessages.delivery.all
                      : `${libraryMessages.delivery.limited}: ${state.data?.territories?.split(',')?.join(' - ')}`
                  }
                </span>
              </Col>
            </Row>

            <Row className='h6'>
              <Col xs={12} sm={6}>
                {libraryMessages.platforms.label}:
              </Col>
              <Col xs={12} sm={6}>
                <span className="text-capitalize text-muted">
                  {state.data?.platforms?.split(',')?.join(' - ')}
                </span>
              </Col>
            </Row>

            <Row className='h6'>
              <Col xs={12} sm={6}>
                {libraryMessages.genre.label}:{' '}
              </Col>
              <Col xs={12} sm={6}>
                <span className="text-capitalize font-weight-bold">
                  {state.data?.digitalGenre}/{state.data?.digitalSubgenre}
                </span>
              </Col>
            </Row>
            <hr />

            <Row className='h6'>
              <Col xs={12} sm={6}>
                <span className="text-capitalize font-weight-bold">
                  {uiTexts.account.invoices.order}:
                </span>
                {
                  state.data?.Order.status && (
                    <h2 className={`h6`}>
                      {`${uiTexts.global.itemStatus}`}:&nbsp;
                      <span className={`custom__badge badge badge-${globalSizes.statusColor[state.data?.Order.status]}`}>
                        {uiTexts.global.status[state.data?.Order.status]}
                      </span>
                    </h2>
                  )
                }
              </Col>
              <Col xs={12} sm={6}>
                <span className="text-capitalize font-weight-bold">
                  {state.data?.Order?.id}
                </span>
              </Col>
            </Row>

            <Row className='h6'>
              <Col xs={12} sm={6}>
                <span className="text-capitalize font-weight-bold">
                  {uiTexts.global.total}:
                </span>
              </Col>
              <Col xs={12} sm={6}>
                <span className="text-capitalize font-weight-bold">
                  {state.data?.Order?.totalAmountByLocation}
                  <span className='fs-12'>{state.data?.Order?.orderItems?.currency}</span>
                </span>{' / '}
                <span className="text-capitalize font-weight-bold">
                  {state.data?.Order?.totalAmountGlobal}
                  <span className='fs-12'>{state.data?.Order?.orderItems?.globalCurrency}</span>
                </span>
              </Col>
            </Row>

            <Row className='h6'>
              <Col xs={12} sm={6}>
                <span className="text-capitalize font-weight-bold">
                  {uiTexts.signup.form.fname}:
                </span>
              </Col>
              <Col xs={12} sm={6}>
                <Link to={`${globalLinks.account.profile}/${state.data?.userId}`} className="text-capitalize font-weight-bold" title={`visiter le compte`}>
                  {state.data?.Order?.transactionDetails?.metadata?.customer_fullname || `${state.data?.Account?.fname || ''} ${state.data?.Account?.lname || ''}`}
                </Link>
              </Col>
            </Row>

            <Row className='h6'>
              <Col xs={12} sm={6}>
                <span className="text-capitalize font-weight-bold">
                  {uiTexts.signup.form.email}:
                </span>
              </Col>
              <Col xs={12} sm={6}>
                <span className="text-lowercase font-weight-bold">
                  {state.data?.Order?.transactionDetails?.receipt_email || state.data?.Account?.email}
                </span>
              </Col>
            </Row>

            <Row className='h6'>
              <Col xs={12} sm={6}>
                <span className="font-weight-bold">
                  {uiTexts.account.invoices.stripeId}:
                </span>
              </Col>
              <Col xs={12} sm={6}>
                <span className="text-capitalize font-weight-bold">
                  {state.data?.Order?.transactionDetails?.id}
                </span>
              </Col>
            </Row>

          </div>
        )
      }
      <h3 className='h4 mt-3 mb-3'>{releasesText.tracksListTitle}</h3>
      {
        !state.loading && (
          <React.Fragment>
            <TracksList
              data={state?.data?.tracks}
              onClickItem={onToggleEditTrackModal}
              onRemove={onRemoveTrackById}
              keyNames={['index', 'title', 'Artists', 'isrc']}
              headLabels={['Nº', 'Titre', 'Artiste', 'ISRC']}
              downloadAudio
              onDragEnd={onDragEnd}
            />
            <Modal show={showModal} onHide={onToggleEditTrackModal(-1)} backdrop="static" size="lg" centered>
              <Modal.Header className='border-0' closeButton>
                <Modal.Title>
                  {uiTexts.global.edit} {selectedTrackState.data?.title}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="show-grid pl-0 pr-0 pt-0">
                <Container>
                  {
                    !!trackEditAlertSetup.textContent &&
                    <Alert className='w-100 mb-4' variant={trackEditAlertSetup.className}>
                      {trackEditAlertSetup.textContent}
                    </Alert>
                  }
                  <div className='pt-3'>
                    {
                      <TracksReleaseForm
                        isEdit={true}
                        state={selectedTrackState}
                        data={trackReleaseFormData}
                        onSubmit={onSubmitEditTrack}
                        onClose={undefined}
                        onChange={onChange}
                        onChangeSelect={onChangeSelect}
                        onCreateFormCollectionItem={onCreateFormCollectionItem}
                        onChangeCollectionItem={onChangeCollectionItem}
                        onRemoveCollectionItem={onRemoveCollectionItem}
                        onCreateNewArtist={onCreateNewArtist}
                        onChangeNoVoice={onChangeNoVoice}
                        onSelectFile={onSelectTrackAudio}
                      />
                    }
                  </div>
                </Container>
              </Modal.Body>
            </Modal>
          </React.Fragment>
        )
      }
    </PageLayout>
  )
}
