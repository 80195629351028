import React from 'react';
import Card, { CardProps, } from 'react-bootstrap/Card';
import { ArrowRight } from 'react-feather'
import { Link } from 'react-router-dom';

import './style.css'
import globalSizes from '../../config/sizes';

interface BoxLinkProps extends CardProps {
  title: string;
  href?: string;
  linkLabel?: string;
  linkVariant?: string;
  linkClassName?: string;
  isExternalLink?: boolean;
  bodyClassName?: string;
}
export default function BoxLink({ href, linkLabel, linkVariant, isExternalLink = false, bodyClassName = '', linkClassName = '', title, children, className = '', ...rest }: BoxLinkProps) {
  return (
    <Card border='0' className={`box-link__block overflow-hidden ${className}`} {...rest}>
      <Card.Title className='text-truncate'>{title}</Card.Title>
      <Card.Body className={`border border_color_light-blue border_radius-top-corner_3 bg-white p-3 ${bodyClassName}`}>
        <div className='card-text'>{children}</div>
      </Card.Body>
      {
        href &&
        (
          <Card.Footer className='box-link__footer border_radius-bottom-corner_3 overflow-hidden p-0'>
            {
              isExternalLink
                ? (
                  <a target='_blank' className={`btn box-link__link d-block text-left clearfix ${linkClassName} pl-3 pr-3`} href={href}>
                    {linkLabel}
                    <ArrowRight className='float-right' size={globalSizes.icon.default} />
                  </a>
                )
                : (
                  <Link className={`btn box-link__link d-block text-left clearfix ${linkClassName} pl-3 pr-3`} to={href}>
                    {linkLabel}
                    <ArrowRight className='float-right' size={globalSizes.icon.default} />
                  </Link>
                )
            }
          </Card.Footer>
        )
      }
    </Card>
  )
}
