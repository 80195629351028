import React, { useContext } from 'react';
import globalLinks from '../../config/links';
import PrivateRoute from '../../libs/PrivateRoute';
import { CustomRoutesContainerProps } from '../../types/shared';
import AccountingStatsHome from '../../pages/AccountingStatsHome';
import AuthContext from '../../context/auth';
import { renderRouteIfAllowedTo } from '../../utils/ui/capabilities';
import { useLocation, useParams } from 'react-router-dom';

const { root } = globalLinks.accounting;
export default function AccountingStatsRoutes({ auth }: CustomRoutesContainerProps) {
  const { userCapabilities } = useContext(AuthContext);
  const { pathname } = useLocation();
  const isYoutube = pathname.includes("/youtube");

  return (
    <React.Fragment>
      {
        renderRouteIfAllowedTo(
          userCapabilities,
          ["read_platforms_stats"],
          <PrivateRoute
            exact
            path={`${root}/:type`}
            auth={auth}
          >
            <AccountingStatsHome />
          </PrivateRoute>
        )
      }
      {
        isYoutube && renderRouteIfAllowedTo(
          userCapabilities,
          ["read_youtube_stats"],
          <PrivateRoute
            exact
            path={`${root}/:type`}
            auth={auth}
          >
            <AccountingStatsHome />
          </PrivateRoute>
        )
      }
    </React.Fragment>
  )
}
