import React, { useEffect, useReducer, useRef, useState } from 'react';
import { Activity, Disc, Download, Youtube } from 'react-feather';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';
import axios from 'axios';
import format from 'date-fns/format';
import { useParams } from 'react-router-dom';

import uiTexts from '../../config/text';
import PageLayout from '../../libs/PageLayout';
import CardIcon from '../../components/CardIcon';
import { globalReducer } from '../../store/reducer';
import globalLinks from '../../config/links';
import LoaderIndicator from '../../components/LoaderIndicator';
import AlertMessage from '../../components/AlertMessage';
import globalSizes from '../../config/sizes';
import ReportsFilterForm from '../../components/ReportsFilterForm';
import TableList from '../../components/TableList';
import { formatDateTo } from '../../utils/dates';

import "react-datepicker/dist/react-datepicker.css";
import './style.css';

const initialState = {
  loading: true,
  data: {
    reports: [],
  },
  errors: null,
  global: null,
};
const initFiltersState = {
  fromDate: null,
  toDate: null,
  reports: [],
};

export default function AccountingStatsHome() {
  let totalIncomeFromStart = 0;
  const { type } = useParams<Record<"type", string>>();
  let _isMounted = useRef(true).current;
  const [state, dispatch] = useReducer(globalReducer, initialState);
  const [filters, setFilters] = useState(initFiltersState);
  const reportsList = filters.reports.length === 0 ? state.data.reports : filters.reports;
  const onSelectDate = (key: string) => (originalDate: any) => {
    originalDate = key === 'fromDate'
      ? originalDate
      : lastDayOfMonth(originalDate);
    const value = format(originalDate, globalSizes.dates.en.format);
    _isMounted && setFilters({
      ...filters,
      [key]: value,
    });
  }
  const onFilter = (type: "on" | "off") => () => {
    let reports = [];

    if (type === 'on' && filters.fromDate !== null) {
      const _fromDate = new Date(String(filters.fromDate)).getTime();
      const _toDate = filters.toDate === null
        ? _fromDate
        : new Date(String(filters.toDate)).getTime();

      reports = state.data.reports.filter((report: Record<string, any>) => {
        return report.fromDate >= _fromDate && report.fromDate <= _toDate;
      });
    } else if (type === 'off') {
      reports = [];
    }

    _isMounted && setFilters({
      ...filters,
      reports
    });
  }

  useEffect(() => {
    const loadInitialData = async () => {
      dispatch({
        type: "LOADING",
        payload: true
      });

      try {
        const [{ data: response }, { data: reports }] = await Promise.all([
          axios.get(globalLinks.api.accountingStats.common),
          axios.get(`${globalLinks.api.accountingStats.reports}?type=${type}`),
        ]);
        if (response.code === 'success') {
          _isMounted && dispatch({
            type: 'SET_FIELDS',
            payload: {
              ...response.data,
              reports: reports.data,
            }
          });
        }
      } catch (error) {
        let payload;
        if (error.response && error.response.data && error.response.data.code) {
          payload = error.response.data.errors;
        } else {
          payload = {
            global: uiTexts.global.network
          };
        }
        _isMounted && dispatch({
          type: 'ERROR',
          payload,
        });
      }
    }
    loadInitialData();

    return () => {
      _isMounted = false;
    }
  }, [type]);

  return (
    <PageLayout className='page__bloc pt-3 pt-md-5 pb-3 pb-md-5 main__block' title={uiTexts.accountingStats.home.title}>
      <LoaderIndicator loading={state.loading} />
      <AlertMessage
        variant='danger'
        show={state.errors !== null}
        message={state.erroro?.global}
      />
      <div className="action__block d-flex justify-content-between flex-wrap mb-3">
        <h1 className='h3'>{uiTexts.accountingStats.home.title}</h1>
      </div>
      <div className='d-flex flex-column flex-md-row justify-content-between mb-3 mb-md-4 box-shadow-all p-3 bg-white border_radius_3'>
        <div className='box__item flex_1 mb-3 mb-md-0'>
          <CardIcon
            Icon={Activity}
            variant="success"
            title="Revenu €"
            content={state.data?.totalIncome}
          />
        </div>
        <div className='box__item flex_1 mb-3 mb-md-0'>
          <CardIcon
            Icon={Activity}
            variant="danger"
            title="Impayé €"
            content={state.data?.remainingIncome}
          />
        </div>
        <div className='box__item flex_1'>
          <CardIcon
            Icon={Activity}
            variant="primary"
            title="Comptes"
            content={state.data?.nombreOfCustomers}
          />
        </div>
      </div>
      <div className='reports__container'>
        <h2 className='h3 mb-4'>{uiTexts.reporting.mainTitle}</h2>
        <ReportsFilterForm
          filters={filters}
          onSelectDate={onSelectDate}
          onFilter={onFilter}
        />
        <TableList headers={["id", "Date", "Total (€)"]}>
          {
            state.loading
              ? (
                <tr>
                  <td colSpan={3}>
                    <h4 className='h5 text-muted text-center'>
                      <Download size={globalSizes.icon.xm} className='mr-2' />
                      {uiTexts.global.loading}
                    </h4>
                  </td>
                </tr>
              )
              : (
                reportsList.map(({ totalIncome, fromDate, type }: Record<string, number>, index: number) => {
                  const _dayInMs = 24 * 3600 * 1000;
                  // add one day to the current date of the report to avoid the interference
                  // of data such as last day of the month must be the 1st of next(early bug)
                  const _date = formatDateTo(fromDate + _dayInMs, globalSizes.dates.fr.month);
                  const _totalIncome = totalIncome.toFixed(2);
                  totalIncomeFromStart += totalIncome;

                  return (
                    <React.Fragment key={`report-${fromDate}`}>
                      <tr className={`table-list__item`}>
                        <td className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                          {`#${index}`}
                        </td>
                        <td className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle text-capitalize'>
                          {
                            String(type) === "youtube"
                              ? (<Youtube className={"mr-2"} size={globalSizes.icon.default} />)
                              : (<Disc className={"mr-2"} size={globalSizes.icon.default} />)
                          }
                          <span>{_date}</span>
                        </td>
                        <td className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                          {_totalIncome}
                        </td>
                      </tr>
                      {
                        index === state.data.reports.length - 1 && (
                          <tr>
                            <td className='h5 font-weight-bold pl-4 pr-4 align-middle' colSpan={2}>Total</td>
                            <td className='pl-4 pr-4'>
                              <h4 className='h5 align-middle'>
                                {`${totalIncomeFromStart.toFixed(2)} €`}
                              </h4>
                            </td>
                          </tr>
                        )
                      }
                    </React.Fragment>
                  );
                })
              )
          }
        </TableList>
      </div>
    </PageLayout>
  )
}
