import React, { useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { Download, Play, Trash2, Youtube } from 'react-feather';
import AudioPlayer from 'react-h5-audio-player';
import Modal from 'react-bootstrap/Modal';
import { DragDropContext, Droppable, Draggable, OnDragEndResponder, NotDraggingStyle, DraggingStyle } from "react-beautiful-dnd";
import { KeyValueObject } from '../../types/shared';
import globalSizes from '../../config/sizes';
import uiTexts from '../../config/text';
import globalLinks from '../../config/links';
import { getArtistsLabel, retrievePropsThatMatter } from '../../utils/tools';
import Drag from '../icons/drag';

import 'react-h5-audio-player/lib/styles.css';
import './style.css';

interface TracksListProps {
  data: KeyValueObject<any>[];
  headLabels: string[];
  keyNames?: string[];
  onRemove?: (id: string | number) => void;
  onClickItem?: (id: string | number) => () => void;
  downloadAudio?: boolean;
  onDragEnd?: OnDragEndResponder;
}

export default function TracksList({ data, headLabels, onRemove, onClickItem, downloadAudio, onDragEnd = () => { } }: TracksListProps) {
  const [show, setShow] = useState(false);
  const [audioUrl, setAudioUrl] = useState<string | undefined>(undefined);
  const trackId = useRef<any>();
  const onPlayAudio = (evnt: React.MouseEvent<HTMLButtonElement>) => {
    evnt.stopPropagation();
    const { audiosrc } = evnt.currentTarget.dataset;
    setAudioUrl(audiosrc);
  }
  const onDownloadAudio = (id: string | number) => (evnt: React.MouseEvent<HTMLButtonElement>) => {
    evnt.stopPropagation();
  }
  const onToggle = (id?: any) => (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (id) {
      trackId.current = id;
    }
    setShow(!show);
  }
  const onRemoveItem = (evnt: React.MouseEvent<HTMLButtonElement>) => {
    evnt.stopPropagation();
    if (onRemove && trackId.current) {
      onRemove(trackId.current);
      setShow(false);
    }
  }
  // draggable config & helpers
  const grid = data?.length || 0;
  const getItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined): object => {
    const extraStyle = isDragging
      ? {
        display: "flex",
        flexDirection: "row",
      }
      : {};
    return {
      // some basic styles to make the items look a bit nicer
      userSelect: "none",
      padding: grid * 2,
      margin: `0 0 ${grid}px 0`,
      // change background colour if dragging
      background: isDragging ? "rgba(152, 22, 76, .1)" : "transparent",
      ...extraStyle,
      // styles we need to apply on draggables
      ...draggableStyle,
    };
  };
  const getListStyle = (isDraggingOver: boolean) => ({
    backgroundColor: isDraggingOver ? "rgba(152, 22, 76, 1)" : "transparent",
    padding: grid,
    width: "100%"
  });

  return (
    <>
      {
        data && (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {
                (provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    <div className='tablie-list__wrapper border border_color_light-blue border_radius_3 overflow-hidden'>
                      <Table className='table-list__block bg-white mb-0' responsive hover>
                        <thead className='table-list__header'>
                          <tr className='table-list__item'>
                            {
                              headLabels.map((head: string) => (
                                <th key={head} className='table-list__header-cell text-capitalize pt-3 pl-4 pb-3 pr-4 align-middle'>
                                  {head}
                                </th>
                              ))
                            }
                          </tr>
                        </thead>
                        <tbody className='table-list__body'>
                          {
                            data.map(
                              (track: KeyValueObject<any>, index: number) => {
                                let audio = track.audioDetails && typeof track.audioDetails === 'string'
                                  ? JSON.parse(track.audioDetails)
                                  : track.audioDetails;
                                const audioSrc = `${globalSizes.resourcesMainDomain}${audio?.url}`;
                                let artistLabel = getArtistsLabel(
                                  retrievePropsThatMatter(
                                    track.Artists,
                                    'TrackArtist'
                                  )
                                );

                                return (
                                  <Draggable
                                    key={track.id}
                                    draggableId={`${track.id}`}
                                    index={index}
                                  >
                                    {(provided, snapshot) => (
                                      <tr
                                        className='table-list__item'
                                        onClick={onClickItem && onClickItem(track.id)}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                      >
                                        <td className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                                          <div className="d-flex align-items-center">
                                            <div className="mr-2" title={"Changer le placements des pistes"}>
                                              <Drag width={20} height={20} />
                                            </div>
                                            <div className='mr-2'>{index + 1}</div>
                                            {
                                              downloadAudio && (
                                                <div>
                                                  <Button className='mr-2 p-1' variant='primary'
                                                    href={`${globalSizes.resourcesMainDomain}${globalLinks.api.libraries.tracks.one}/${track.id}/audio`}
                                                    onClick={onDownloadAudio(track.id)}>
                                                    <Download size={globalSizes.icon.default} />
                                                  </Button>
                                                </div>
                                              )
                                            }
                                            {
                                              track?.youtubeVideoId && (
                                                <Button className='mr-2 p-1' variant='danger'
                                                  href={`${globalLinks.youtubeVideo}?v=${track.youtubeVideoId}`}
                                                  onClick={onDownloadAudio(track.id)}
                                                  target="_blank"
                                                >
                                                  <Youtube size={globalSizes.icon.default} />
                                                </Button>
                                              )
                                            }
                                          </div>
                                        </td>
                                        <td className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle d-flex align-items-center flex_1'>
                                          <Button variant='light' className='mr-2' onClick={onPlayAudio} data-audiosrc={audioSrc} disabled={!audioSrc}>
                                            <Play size={globalSizes.icon.default} />
                                          </Button>
                                          <span>{track.title}</span>
                                        </td>
                                        <td className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                                          {artistLabel}
                                        </td>
                                        <td className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                                          {track.isrc}
                                          {
                                            onRemove && (
                                              <Button variant='outline-danger' onClick={onToggle(track.id)}>
                                                <Trash2 size={globalSizes.icon.default} />
                                              </Button>
                                            )
                                          }
                                        </td>
                                      </tr>
                                    )}
                                  </Draggable>
                                )
                              }
                            )
                          }
                          {provided.placeholder}
                        </tbody>
                      </Table>
                      {
                        onRemove && (
                          <Modal show={show} backdrop="static" centered>
                            <Modal.Body>
                              <Modal.Title className='mb-3'>
                                {uiTexts.global.askConfirmation}
                              </Modal.Title>
                              <div className="d-flex justify-content-end">
                                <Button variant='primary' className='border_radius_left-side_lg' onClick={onToggle()}>
                                  {uiTexts.global.cancel}
                                </Button>
                                <Button variant='outline-danger' className='border_radius_right-side_lg' onClick={onRemoveItem}>
                                  {uiTexts.global.sure}
                                </Button>
                              </div>
                            </Modal.Body>
                          </Modal>
                        )
                      }
                    </div>
                  </div>
                )
              }
            </Droppable>
          </DragDropContext>
        )
      }
      {
        !!audioUrl && (
          <>
            <div className='fake-div__block' />
            <div className='audio-player__block'>
              <AudioPlayer
                autoPlay
                src={audioUrl}
              />
            </div>
          </>
        )
      }
    </>
  )
}
