import React, { useContext, useEffect, useReducer } from 'react';
import { ChevronLeft } from 'react-feather'
import Button from 'react-bootstrap/Button';
import Axios from 'axios';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import PageLayout from '../../libs/PageLayout';
import uiTexts from '../../config/text';
import globalSizes from '../../config/sizes';
import { globalReducer } from '../../store/reducer';
import AccountValidator from '../../utils/ProfilValidator';
import globalLinks from '../../config/links';
import AuthContext from '../../context/auth';
import { GlobalReducerActionType } from '../../types/shared';
import BankForm from '../../components/BankForm';


const bankTitle = uiTexts.account.billing.bank.title;
const initialState = {
  loading: false,
  errors: {},
  global: null,
  data: {},
};

export default function NewBankAccount() {
  let _isMounted = true;
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const authContext = useContext(AuthContext);
  const [state, dispatch] = useReducer(globalReducer, initialState);
  // change new date fired by onchange event on input
  const onChange = (evnt: React.ChangeEvent<HTMLInputElement>) => {
    const { name: key, value } = evnt.target;
    dispatch({
      type: 'SET_FIELD',
      payload: { key, value },
    });
  }
  // send update request
  const onSubmit = async (evnt: React.FormEvent<HTMLFormElement>) => {
    evnt.preventDefault();
    _isMounted && dispatch({
      type: 'LOADING',
      payload: true,
    });
    const { owner, bankName, holderCountry, bankCountry, holderAddress, holderAddress2, bankAddress, bankAddress2, holderCity, bankCity, holderPostcode, bankZipCode, iban, swift, } = state.data;
    const newData = { owner, bankName, holderCountry, bankCountry, holderAddress, holderAddress2, bankAddress, bankAddress2, holderCity, bankCity, holderPostcode, bankZipCode, iban, swift, };

    try {
      const errors = AccountValidator.bankAccountData(newData);

      if (false) {
        dispatch({
          type: 'ERROR',
          payload: errors,
        });
      } else {
        let url = globalLinks.api.account.bankAccount.replace(":id", params.id);
        const { data } = await Axios.post(url, newData, {
          headers: {
            'X-Csrf-Token': authContext.csrf,
          },
        });
        if (data.code === 'success') {
          _isMounted && dispatch({
            type: 'SUCCESS',
            payload: data.message,
          });
          window.scrollTo && window.scrollTo(0, 0);
        }
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.code) {
        _isMounted && dispatch({
          type: 'ERROR',
          payload: error.response.data.errors,
        });
      } else {
        _isMounted && dispatch({
          type: 'ERROR',
          payload: { global: uiTexts.global.network },
        });
      }
    }
  }
  // close alert message
  const onClose = (type: GlobalReducerActionType, payload: any) => () => {
    _isMounted && dispatch({
      type,
      payload,
    });
  }

  return (
    <PageLayout className='page__bloc pt-3 pt-md-5 pb-3 pb-md-5 main__block' title={bankTitle}>
      <h1 className="h4 mb-5">
        <Button variant='light' size='sm' className='mr-2' onClick={history.goBack}>
          <ChevronLeft size={globalSizes.icon.xm} />
        </Button>
        {bankTitle}
      </h1>
      <BankForm onSubmit={onSubmit} onChange={onChange} onClose={onClose} state={state} />
    </PageLayout>
  )
}
