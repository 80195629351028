import React from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar';
import ProgressProps from './types';

import './style.css';

export default function ProgressIndicator({ loading, progression, label, className }: ProgressProps) {
  if (loading === false) return null;
  return (
    <div className={`progress-bar__container ${className || ''}`}>
      <ProgressBar
        animated now={progression}
        label={label}
        className='custom__progress-bar border_radius_0'
      />
    </div>
  );
}
