import React, { useContext, useEffect, useReducer } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { format } from 'date-fns';
import Axios from 'axios';
import { ActionMeta, ValueType } from 'react-select';
import { useLocation } from 'react-router-dom';

import PageLayout from '../../libs/PageLayout';
import YoutubeReportForm from '../../components/YoutubeReportForm';
import uiTexts from '../../config/text';
import globalSizes from '../../config/sizes';
import globalLinks from '../../config/links';
import { globalReducer } from '../../store/reducer';
import ReportValidator from '../../utils/ReportValidator';
import AuthContext from '../../context/auth';
import AlertMessage from '../../components/AlertMessage';

const initialDataState: any = {
  loading: true,
  errors: {},
  global: null,
  data: [],
};
const initialReportState = {
  ...initialDataState,
  data: {},
  loading: false
};

export default function CreateYoutubeReport() {
  let _isMounted = true;
  const axiosCancel = Axios.CancelToken.source();
  const { state: userId } = useLocation();
  const { csrf, } = useContext(AuthContext);
  const [accountsState, dispatchAccounts] = useReducer(globalReducer, initialDataState);
  initialReportState.data = {
    userId
  };
  const [state, dispatch] = useReducer(globalReducer, initialReportState);
  const alertMessage = state.errors.global || accountsState.errors.global || state.global;
  const showAlertMessage = !!state.errors.global || !!accountsState.errors.global || !!state.global;
  // select date
  const onSelectDate = (key: string) => (originalDate: any) => {
    const value = format(originalDate, globalSizes.dates.en.format);
    dispatch({
      type: 'SET_FIELD',
      payload: {
        key,
        value,
      }
    });
  }
  /**
   * change the value
   * @param value contain value of <Select />
   * @param action 
   * @see https://react-select.com/
   */
  const onChangeSelect = (value: ValueType<any>, action: ActionMeta<any>) => {
    dispatch({
      type: 'SET_FIELD',
      payload: {
        key: action.name,
        value: value.value,
      }
    })
  }
  // save new input value
  const onChange = (evnt: React.ChangeEvent<HTMLInputElement>) => {
    const { name: key, value } = evnt.target;
    dispatch({
      type: 'SET_FIELD',
      payload: {
        key,
        value
      }
    });
  }
  const onCloseAlert = () => {
    if (!!accountsState.errors.global) {
      _isMounted && dispatchAccounts({
        type: 'ERROR',
        payload: {},
      });
    } else if (!!state.global) {
      _isMounted && dispatch({
        type: 'SUCCESS',
        payload: null,
      });
    } else {
      _isMounted && dispatch({
        type: 'ERROR',
        payload: {},
      });
    }
  }
  // send POST request to create new youtube report
  const onSubmit = async (evnt: React.FormEvent) => {
    evnt.preventDefault();
    dispatch({
      type: 'LOADING',
      payload: true,
    });
    const errors = ReportValidator.verifyYoutubeReportData(state.data);
    if (errors) {
      return dispatch({
        type: 'ERROR',
        payload: errors,
      });
    }
    try {
      const { data } = await Axios.post(
        globalLinks.api.reporting.createYoutube,
        state.data,
        {
          cancelToken: axiosCancel.token,
          headers: {
            'X-Csrf-Token': csrf,
          }
        }
      );
      if (data.code === 'success') {
        _isMounted && dispatch({
          type: 'SUCCESS',
          payload: data.message,
        });
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.code) {
        _isMounted && dispatch({
          type: 'ERROR',
          payload: error.response.data.errors,
        });
      } else {
        _isMounted && dispatch({
          type: 'ERROR',
          payload: { global: uiTexts.global.network },
        });
      }
    }
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    const loadReportData = async () => {
      try {
        const { data } = await Axios.get(globalLinks.api.account.allProfiles, {
          cancelToken: axiosCancel.token,
        });

        if (data.code === 'success') {
          _isMounted && dispatchAccounts({
            type: 'SET_LIST',
            payload: data.data.accounts,
          });
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.code) {
          _isMounted && dispatchAccounts({
            type: 'ERROR',
            payload: error.response.data.errors,
          });
        } else {
          _isMounted && dispatchAccounts({
            type: 'ERROR',
            payload: { global: uiTexts.global.network },
          });
        }
      }
    }
    loadReportData();
    return () => {
      _isMounted = false;
      axiosCancel.cancel('Operation canceled');
    }
  }, []);

  return (
    <PageLayout className='page__bloc pt-3 pt-md-5 pb-3 pb-md-5 main__block' title={uiTexts.reporting.addTitle}>
      {
        (accountsState.loading || state.loading) && (
          <div className='page__spinner d-flex z_index_max justify-content-center align-items-center' data-testid='spinner'>
            <Spinner className='main-text-color' animation="border" role="status">
              <span className="sr-only">{uiTexts.global.loading}</span>
            </Spinner>
          </div>
        )
      }
      <div className="action__block d-flex justify-content-between flex-wrap mb-3 mb-md-4">
        <h1 className='h4'>{uiTexts.reporting.addYoutubeTitle}</h1>
      </div>

      <AlertMessage
        message={alertMessage}
        variant={!!state.global ? 'success' : 'danger'}
        show={showAlertMessage}
        className='mb-4'
        onClose={onCloseAlert}
      />

      <YoutubeReportForm
        onChangeSelect={onChangeSelect}
        onSelectDate={onSelectDate}
        onChange={onChange}
        loading={state.loading}
        disabled={state.loading || !!state.global}
        onSubmit={onSubmit}
        errors={state.errors}
        data={state.data}
        optionalValues={accountsState.data}
        user={state.data.userId}
      />
    </PageLayout>
  )
}
