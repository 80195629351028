import instrumentsList from './instruments';

export const artistRoles = [
  {
    label: 'Artiste Principal',
    value: 'Primary Artist'
  },
  {
    label: 'Feat',
    value: 'Featuring'
  },
  {
    label: 'Avec',
    value: 'With'
  },
  {
    label: 'Presente par',
    value: 'Producer'
  },
  {
    label: 'Remixe par',
    value: 'Remixer'
  },
];

export const contributorRoles = [
  {
    label: 'Compositeur',
    value: 'Composer'
  },
  {
    label: 'Parolier',
    value: 'Lyricist'
  },
  {
    label: 'Arrangeur',
    value: 'Arranger'
  },
  {
    label: 'Producteur',
    value: 'Income Participant'
  },
];

export const musicianRoles = instrumentsList;