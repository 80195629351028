const reportMessages = {
  track: {
    label: 'Selectionnez une piste*',
    errorMsg: 'Piste est requis',
  },
  account: {
    label: 'Selectionnez un compte*',
    errorMsg: 'Compte est requis',
  },
  numOfStreams: {
    label: 'Streams*',
    errorMsg: 'Chiffre des streams est requis',
  },
  incomeOfStreams: {
    label: 'Revenus des streams*',
    errorMsg: 'Revenus des streams est requis',
  },
  numOfYoutube: {
    label: 'Nombre des vues YouTube*',
    errorMsg: 'Nombre des vues YouTube est requis',
  },
  incomeOfYoutube: {
    label: 'Revenus des vues YouTube*',
    errorMsg: 'Revenus des vues YouTube est requis',
  },
  numOfDownloads: {
    label: 'Nombre des téléchargements*',
    errorMsg: 'Nombre des téléchargements est requis',
  },
  incomeOfDownloads: {
    label: 'Revenus des téléchargements*',
    errorMsg: 'Revenus des téléchargements est requis',
  },
  fromDate: {
    label: 'De(date)*',
    errorMsg: 'Date de début est requise'
  },
  toDate: {
    label: 'A(date)*',
    errorMsg: 'Date de fin est requise'
  }
};

export default reportMessages;