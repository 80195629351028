import { getArtistsLabel, retrievePropsThatMatter } from '../tools';

/**
 * generate artists label
 * @param release object containing library details
 */
export const createArtistsLabel = (release: Record<string, any> | null) => {
  let artistLabelNames: string;

  if (release?.artistsLabel === null || release?.artistsLabel === "") {
    artistLabelNames = getArtistsLabel(
      retrievePropsThatMatter(
        release?.tracks?.length
          ? release?.tracks[0]?.Artists
          : [],
        'TrackArtist'
      )
    );
  } else if (release?.artistsLabel) {
    artistLabelNames = getArtistsLabel(JSON.parse(release?.artistsLabel));
  } else {
    artistLabelNames = '';
  }

  return artistLabelNames;
}

/**
 * Parse a json data
 * @param data 
 * @param defaultValue default value to return in case of any error
 * @returns parsed data
 */
export const parseJsonData = (data: string, defaultValue = {}) => {
  let result;

  try {
    result = JSON.parse(data);
  } catch (error) {
    result = defaultValue;
  }

  return result;
}