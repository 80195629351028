import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import Navbar from 'react-bootstrap/Navbar';

import uiTexts from '../../config/text';
import Logo from '../../assets/images/dropzik-logo.png';

import './style.css';
import UIContext from '../../context/ui';

export default function HeaderMenu() {
  const uiContext = useContext(UIContext);
  const onClick = (evnt: React.MouseEvent<HTMLAnchorElement>) => {
    uiContext.setMenuState(!uiContext.menuState);
  }
  return (
    <Navbar sticky='top' bg='dark' className="d-sm-block d-md-none navbar-dark justify-content-between flex-md-nowrap p-0 shadow">
      <Link className="navbar-brand col-md-3 col-lg-2 mr-0 px-3 pt-0 pb-0 image__container shadow-none" to="/" onClick={onClick}>
        <Image src={Logo} height={55} width={157} thumbnail className='image__thumbnail pl-0' alt={uiTexts.global.logo} />
      </Link>
      <Navbar.Toggle onClick={onClick} className='d-block navbar__menu-toggler position-absolute d-md-none' />
    </Navbar>
  )
}
