import Axios from "axios";
import { useEffect, useReducer } from "react";
import { globalReducer } from "../../store/reducer";
import globalLinks from "../../config/links";
import useAbortController from "../use-abort-controller";
import uiTexts from "../../config/text";

const initState: any = {
  loading: true,
  errors: {},
  global: null,
  data: {
    admins: [],
    capabilities: []
  },
};

/**
 * Retrieve initial data(including admins) to add &list admins
 */
export const useManageAdmins = () => {
  const { cancelPreviousRequest, newAbortSignal } = useAbortController();
  const [state, dispatch] = useReducer(globalReducer, initState);

  useEffect(() => {
    const loadReportsData = async () => {
      dispatch({
        type: "LOADING",
        payload: true
      });
      try {
        const endpointUrl = `${globalLinks.api.account.manageAdmins.initialData}`;
        cancelPreviousRequest();
        const { data: response } = await Axios.get(endpointUrl, {
          signal: newAbortSignal()
        });

        if (response.code === 'success') {
          dispatch({
            type: 'SET_FIELDS',
            payload: {
              admins: response.data.admins,
              capabilities: response.data.capabilities,
            },
          });
        }
      } catch (error) {
        if (error?.response?.data?.code) {
          dispatch({
            type: 'ERROR',
            payload: error?.response?.data?.errors,
          });
        } else {
          dispatch({
            type: 'ERROR',
            payload: {
              global:
                uiTexts.global.network
            },
          });
        }
      }
    }
    loadReportsData();
    return () => {
      cancelPreviousRequest();
    }
  }, []);

  return [state, dispatch] as const;
}