import React from 'react';
import Form from 'react-bootstrap/Form';
import { parse, } from 'date-fns';
import fr from 'date-fns/locale/fr';
import Col from 'react-bootstrap/Col';
import DatePicker, { registerLocale } from 'react-datepicker';
import Select from 'react-select';

import uiTexts from '../../config/text';
import GradientButton from '../GradientButton';
import reportMessages from '../../config/messages/report';
import FormField from '../FormField';
import globalSizes from '../../config/sizes';
import YoutubeReportFormPropsType from './types';

import "react-datepicker/dist/react-datepicker.css";

registerLocale('fr', fr);

export default function YoutubeReportForm({ data, user, errors, loading, disabled, optionalValues = [], onSubmit, onChangeSelect, onSelectDate, onChange }: YoutubeReportFormPropsType) {
  // render a message when suggestion list are empty
  const noOptionsMessage = () => uiTexts.global.noOptions;
  // custom input to use with <DatePicker />
  const CustomDateInput = React.forwardRef(({ value, onClick, error, name, label }: any, ref: any) => {
    return (
      <FormField
        autoComplete='off'
        defaultValue={value}
        error={error || null}
        label={label}
        name={name}
        onClick={onClick}
        readonly={false} />
    );
  });
  const fromDate = data?.fromDate
    ? new Date(parse(data?.fromDate, globalSizes.dates.en.format, new Date()))
    : undefined;
  // adapte tracks data fetched from server to react-select options
  const getAccountsOptions = () => {
    let results: any = [];
    optionalValues.map((item: any) => {
      results.push({
        label: `${item.lname?.toUpperCase()} ${item.fname}`,
        value: item.id
      });
    });
    return results;
  }
  const options = getAccountsOptions();
  const selectedProfile = options.find((account: any) => account.value === parseInt(String(user), 10));

  return (
    <Form className='form__block mw-100' onSubmit={onSubmit}>

      <Form.Row>
        <Col className='mb-2'>
          <Form.Group id='trackId' className='custom__select-block'>
            <Select
              options={options}
              name='userId'
              placeholder=''
              noOptionsMessage={noOptionsMessage}
              className='custom__select'
              onChange={onChangeSelect}
              value={selectedProfile}
            />
            {
              errors?.userId && (
                <Form.Control.Feedback className='d-block' type="invalid">
                  {errors?.userId}
                </Form.Control.Feedback>
              )
            }
            <Form.Label>{reportMessages.account.label}</Form.Label>
          </Form.Group>
        </Col>
      </Form.Row>

      <Form.Row>

        <Col xs={12} md={6} >
          <DatePicker
            locale='fr'
            selected={fromDate}
            onChange={onSelectDate('fromDate')}
            customInput={
              <CustomDateInput
                name='fromDate'
                label={reportMessages.fromDate.label}
                error={errors?.fromDate}
              />
            }
            monthClassName={() => 'calendar-month__date'}
            wrapperClassName='w-100'
            placeholderText={reportMessages.fromDate.label}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            showFullMonthYearPicker
          />
        </Col>

        <Col sm={12} md={6}>
          <FormField
            type='number'
            step={0.01}
            defaultValue={data?.incomeOfYoutube}
            error={errors?.incomeOfYoutube || null}
            label={reportMessages.incomeOfYoutube.label}
            name='incomeOfYoutube'
            onChange={onChange}
            required
          />
        </Col>
      </Form.Row>

      {
        onSubmit && (
          <GradientButton disabled={disabled} type='submit' size='lg' className='text-white' block>
            {
              loading
                ? uiTexts.global.loading
                : uiTexts.global.save
            }
          </GradientButton>
        )
      }
    </Form>
  )
}
