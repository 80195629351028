import React, { useContext, useEffect, useReducer, useState, } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import Axios from 'axios';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { ArrowLeft } from 'react-feather'
import PageLayout from '../../libs/PageLayout';
import uiTexts from '../../config/text';
import globalLinks from '../../config/links';
import GradientButton from '../../components/GradientButton';
import globalSizes from '../../config/sizes';
import { globalReducer } from '../../store/reducer';
import { GlobalReducerActionType, KeyValueObject } from '../../types/shared'
import AuthContext from '../../context/auth';
import ProfilValidator from '../../utils/ProfilValidator';
import FormField from '../../components/FormField';
import PaypalForm from '../../components/PaypalForm';
import BankForm from '../../components/BankForm';

const initState = {
  loading: false,
  errors: {},
  global: null,
  data: {},
};
const invoiceTexts = uiTexts.account.invoices;

export default function Invoice() {
  const axiosCancel = Axios.CancelToken.source();
  let _isMounted = true;
  const authContext = useContext(AuthContext);
  const history = useHistory();
  const { state: invoiceDetails } = useLocation<KeyValueObject<any>>();

  if (invoiceDetails === undefined || !invoiceDetails?.id) {
    history.push(globalLinks.account.invoices);
  }
  initState.data = invoiceDetails || {};
  const [state, dispatch] = useReducer(globalReducer, initState);
  // close the <Alert />
  const onClose = (type: GlobalReducerActionType, payload: any) => () => {
    dispatch({
      type,
      payload,
    })
  }
  // change the status of this payout
  const onChange = (evnt: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name: key, value } = evnt.target;
    dispatch({
      type: 'SET_FIELD',
      payload: {
        key,
        value
      }
    });
  }
  const onSubmit = async (evnt: React.FormEvent<HTMLFormElement>) => {
    evnt.preventDefault();
    _isMounted && dispatch({
      type: 'LOADING',
      payload: true,
    });

    try {
      const { status } = state.data;
      const errors = ProfilValidator.payoutStatusData({ status });

      if (errors) {
        dispatch({
          type: 'ERROR',
          payload: errors,
        })
      } else {
        let endpointUrl = globalLinks.api.account.editInvoice.replace(':id', state.data?.id);
        const { data } = await Axios.put(
          endpointUrl,
          { status },
          {
            headers: {
              'X-Csrf-Token': authContext.csrf
            },
          }
        );

        if (data.code === 'success') {
          _isMounted && dispatch({
            type: 'SUCCESS',
            payload: data.message,
          });
        }
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.code) {
        _isMounted && dispatch({
          type: 'ERROR',
          payload: error.response.data.errors,
        });
      } else {
        _isMounted && dispatch({
          type: 'ERROR',
          payload: { global: uiTexts.global.network },
        });
      }
    }
  }
  // navigate to release
  const onNavigateToRelease = () => {
    history.push(`${globalLinks.library.release}/${state.data?.LibraryId}`);
  }

  useEffect(() => {
    return () => {
      _isMounted = false;
      axiosCancel.cancel('Operation canceled');
    }
  }, []);

  return (
    <PageLayout className='page__bloc pt-3 pt-md-5 pb-3 pb-md-5 main__block' title={`${invoiceTexts.order} #${state.data?.id}`}>
      {
        state.loading && (
          <div className='page__spinner d-flex justify-content-center align-items-center' data-testid='spinner'>
            <Spinner className='main-text-color' animation="border" role="status">
              <span className="sr-only">{uiTexts.global.loading}.</span>
            </Spinner>
          </div>
        )
      }
      <div className="action__block d-flex justify-content-between flex-wrap mb-3 mb-md-4">
        <h1 className='h4'>{`${invoiceTexts.order} #${state.data?.id}`}</h1>
      </div>
      {
        !!state.errors.global &&
        <Alert className='w-100' variant='danger' onClose={onClose('ERROR', {})} dismissible transition>{state.errors.global}</Alert>
      }
      {
        !!state.global &&
        <Alert className='w-100' variant='success' onClose={onClose('SUCCESS', null)} dismissible transition>{state.global}</Alert>
      }
      <Form onSubmit={onSubmit} className='form__block mw-100'>

        <Form.Row>
          <Col xs={12} sm={6}>
            <FormField name='' defaultValue={`${state.data?.plan}`} label={invoiceTexts.plan} readonly />
          </Col>
          <Col xs={12} sm={6}>
            <FormField name='' defaultValue={`${state.data?.totalAmountByLocation} ${state.data?.orderItems?.currency}`} label={uiTexts.account.payout.amount} readonly />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col xs={12} sm={6}>
            <FormField name='' defaultValue={state.data?.orderItems?.expressDeliveryPrice} label={`${invoiceTexts.expressDeliveryPrice} en ${state.data?.orderItems?.currency}`} readonly />
          </Col>
          <Col xs={12} sm={6}>
            <FormField name='' defaultValue={state.data?.orderItems?.labelFees} label={`${invoiceTexts.labelFees} en ${state.data?.orderItems?.currency}`} readonly />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col xs={12}>
            <FormField name='' defaultValue={state.data?.transactionDetails?.id} label={invoiceTexts.stripeId} readonly />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col xs={12} sm={6}>
            <Form.Group controlId="status">
              <Form.Label>{uiTexts.global.itemStatus}</Form.Label>
              <Form.Control as="select" name='status' value={state.data?.status} onChange={onChange} size='lg' isInvalid={!!state.errors?.status} required>
                <option value='PENDING'>En attente</option>
                <option value='PAID'>Payée</option>
                <option value='CANCEL'>Annulée</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">{state.errors?.status}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} sm={6}>
            <Button size='lg' variant='outline-primary' onClick={onNavigateToRelease} disabled={!state.data?.LibraryId} block>
              {uiTexts.libraries.newRelease.details}
            </Button>
          </Col>
        </Form.Row>

        <Form.Row>
          <Col xs={12}>
            <FormField name='' defaultValue={state.data?.transactionDetails?.receipt_email} label={uiTexts.signup.form.email} readonly />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col xs={12} sm={6}>
            <FormField name='' defaultValue={state.data?.transactionDetails?.metadata?.customer_fullname} label={uiTexts.signup.form.fullname} readonly />
          </Col>
          <Col xs={12} sm={6}>
            <FormField name='' defaultValue={state.data?.transactionDetails?.metadata?.customer_id} label={uiTexts.global.userId} readonly />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col xs={12} sm={6}>
            <FormField name='' defaultValue={state.data?.transactionDetails?.metadata?.release_title} label={uiTexts.global.releaseTitle} readonly />
          </Col>
          <Col xs={12} sm={6}>
            <FormField name='' defaultValue={state.data?.transactionDetails?.metadata?.release_id} label={uiTexts.global.releaseId} readonly />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col className='d-flex justify-content-end'>
            <Link to={globalLinks.account.invoices} className='btn btn -lg btn-outline-primary d-flex align-items-center mr-2'>
              <ArrowLeft size={globalSizes.icon.default} className='mr-2' />
              {uiTexts.global.cancel}
            </Link>
            <GradientButton type='submit' size='lg' disabled={state.loading} className='text-white'>
              {
                state.loading
                  ? uiTexts.global.loading
                  : uiTexts.global.save
              }
            </GradientButton>
          </Col>
        </Form.Row>

      </Form>

    </PageLayout >
  )
}
