import React, { useContext } from 'react';
import PrivateRoute from '../../libs/PrivateRoute';
import globalLinks from '../../config/links';
import PayoutsList from '../../pages/PayoutsList';
import Payout from '../../pages/Payout';
import UnpaidAccounts from '../../pages/UnpaidAccounts';
import { CustomRoutesContainerProps } from '../../types/shared';
import AuthContext from '../../context/auth';
import { renderRouteIfAllowedTo } from '../../utils/ui/capabilities';

export default function AccountingRoutes({ auth }: CustomRoutesContainerProps) {
  const { userCapabilities } = useContext(AuthContext);

  return (
    <>
      {
        renderRouteIfAllowedTo(
          userCapabilities,
          ["read_pending_payout"],
          <PrivateRoute path={globalLinks.account.pendingPayouts} auth={auth}>
            <PayoutsList status="PENDING" apiUrl={globalLinks.api.account.pendingPayouts} />
          </PrivateRoute>
        )
      }
      {
        renderRouteIfAllowedTo(
          userCapabilities,
          ["read_paid_payout"],
          <PrivateRoute path={globalLinks.account.paidPayouts} auth={auth}>
            <PayoutsList status="PAID" apiUrl={globalLinks.api.account.paidPayouts} />
          </PrivateRoute>
        )
      }
      {
        renderRouteIfAllowedTo(
          userCapabilities,
          ["edit_pending_payout", "edit_paid_payout"],
          <PrivateRoute path={globalLinks.account.payout} auth={auth}>
            <Payout />
          </PrivateRoute>
        )
      }
      {
        renderRouteIfAllowedTo(
          userCapabilities,
          ["read_unpaid_accounts"],
          <PrivateRoute path={globalLinks.account.unpayedAccounts} auth={auth}>
            <UnpaidAccounts />
          </PrivateRoute>
        )
      }
    </>
  )
}
