const statusColor: any = {
  PENDING: 'warning',
  DELIVRED: 'success',
  PAID: 'success',
  CANCEL: 'danger',
}
const globalSizes = {
  icon: {
    default: 20,
    xm: 35,
    md: 50,
    xl: 80,
    xxl: 160,
  },
  netAmountFees: .6,
  dates: {
    fr: {
      format: 'dd/MM/yyyy',
      whole: 'hh:mm dd MMMM yyyy',
      month: 'MMMM yyyy',
      day: 'EEEE dd MMMM yyyy'
    },
    en: {
      format: 'MM/dd/yyyy',
    }
  },
  cover: {
    format: ['image/png', 'image/jpg', 'image/jpeg'],
  },
  audio: {
    format: ['audio/wav', 'audio/x-wav'],
  },
  stripeClientToken: 'pk_test_51HY1s8HXkG4vdBZS2vhdDW7pYgpLrwaJc8eDxtW0On6KTZaoBcnDoB4fjl12E0yZGEnfjmx4wFSabKmdVNVneCp500CaLFfVqq',
  paidStatus: 'PAID',
  statusColor,
  googleAnalytics: "UA-177819551-7",
  currency: 'USD',
  MAX_FILE_CHUNK_SIZE: 350000, // 350Kb
  resourcesMainDomain: process.env.NODE_ENV === "development" ? 'http://localhost:3002' : 'https://app.dropzik.com',
}

export default globalSizes;