import React from 'react';
import Form from 'react-bootstrap/Form';
import { parse, } from 'date-fns';
import fr from 'date-fns/locale/fr';
import Col from 'react-bootstrap/Col';
import DatePicker, { registerLocale } from 'react-datepicker';
import Select from 'react-select';

import uiTexts from '../../config/text';
import GradientButton from '../GradientButton';
import reportMessages from '../../config/messages/report';
import FormField from '../FormField';
import globalSizes from '../../config/sizes';
import ReportFormPropsTypes from './types';

import "react-datepicker/dist/react-datepicker.css";

registerLocale('fr', fr);

export default function ReportForm({ onSubmit, onChangeSelect, onSelectDate, onChange, data, errors, loading, disabled, optionalValues = [] }: ReportFormPropsTypes) {
  // render a message when suggestion list are empty
  const noOptionsMessage = () => uiTexts.global.noOptions;
  // custom input to use with <DatePicker />
  const CustomDateInput = React.forwardRef(({ value, onClick, error, name, label }: any, ref: any) => {
    return (
      <FormField
        autoComplete='off'
        defaultValue={value}
        error={error || null}
        label={label}
        name={name}
        onClick={onClick}
        readonly={false} />
    );
  });
  const fromDate = data?.fromDate
    ? new Date(parse(data?.fromDate, globalSizes.dates.en.format, new Date()))
    : undefined;
  // adapte tracks data fetched from server to react-select options
  const getTracksOptions = () => {
    let results: any = [];
    optionalValues.map((item: any) => {
      results.push({
        label: `${item.title} - ${item.libraryTitle}`,
        value: item.trackId
      });
    });
    return results;
  }

  return (
    <Form className='form__block mw-100' onSubmit={onSubmit}>

      <Form.Row>
        <Col xs={12} sm={6} className='mb-2'>
          <Form.Group id='trackId' className='custom__select-block'>
            <Select
              options={getTracksOptions()} name='trackId'
              placeholder=''
              noOptionsMessage={noOptionsMessage}
              className='custom__select'
              onChange={onChangeSelect} />
            {
              errors?.trackId && (
                <Form.Control.Feedback className='d-block' type="invalid">
                  {errors?.trackId}
                </Form.Control.Feedback>
              )
            }
            <Form.Label>{reportMessages.track.label}</Form.Label>
          </Form.Group>
        </Col>
        <Col xs={12} sm={6} className='mb-2'>
          <DatePicker
            locale='fr'
            selected={fromDate}
            onChange={onSelectDate('fromDate')}
            customInput={
              <CustomDateInput
                name='fromDate'
                label={reportMessages.fromDate.label}
                error={errors?.fromDate}
              />
            }
            monthClassName={() => 'calendar-month__date'}
            wrapperClassName='w-100'
            placeholderText={reportMessages.fromDate.label}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            showFullMonthYearPicker
          />
        </Col>
      </Form.Row>

      <Form.Row>
        <Col sm={12} md={6}>
          <FormField
            type='number'
            defaultValue={data?.numOfStreams} error={errors?.numOfStreams || null}
            label={reportMessages.numOfStreams.label} name='numOfStreams'
            onChange={onChange} required />
        </Col>
        <Col sm={12} md={6}>
          <FormField
            type='number'
            step={0.01}
            defaultValue={data?.incomeOfStreams} error={errors?.incomeOfStreams || null}
            label={reportMessages.incomeOfStreams.label} name='incomeOfStreams'
            onChange={onChange} required />
        </Col>
      </Form.Row>

      <Form.Row>
        <Col sm={12} md={6}>
          <FormField
            type='number'
            defaultValue={data?.numOfDownloads} error={errors?.numOfDownloads || null}
            label={reportMessages.numOfDownloads.label} name='numOfDownloads'
            onChange={onChange} required />
        </Col>
        <Col sm={12} md={6}>
          <FormField
            type='number'
            step={0.01}
            defaultValue={data?.incomeOfDownloads} error={errors?.incomeOfDownloads || null}
            label={reportMessages.incomeOfDownloads.label} name='incomeOfDownloads'
            onChange={onChange} required />
        </Col>
      </Form.Row>

      <Form.Row>
        <Col sm={12} md={6}>
          <FormField
            type='number'
            defaultValue={data?.numOfYoutube} error={errors?.numOfYoutube || null}
            label={reportMessages.numOfYoutube.label} name='numOfYoutube'
            onChange={onChange} required />
        </Col>
        <Col sm={12} md={6}>
          <FormField
            type='number'
            step={0.01}
            defaultValue={data?.incomeOfYoutube} error={errors?.incomeOfYoutube || null}
            label={reportMessages.incomeOfYoutube.label} name='incomeOfYoutube'
            onChange={onChange} required />
        </Col>
      </Form.Row>

      {
        onSubmit && (
          <GradientButton disabled={disabled} type='submit' size='lg' className='text-white' block>
            {
              loading
                ? uiTexts.global.loading
                : uiTexts.global.save
            }
          </GradientButton>
        )
      }
    </Form>
  )
}
