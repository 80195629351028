export const servicesMessages = {
  name: {
    label: 'Code promo',
    errorMsg: 'code promo est requis'
  },
  type: {
    label: 'Type',
  },
  dueDate: {
    label: 'Date d\'experation',
    errorMsg: 'Date d\'experation est requis'
  },
  amount: {
    label: 'Percentage',
    errorMsg: 'Percentage est requis',
    note: 'Pour saisir une virgule(,) taper un point(.), a la place'
  },
  description: {
    label: 'Description',
    errorMsg: 'Description est requis'
  },
  slug: {
    label: 'Lien',
  },
  status: {
    errorMsg: 'Le status doit etre en attente/active',
  },
  facebook: {
    label: 'Facebook',
    errorMsg: 'Facebook est errone'
  },
  instagram: {
    label: 'instagram',
    errorMsg: 'instagram est errone'
  },
  spotify: {
    label: 'spotify',
    errorMsg: 'spotify est errone'
  },
  anghami: {
    label: 'anghami',
    errorMsg: 'anghami est errone'
  },
  deezer: {
    label: 'deezer',
    errorMsg: 'deezer est errone'
  },
  appleMusic: {
    label: 'Apple music',
    errorMsg: 'Apple music est errone'
  },
  youtube: {
    label: 'youtube',
    errorMsg: 'youtube est errone'
  },
  youtubeMusic: {
    label: 'youtube music',
    errorMsg: 'youtube music est errone'
  }
}