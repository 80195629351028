import React from 'react';
import { Route, Redirect, } from 'react-router-dom';
import { AuthRouteProps, } from '../../types/shared';

function PrivateRoute({ auth, children, redirectTo, ...rest }: AuthRouteProps) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth
          ? children
          : <Redirect to={{
            pathname: redirectTo || '/signin',
            state: { from: location.pathname }
          }} />
      }
    />
  );
}

export default PrivateRoute;