import React, { useEffect, useReducer, } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import PageLayout from '../../libs/PageLayout';
import uiTexts from '../../config/text';
import globalLinks from '../../config/links';
import Axios from 'axios';
import { globalReducer } from '../../store/reducer';
import { GlobalReducerActionType, KeyValueObject } from '../../types/shared'
import { useHistory, useParams } from 'react-router-dom';
import CardsList from '../../components/CardsList';
import './style.css';

const initState = {
  loading: true,
  errors: {},
  global: null,
  data: [],
};
const releasesText = uiTexts.libraries;
export default function Release() {
  const params = useParams<KeyValueObject<string>>();
  const history = useHistory();
  const axiosCancel = Axios.CancelToken.source();
  let _isMounted = true;
  const [state, dispatch] = useReducer(globalReducer, initState);

  const onClose = (type: GlobalReducerActionType, payload: any) => () => {
    dispatch({
      type,
      payload,
    })
  }
  /**
   * navigate to release main ui
   * @param release detain of library release
   */
  const onNavigateToReleasePage = (release: Record<string, any>) => () => {
    history.push(`${globalLinks.library.release}/${release.id}`, release);
  }

  useEffect(() => {
    const loadReleasesData = async () => {
      dispatch({
        type: 'RESET_STATE',
        payload: initState,
      })
      try {
        const { data } = await Axios.get(globalLinks.api.libraries.releasesListByStatus[params?.status], {
          cancelToken: axiosCancel.token,
        });

        if (data.code === 'success') {
          _isMounted && dispatch({
            type: 'SET_LIST',
            payload: data.data || []
          });
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.code) {
          _isMounted && dispatch({
            type: 'ERROR',
            payload: error.response.data.errors,
          });
        } else {
          _isMounted && dispatch({
            type: 'ERROR',
            payload: { global: uiTexts.global.network },
          });
        }
      }
    }
    loadReleasesData();
    return () => {
      _isMounted = false;
      axiosCancel.cancel('Operation canceled');
    }
  }, [params.status]);

  return (
    <PageLayout className='page__bloc pt-3 pt-md-5 pb-3 pb-md-5 main__block' title={releasesText.title}>
      {
        state.loading && (
          <div className='page__spinner d-flex justify-content-center align-items-center' data-testid='spinner'>
            <Spinner className='main-text-color' animation="border" role="status">
              <span className="sr-only">{uiTexts.global.loading}.</span>
            </Spinner>
          </div>
        )
      }
      <div className="action__block mb-3 mb-md-4">
        <h1 className='h4'>{releasesText.title}</h1>
        <blockquote className='note__block text-muted mb-0 p-2 pt-1 pb-1 rounded'>
          {uiTexts.libraries.releaseNote}
        </blockquote>
      </div>
      {
        !!state.errors.global &&
        <Alert className='w-100' variant='danger' onClose={onClose('ERROR', {})} dismissible transition>{state.errors.global}</Alert>
      }
      <CardsList
        data={state?.data}
        onNavigateToReleasePage={onNavigateToReleasePage}
      />
    </PageLayout>
  )
}
