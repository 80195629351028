import React, { useContext, useEffect, useReducer, useRef } from 'react';
import { format } from 'date-fns';
import Axios from 'axios';
import { ActionMeta, ValueType } from 'react-select';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { ChevronDown } from 'react-feather';

import PageLayout from '../../libs/PageLayout';
import ReportForm from '../../components/ReportForm';
import AutomaticReportForm from '../../components/AutomaticReportForm';
import CSVReportForm from '../../components/CSVReportForm';
import uiTexts from '../../config/text';
import globalSizes from '../../config/sizes';
import globalLinks from '../../config/links';
import { globalReducer } from '../../store/reducer';
import ReportValidator from '../../utils/ReportValidator';
import AuthContext from '../../context/auth';
import { generateCSVFile, parseCSVFile } from '../../utils/csv';
import LoaderIndicator from '../../components/LoaderIndicator';
import { lastDayOfMonth } from 'date-fns/esm';
import AlertMessage from '../../components/AlertMessage';
import { formatDateTo } from '../../utils/dates';
import DeleteCSVReportsForm from '../../components/DeleteCSVReportsForm';

const initState: any = {
  loading: true,
  errors: {},
  global: null,
  data: [],
};
const notLoadingState: any = {
  loading: false,
  errors: {},
  global: null,
  data: {},
};

export default function CreateTrackReport() {
  let _isMounted = true;
  const computationWorker = useRef<Worker>(null!);
  const axiosCancel = Axios.CancelToken.source();
  const anchorRef = useRef<HTMLAnchorElement>(null!);
  const { csrf, } = useContext(AuthContext);
  const [tracksState, dispatchTracks] = useReducer(globalReducer, initState);
  const [state, dispatch] = useReducer(globalReducer, notLoadingState);
  const [csvReportsState, dispatchCSVReports] = useReducer(globalReducer, notLoadingState);
  const loaderState = tracksState.loading || state.loading || csvReportsState.loading;
  const globalErrorMessage = state.errors.global || tracksState.errors.global || csvReportsState.errors.global;
  const globalSuccessMessage = state.global || csvReportsState.global;
  // select date
  const onSelectDate = (key: string) => (originalDate: any) => {
    const value = format(originalDate, globalSizes.dates.en.format);
    dispatch({
      type: 'SET_FIELD',
      payload: {
        key,
        value,
      }
    });
  }
  /**
   * change the value
   * @param value contain value of <Select />
   * @param action 
   * @see https://react-select.com/
   */
  const onChangeSelect = (value: ValueType<any>, action: ActionMeta<any>) => {
    dispatch({
      type: 'SET_FIELD',
      payload: {
        key: action.name,
        value: value.value,
      }
    })
  }
  // save new input value
  const onChange = (evnt: React.ChangeEvent<HTMLInputElement>) => {
    const { name: key, value } = evnt.target;
    dispatch({
      type: 'SET_FIELD',
      payload: {
        key, value
      }
    });
  }
  // send POST request to create new report
  const onSubmit = async (evnt: React.FormEvent) => {
    evnt.preventDefault();
    dispatch({
      type: 'LOADING',
      payload: true,
    });
    const errors = ReportValidator.verifyReportData(state.data);
    if (errors) {
      return dispatch({
        type: 'ERROR',
        payload: errors,
      });
    }
    try {
      const { data } = await Axios.post(
        globalLinks.api.reporting.create,
        state.data,
        {
          cancelToken: axiosCancel.token,
          headers: {
            'X-Csrf-Token': csrf,
          }
        }
      );
      if (data.code === 'success') {
        _isMounted && dispatch({
          type: 'SUCCESS',
          payload: data.message,
        });
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.code) {
        _isMounted && dispatch({
          type: 'ERROR',
          payload: error.response.data.errors,
        });
      } else {
        _isMounted && dispatch({
          type: 'ERROR',
          payload: { global: uiTexts.global.network },
        });
      }
    }
    window.scrollTo(0, 0);
  }
  const onFetchAutomaticReport = async (evnt: React.FormEvent) => {
    evnt.preventDefault();
    dispatch({
      type: 'LOADING',
      payload: true,
    });

    try {
      const errors = ReportValidator.verifyAutomaticReportData(state.data);

      if (errors) {
        dispatch({
          type: 'ERROR',
          payload: errors,
        });
      } else {
        const { automatic: automaticUrl } = globalLinks.api.reporting;
        const headers = {
          cancelToken: axiosCancel.token,
          headers: {
            'X-Csrf-Token': csrf,
          }
        };
        const automaticData = {
          fromDate: state.data?.fromDate
        }
        const { data: response } = await Axios.post(automaticUrl, automaticData, headers);

        if (response.code = "success") {
          _isMounted && dispatch({
            type: "SUCCESS",
            payload: response.message
          });
        }
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.code) {
        _isMounted && dispatch({
          type: 'ERROR',
          payload: error.response.data.errors,
        });
      } else {
        _isMounted && dispatch({
          type: 'ERROR',
          payload: { global: uiTexts.global.network },
        });
      }
    }

  }
  const onChooseFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.item(0);
    const csvMimeTypes = ['text/csv', 'text/comma-separated-values', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

    if (file?.type !== undefined && csvMimeTypes.includes(file.type)) {
      _isMounted && dispatchCSVReports({
        type: 'RESET_STATE',
        payload: {
          ...csvReportsState,
          data: {
            ...csvReportsState.data,
            file: file
          },
          errors: {}
        }
      });
    } else {
      _isMounted && dispatchCSVReports({
        type: 'ERROR',
        payload: {
          file: 'S.V.P selectionner un fichier CSV'
        }
      })
    }
  }
  const onSelectCSVReportDate = (originalDate: Date) => {
    const fromDate = originalDate.getTime();
    const toDate = lastDayOfMonth(originalDate).getTime();

    dispatchCSVReports({
      type: 'SET_FIELDS',
      payload: {
        fromDate,
        toDate
      }
    });
  }
  /**
   * Generate csv file containing unsaved reports & download it to user disk
   * @param reportsList list of reports parsed from file selected by user
   * @param isrcList list of isrc's send by server of unsaved reports
   */
  const generateUnsavedReports = async (reportsList: Record<string, any>[], isrcList: string[]) => {
    const unsavedRports: any[] = reportsList.filter(({ ISRC: isrc }: any) => {
      return isrcList.includes(isrc);
    });
    const { link } = await generateCSVFile(unsavedRports);
    anchorRef.current.href = link;
    anchorRef.current.click();
  }
  const onCreateReportsViaCSV = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    _isMounted && dispatchCSVReports({
      type: 'LOADING',
      payload: true
    });
    try {
      const validationErrors = ReportValidator.verifyCSVReportData(csvReportsState.data)

      if (validationErrors && _isMounted) {
        dispatchCSVReports({
          type: 'ERROR',
          payload: validationErrors,
        });
        return false;
      }

      const { data: reportsList, errors: csvErrors } = await parseCSVFile(csvReportsState.data.file, {
        worker: true,
        transformHeader: undefined
      });

      if (csvErrors.length !== 0) {
        _isMounted && dispatchCSVReports({
          type: 'ERROR',
          payload: {
            global: uiTexts.global.inValid
          },
        });
        return false;
      }
      const options = {
        fromDate: csvReportsState.data.fromDate,
        toDate: csvReportsState.data.toDate,
      };
      computationWorker.current.postMessage({ reports: reportsList, options });
    } catch (error) {
      _isMounted && dispatchCSVReports({
        type: 'ERROR',
        payload: { global: uiTexts.global.network }
      });
    }
  }
  const onRemoveReportsByMonth = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    _isMounted && dispatchCSVReports({
      type: 'LOADING',
      payload: true
    });

    try {
      const reportDate = csvReportsState.data.fromDate;
      const validationErrors = ReportValidator.verifyMonthlyReportsData({ date: reportDate });

      if (validationErrors && _isMounted) {
        dispatchCSVReports({
          type: 'ERROR',
          payload: validationErrors,
        });
        return false;
      }

      const { data } = await Axios.delete(
        `${globalLinks.api.reporting.deleteByMonth}?date=${reportDate}`,
        {
          cancelToken: axiosCancel.token,
          headers: {
            'X-Csrf-Token': csrf,
          }
        }
      );

      if (data.code === 'success' && _isMounted) {
        dispatchCSVReports({
          type: "SUCCESS",
          payload: data.message
        });
      } else if (_isMounted) {
        dispatchCSVReports({
          type: "ERROR",
          payload: data.errors
        });
      }
    } catch (error) {
      let payload;
      if (error.response && error.response.data && error.response.data.code) {
        payload = error.response.data.errors;
      } else {
        payload = { global: uiTexts.global.network };
      }
      _isMounted && dispatchCSVReports({
        type: 'ERROR',
        payload
      });
    }
  }
  const onMessage = async (event: any) => {
    try {
      const reports = event.data.reports;
      const reportsList = event.data.originalData;
      const reportDate = formatDateTo(csvReportsState.data.fromDate, globalSizes.dates.fr.month)
      let unsavedReports: string[] = [];

      if (reports?.[0].length > 0) {
        let data;
        for (let i = 0; i < reports.length; i++) {
          const reportsBulk = reports[i];
          try {
            const { data: response } = await Axios.post(
              `${globalLinks.api.reporting.csv}?date=${reportDate}`,
              reportsBulk,
              {
                cancelToken: axiosCancel.token,
                headers: {
                  'X-Csrf-Token': csrf,
                }
              }
            );
            data = response;
            unsavedReports = [...unsavedReports, ...response?.data?.unsavedISRC];
          } catch (error) {
            const erroredIsrcs = reportsBulk.map((item: any) => Object.keys(item)?.[0]);
            unsavedReports = [...unsavedReports, ...erroredIsrcs];
          }
        }
        // generate csv file containing unsaved reports
        if (unsavedReports.length > 0) {
          await generateUnsavedReports(reportsList as any, unsavedReports);
        }
        if (data.code === 'success' && _isMounted) {
          dispatchCSVReports({
            type: "SUCCESS",
            payload: data.message
          });
        } else {
          dispatchCSVReports({
            type: "ERROR",
            payload: data.errors
          });
        }
      }
    } catch (error) {
      let payload;
      if (error.response && error.response.data && error.response.data.code) {
        payload = error.response.data.errors;
      } else {
        payload = { global: uiTexts.global.network };
      }
      _isMounted && dispatchCSVReports({
        type: 'ERROR',
        payload
      });
    }
  }
  // allow users to upload resports and delete them
  const onResetCsvReportState = () => {
    _isMounted && dispatchCSVReports({
      type: "SUCCESS",
      payload: false
    });
  }

  useEffect(() => {
    const loadReportData = async () => {
      try {
        const { data } = await Axios.get(globalLinks.api.libraries.tracks.delivered, {
          cancelToken: axiosCancel.token,
        });
        if (data?.data && data?.data?.coverDetails) {
          data.data = {
            ...data.data,
            coverDetails: JSON.parse(data.data.coverDetails)
          }
        }
        if (data.code === 'success') {
          _isMounted && dispatchTracks({
            type: 'SET_LIST',
            payload: data.data,
          });
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.code) {
          _isMounted && dispatchTracks({
            type: 'ERROR',
            payload: error.response.data.errors,
          });
        } else {
          _isMounted && dispatchTracks({
            type: 'ERROR',
            payload: { global: uiTexts.global.network },
          });
        }
      }
    }
    loadReportData();
    computationWorker.current = new Worker("../../workers/reports-computation.js");
    computationWorker.current.addEventListener("message", onMessage);

    return () => {
      _isMounted = false;
      axiosCancel.cancel('Operation canceled');
      computationWorker.current.removeEventListener("message", onMessage);
      computationWorker.current.terminate();
    }
  }, []);

  return (
    <PageLayout className='page__bloc pt-3 pt-md-5 pb-3 pb-md-5 main__block' title={uiTexts.reporting.addTitle}>

      <LoaderIndicator
        loading={loaderState}
        className='z_index_max'
        testid='spinner'
      />
      <div className="action__block d-flex justify-content-between flex-wrap mb-3 mb-md-4">
        <h1 className='h4 text-capitalize'>{uiTexts.reporting.mainTitle}</h1>
      </div>
      <AlertMessage
        message={globalErrorMessage}
        show={!!globalErrorMessage}
        variant="danger"
        className='w-100'
      />
      <AlertMessage
        message={globalSuccessMessage}
        show={!!globalSuccessMessage}
        variant="success"
        className='w-100'
      />

      <Accordion defaultActiveKey='2'>
        <Card className='accorion__card'>
          <Card.Header className='p-0'>
            <Accordion.Toggle
              as={Button}
              variant="link"
              eventKey="0"
              className='custom__accordion-toggle btn-block text-left p-3 pr-1'
            >
              <span className='h5 pl-2'>{uiTexts.reporting.addTitle}</span>
              <ChevronDown size={globalSizes.icon.default} className='accordion__toggle-icon float-right' />
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body className='px-2 pt-4 pb-2'>
              <ReportForm
                onChangeSelect={onChangeSelect}
                onSelectDate={onSelectDate}
                onChange={onChange}
                loading={state.loading}
                disabled={state.loading || !!state.global}
                onSubmit={onSubmit}
                errors={state.errors}
                data={state.data}
                optionalValues={tracksState.data}
              />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card className='accorion__card'>
          <Card.Header className='p-0'>
            <Accordion.Toggle
              as={Button}
              variant="link"
              eventKey="1"
              className='custom__accordion-toggle btn-block text-left p-3 pr-1'
            >
              <span className='h5 pl-2'>{uiTexts.reporting.automaticReport}</span>
              <ChevronDown size={globalSizes.icon.default} className='accordion__toggle-icon float-right' />
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="1">
            <Card.Body className='px-2 pt-2 pb-2 form-group__container'>
              <AutomaticReportForm
                onSelectDate={onSelectDate}
                onSubmit={onFetchAutomaticReport}
                errors={state.errors}
                data={state.data}
                loading={state.loading}
                disabled={state.loading}
              />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card className='accorion__card'>
          <Card.Header className='p-0'>
            <Accordion.Toggle
              as={Button}
              variant="link"
              eventKey="2"
              className='custom__accordion-toggle btn-block text-left p-3 pr-1'
              onClick={onResetCsvReportState}
            >
              <span className='h5 pl-2'>{uiTexts.reporting.csvReport}</span>
              <ChevronDown size={globalSizes.icon.default} className='accordion__toggle-icon float-right' />
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="2">
            <Card.Body className='px-2 pt-2 pb-2 form-group__container'>
              <CSVReportForm
                errors={csvReportsState.errors}
                data={csvReportsState.data}
                loading={csvReportsState.loading}
                disabled={csvReportsState.loading || !!csvReportsState.global}
                onSelectDate={onSelectCSVReportDate}
                onChooseFile={onChooseFile}
                onSubmit={onCreateReportsViaCSV}
              />
              <a
                ref={anchorRef}
                className='hidden'
                download={'unsaved-reports.csv'}
              />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card className='accorion__card'>
          <Card.Header className='p-0'>
            <Accordion.Toggle
              as={Button}
              variant="link"
              eventKey="3"
              className='custom__accordion-toggle btn-block text-left p-3 pr-1'
              onClick={onResetCsvReportState}
            >
              <span className='h5 pl-2'>{uiTexts.reporting.deleteReport}</span>
              <ChevronDown size={globalSizes.icon.default} className='accordion__toggle-icon float-right' />
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="3">
            <Card.Body className='px-2 pt-2 pb-2 form-group__container'>
              <DeleteCSVReportsForm
                errors={csvReportsState.errors}
                data={csvReportsState.data}
                loading={csvReportsState.loading}
                disabled={csvReportsState.loading || !!csvReportsState.global}
                onSelectDate={onSelectCSVReportDate}
                onSubmit={onRemoveReportsByMonth}
              />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </PageLayout >
  )
}
