import React from 'react'
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import FormField from '../FormField';
import libraryMessages from '../../config/messages/library';
import GradientButton from '../GradientButton';
import uiTexts from '../../config/text';
import { NewArtistFormPropsType } from './types';

export default function NewArtistForm({ data: showNewArtistForm, errors, onChange, onCreateNewArtist }: NewArtistFormPropsType) {
  return (
    <Container>
      <Form.Row>
        <Col sm={12}>
          <FormField
            name='fullname'
            label='Artist fullname'
            defaultValue={showNewArtistForm.fullname}
            onChange={onChange}
            error={errors.fullname}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col sm={12}>
          <FormField
            name='spotifyId'
            label={libraryMessages.spotifyId.label}
            placeholder='22bE4uQ6baNwSHPVcDxLCe'
            defaultValue={showNewArtistForm.spotifyId}
            pattern='[0-7][0-9a-zA-Z]{21}'
            error={errors.spotifyId}
            onChange={onChange}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col sm={12}>
          <FormField
            name='appleMusicId'
            label={libraryMessages.appleMusicId.label}
            placeholder='1249595'
            defaultValue={showNewArtistForm.appleMusicId}
            pattern='[1-9]+'
            error={errors.appleMusicId}
            onChange={onChange}
          />
        </Col>
      </Form.Row>
      <div className="d-flex justify-content-sm-end justify-content-start">
        <GradientButton
          disabled={showNewArtistForm.loading}
          className='text-white pl-4 pr-4' size='lg' type="button"
          onClick={onCreateNewArtist}>
          {
            showNewArtistForm.loading
              ? uiTexts.global.loading
              : uiTexts.global.edit
          }
        </GradientButton>
      </div>
    </Container>
  );
}
