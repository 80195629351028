import React, { useContext } from 'react';
import PrivateRoute from '../../libs/PrivateRoute';
import globalLinks from '../../config/links';
import ReportingsDetails from '../../pages/ReportingsDetails';
import TrackReportingsDetails from '../../pages/TrackReportingsDetails';
import CreateTrackReport from '../../pages/CreateTrackReport';
import CreateYoutubeReport from '../../pages/CreateYoutubeReport';
import { CustomRoutesContainerProps } from '../../types/shared';
import { renderRouteIfAllowedTo } from '../../utils/ui/capabilities';
import AuthContext from '../../context/auth';

export default function ReportsRoutes({ auth }: CustomRoutesContainerProps) {
  const { userCapabilities } = useContext(AuthContext);

  return (
    <>
      {
        renderRouteIfAllowedTo(
          userCapabilities,
          ["read_reports"],
          <>
            <PrivateRoute exact path={globalLinks.reporting.root} auth={auth}>
              <ReportingsDetails />
            </PrivateRoute>
            <PrivateRoute exact path={globalLinks.reporting.byTrack} auth={auth}>
              <TrackReportingsDetails />
            </PrivateRoute>
          </>
        )
      }
      {
        renderRouteIfAllowedTo(
          userCapabilities,
          ["edit_reports"],
          <>
            <PrivateRoute path={globalLinks.reporting.platform} auth={auth}>
              <CreateTrackReport />
            </PrivateRoute>
            <PrivateRoute path={globalLinks.reporting.youtube} auth={auth}>
              <CreateYoutubeReport />
            </PrivateRoute>
          </>
        )
      }
    </>
  )
}
