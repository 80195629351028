import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import uiTexts from '../../config/text';
import GradientButton from '../GradientButton';
import CSVTrackISRCFormPropsTypes from './types';

export default function CSVTrackISRCForm({ data, errors, loading, onChooseFile, onSubmit, onChange }: CSVTrackISRCFormPropsTypes) {
  const invalidFileData = errors?.file;

  return (
    <Form className='form__block mw-100 mt-4' onSubmit={onSubmit}>
      <Form.Row>
        <Col xs={12} md={6} className='mb-4 pr-0'>
          <Form.Row className='w-100 pl-1'>
            <Form.Group className='w-100 form-file-size_lg' controlId='csv-file'>
              <Form.Control size={"lg"} as={"div"} className={"border-0 p-0"}>
                <Form.File id="csv-file" className="h-100" custom>
                  <Form.File.Input
                    isInvalid={invalidFileData}
                    onChange={onChooseFile}
                  />
                  <Form.File.Label
                    data-browse="Choisir"
                    className='text-truncate'
                  >
                    {
                      data.file?.name
                    }
                  </Form.File.Label>
                  {
                    invalidFileData && (
                      <Form.Control.Feedback type="invalid" className='mb-2'>
                        {invalidFileData}
                      </Form.Control.Feedback>
                    )
                  }
                </Form.File>
              </Form.Control>
              <Form.Label className='fl-uppercase z_index_1'>
                Fichier CSV
              </Form.Label>
            </Form.Group>
          </Form.Row>
        </Col>
        <Col sm={12} md={6}>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="type" title="Choisir isrc pour modifier ISRC ou barcode">
              Type de fichier
            </Form.Label>
            <Form.Control
              name="type"
              as="select"
              id="type"
              className="h-100"
              onChange={onChange}
              defaultValue={"0"}
            >
              <option value="0" disabled>{uiTexts.tools.fileTypeLabel}</option>
              <option value="isrc">{uiTexts.libraries.isrc}</option>
              <option value="barcode">{uiTexts.libraries.barcode}</option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <GradientButton
            disabled={loading}
            type='submit'
            className='text-white'
            size={"lg"}
            block
          >
            {
              loading
                ? uiTexts.global.loading
                : uiTexts.global.save
            }
          </GradientButton>
        </Col>
      </Form.Row>
    </Form>
  )
}
