import React from 'react';
import Card from 'react-bootstrap/Card';
import GradientButton from '../../components/GradientButton';
import Badge from 'react-bootstrap/Badge';
import { LazyLoadImage, ScrollPosition } from 'react-lazy-load-image-component';
import globalSizes from '../../config/sizes';
import uiTexts from '../../config/text';
import 'react-lazy-load-image-component/src/effects/blur.css';

interface CardImagePropsTypes {
  imageSource: string;
  release: Record<string, any>;
  scrollPosition?: ScrollPosition | undefined;
  height?: number | string;
  onNavigateToReleasePage: () => void;
}
const releasesText = uiTexts.libraries;
function CardImage({ imageSource, release, scrollPosition, height = 200, onNavigateToReleasePage }: CardImagePropsTypes) {
  return (
    <Card className='card__block shadow border_radius_3 overflow-hidden mt-3 mb-3'>
      <div className='card-image__block overflow-hidden position-relative d-flex justify-content-center'>
        <LazyLoadImage
          effect='blur'
          className="custom-card__image mx-auto d-block"
          src={`https://images.weserv.nl/?url=${globalSizes.resourcesMainDomain}${imageSource}&output=webp&w=365&q=85`}
          alt={release.title}
          height={height}
          scrollPosition={scrollPosition}
        />
        <Badge className='custom__badge position-absolute_bottom-right' variant={globalSizes.statusColor[release.status] || 'warning'}>
          {uiTexts.global.status[release.status]}
        </Badge>
      </div>
      <Card.Body>
        <Card.Title className='card__title text-capitalize text-truncate'>{release.title}</Card.Title>
        <Card.Text>
          <span className='d-block h6 text-truncate'>{`${releasesText.genre}: ${release.genre}`}</span>
          <span className='d-block h6 text-truncate'>{`${releasesText.originalRealeaseDate}: ${release.originalRealeaseDate}`}</span>
        </Card.Text>
        <GradientButton className='text-white' onClick={onNavigateToReleasePage}>
          {uiTexts.global.visit}
        </GradientButton>
      </Card.Body>
    </Card>
  );
}

export default CardImage;