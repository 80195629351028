import Joi from "joi";
import { fr } from "date-fns/locale";
import globalSizes from "../config/sizes";
import { KeyValueObject } from "../types/shared";
import { format } from "date-fns";
import uiTexts from "../config/text";
import { artistRoles } from "../config/data/roles";

interface UsefulErrorsType {
  [key: string]: string;
}
/**
 * create an object constain validation errors of Joi package
 * @param errors list of joi errors(error.details in joi)
 * @see https://joi.dev/api/?v=17.2.1
 */
export function buildUsefulJoiErrorObject(errors: Joi.ValidationErrorItem[]): KeyValueObject<string> {
  const usefulErrors: UsefulErrorsType = {};
  errors.forEach(({ path, context }) => {
    if (!usefulErrors.hasOwnProperty(path.join('_'))) {
      usefulErrors[path.join('_')] = context?.label || '';
    }
  });
  return usefulErrors;
};

/**
 * retrieve the value of param from url search
 * @param paramName name of param in url search part
 * @param search the search value of url
 */
export function getSearchParam(paramName: string, search: string): string {
  let searchValue = '';
  if (!search.length) searchValue = '';
  else {
    const [, results] = search.split('?');
    results.split('&')
      .forEach((cur) => {
        let [p, v] = cur.split('=');
        if (p === paramName) {
          searchValue = v;
        }
      });
  }
  return searchValue;
}

/**
 * Match current artist/contributor role with the given roles list used
 * 2retrieve the text to show(label prop) and its value(value prop) 2save in DB
 * @param artist artist/contributor details including role(required)
 * @param roles list of role to match with if there isnt then will take artistsRoles
 * @returns object with label & value props
 */
export const matchRole = (artist: { role: string }, roles?: typeof artistRoles) => {
  roles = roles ?? artistRoles;
  const targetRole = roles.find(({ value }) => value == artist?.role)
  return targetRole;
}

/**
 * create a artists label like Matoub feat Sliman Azzem
 * @param artists list of artists
 */
export const getArtistsLabel = (artists: KeyValueObject<any>[]): string => {
  let labelReturn = '';
  let nbrOfPrincipal = 0;
  artists.map((artist: any) => {
    if (artist?.role && /principal|Primary Artist/i.test(artist?.role)) {
      nbrOfPrincipal++;
      if (nbrOfPrincipal > 1) {
        labelReturn += '& ';
      }
      labelReturn += `${artist.fullname} `;
    } else if (artist?.role) {
      const targetRole = matchRole(artist);
      labelReturn += `${targetRole?.label || artist?.role} ${artist.fullname} `;
    }
  });
  return labelReturn;
}

/**
 * create a react-select options
 * @param list data to restructure
 * @see https://react-select.com/props
 */
export const buildSelectOptions = (list: KeyValueObject<any>[]) => {
  return list.map((item: KeyValueObject<any>) => ({ value: item.id, label: item.fullname }));
}

/**
 * format track response received from server to use on ui
 * @param data track details received from server(on create "POST" new track or on retrieve "GET" request)
 * @param originalKey then name of the key to format
 */
export const adapteTrackDetails = (data: KeyValueObject<any>, itemKey: string, roleKey: string) => {
  let response: KeyValueObject<any>[] = [];
  if (data[itemKey]) {
    data[itemKey].map((track: any) => {
      response.push({
        id: track.id,
        fullname: track.fullname,
        role: track[roleKey]?.role,
      });
    });
  }
  return response;
}

/**
 * get the total of each report item
 * @param reports list of track reports
 */
export const getGlobalTrackReportStatistics = (reports: KeyValueObject<number>[]) => {
  let numOfStreams = 0;
  let incomeOfStreams = 0;
  let numOfYoutube = 0;
  let incomeOfYoutube = 0;
  let numOfDownloads = 0;
  let incomeOfDownloads = 0;

  reports.map((report: KeyValueObject<number>) => {
    numOfStreams += report.numOfStreams;
    incomeOfStreams += report.incomeOfStreams;
    numOfYoutube += report.numOfYoutube;
    incomeOfYoutube += report.incomeOfYoutube;
    numOfDownloads += report.numOfDownloads;
    incomeOfDownloads += report.incomeOfDownloads;
  });
  return {
    numOfStreams,
    incomeOfStreams,
    numOfYoutube,
    incomeOfYoutube,
    numOfDownloads,
    incomeOfDownloads,
  }
}

export const refactorReportsToLineChartData = (data: KeyValueObject<any>[] | undefined) => {
  let dataForLineChart = [
    {
      "id": "Reports",
      "color": "hsl(326, 70%, 50%)",
      "data": [
        {
          "x": "1",
          "y": 0
        },
        {
          "x": "2",
          "y": 0
        },
        {
          "x": "3",
          "y": 0
        },
        {
          "x": "4",
          "y": 0
        },
        {
          "x": "5",
          "y": 0
        },
        {
          "x": "6",
          "y": 0
        },
        {
          "x": "7",
          "y": 0
        },
        {
          "x": "8",
          "y": 0
        },
        {
          "x": "9",
          "y": 0
        },
        {
          "x": "10",
          "y": 0
        },
        {
          "x": "11",
          "y": 0
        },
        {
          "x": "12",
          "y": 0
        }
      ]
    }
  ];
  if (data === undefined || data?.length === 0) {
    return dataForLineChart;
  } else {
    dataForLineChart[0].data = [];
    data.map((report: KeyValueObject<any>, index: number) => {
      let fromDate;
      try {
        fromDate = format(new Date(report.fromDate), globalSizes.dates.fr.format, {
          locale: fr,
        });
      } catch (error) {
        fromDate = 'month';
      }
      let totalIncome = Math.floor(report.streamsTotalIncome + report.youtubeTotalIncome + report.downloadsTotalIncome);

      dataForLineChart[0].data.push({
        x: fromDate,
        y: totalIncome
      });
    });
    return dataForLineChart;
  }
}

/**
 * get essential data from list of values from received data
 * @param values list of artists/editors/contributors/musicians
 * @param deepProp role of values listed above
 */
export const retrievePropsThatMatter = (values: KeyValueObject<any>[] | null, deepProp?: string) => {
  if (values === null || values === undefined) {
    return [];
  } else {
    return values.map((value: KeyValueObject<any>) => {
      let prop: any = {
        id: value?.id,
        fullname: value?.fullname,
      }
      if (deepProp) {
        prop.role = value[deepProp]?.role;
      }
      return prop;
    });
  }
}

/**
 * change the text of <Creatable /> for new values
 * @param inputValue the user input
 * @see https://react-select.com/props#creatable-props
 */
export const formatCreateLabel = (inputValue: string) => `${uiTexts.global.add} «${inputValue}»`