import React from "react";
import TicketFormPropsTypes from "./types";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import FormField from "../FormField";
import uiTexts from "../../config/text";
import GradientButton from "../GradientButton";
import { toolsMessages } from "../../config/messages/tools";
import CheckBox from "../CheckBox";

export default function TicketForm({ data: ticket, loading, errors, onChange, onSubmit }: TicketFormPropsTypes) {

  return (
    <Form className="form-collection__item" onSubmit={onSubmit}>
      <Form.Row>
        <Col>
          <FormField
            name="title"
            defaultValue={ticket.title}
            label={toolsMessages.ticket.title.label}
            readonly
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Group controlId="status">
            <Form.Label>Etat</Form.Label>
            <Form.Control
              as="select"
              name="status"
              defaultValue={ticket.status}
              size="lg"
              onChange={onChange}
              custom
            >
              <option value="OPENED">Ouvert</option>
              <option value="CLOSED">Ferme</option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Form.Row>
      {
        !!ticket?.Track?.isrc && (
          <Form.Row>
            <Col className="mb-2">
              <Form.Group>
                <Form.Label
                  className="form-label z_index_1"
                >
                  {toolsMessages.ticket.track.label}
                </Form.Label>
                <div className="px-3">
                  {`${ticket?.Track?.title} - ${ticket?.Track?.isrc}`}
                </div>
              </Form.Group>
            </Col>
          </Form.Row>
        )
      }
      <Form.Row>
        <Col xs={12} sm={6} className="mb-2 mb-md-0">
          <FormField
            name="startAt"
            defaultValue={ticket?.startAt}
            error={errors?.startAt || null}
            label={toolsMessages.ticket.startAt.label}
            placeholder="01:23"
            onChange={onChange}
          />
        </Col>
        <Col xs={12} sm={6} className="mb-0">
          <FormField
            name="endAt"
            defaultValue={ticket?.endAt}
            error={errors?.endAt || null}
            label={toolsMessages.ticket.endAt.label}
            placeholder="47:56"
            onChange={onChange}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col className="mb-3">
          <Form.Group controlId="url" className="mb-0">
            <Form.Label>{toolsMessages.ticket.url.label}</Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              name="url"
              isInvalid={!!errors?.url}
              defaultValue={ticket?.url}
              onChange={onChange}
              required
            />
            <Form.Control.Feedback type="invalid">
              {errors?.url}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row className="mb-3">
        <Col sm={12}>
          {toolsMessages.ticket.agreements.title}
        </Col>
        <Col sm={12}>
          <CheckBox
            name="isClaimed"
            label={toolsMessages.ticket.agreements.youtubeElementNotClaimed}
            onChange={onChange}
            defaultChecked={!!ticket?.isClaimed}
          />
        </Col>
      </Form.Row>
      <Form.Row className="mb-3">
        <Col>
          <CheckBox
            name="isProtected"
            label={toolsMessages.ticket.agreements.contentAlreadyProtected}
            onChange={onChange}
            defaultChecked={!!ticket?.isProtected}
          />
        </Col>
      </Form.Row>
      <div className="d-flex justify-content-sm-end justify-content-start">
        <GradientButton
          disabled={loading}
          className="text-white pl-4 pr-4"
          type="submit"
        >
          {
            loading
              ? uiTexts.global.loading
              : uiTexts.global.save
          }
        </GradientButton>
      </div>
    </Form>
  )
}
