import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import { CSVLink } from "react-csv";
import { Music, Download, Slash } from 'react-feather';
import TableList from '../../components/TableList';
import globalSizes from '../../config/sizes';
import uiTexts from '../../config/text';
import { ProfileReleasesPropsType } from './types';
import { createArtistsLabel } from '../../utils/data';

const csvHeaders = ['#', 'Titre', 'Artistes', 'ISRC', 'Code-barres'];
function ProfileReleases({ csvFilename, releases, isCsvDownloadable, onLoadReleases, onToggleModal }: ProfileReleasesPropsType) {
  const constructCSVDate = () => {
    let csvData: string[][] = [];
    let data = [...(releases.data || [])];
    data.forEach(release => {
      release.tracks.forEach((track: Record<string, string>) => {
        const artists = createArtistsLabel({
          tracks: [track],
          artistsLabel: null
        });
        csvData.push([track.id, track.title, artists, track.isrc, release.barcode]);
      })
    });

    return csvData;
  }

  return (
    <Modal
      show={releases.show}
      onHide={onToggleModal}
      onEntered={onLoadReleases}
      centered
    >
      <Modal.Body>
        <Modal.Title className='mb-3 position-sticky bg-white sticky_top'>
          <Music size={globalSizes.icon.default} className='mr-2' />
          {uiTexts.libraries.tracksList}
        </Modal.Title>
        {releases.loading
          ? (
            <div className='page__spinner d-flex justify-content-center align-items-center blur_bg' data-testid='spinner'>
              <Spinner className='main-text-color' animation="border" role="status">
                <span className="sr-only">{uiTexts.global.loading}.</span>
              </Spinner>
            </div>
          )
          : (
            <TableList headers={['id', 'titre', 'artiste/s', 'isrc', 'barcode']}>
              {releases.data === null || releases.data?.length === 0
                ? (
                  <tr>
                    <td colSpan={3}>
                      <h4 className='h5 text-muted text-center'>
                        <Slash size={globalSizes.icon.xm} className='mr-2' />
                        {uiTexts.global.noOptions}
                      </h4>
                    </td>
                  </tr>
                )
                : (
                  releases.data !== null && (releases.data as any[])?.map(
                    (release: Record<string, string | number>) => {
                      // @ts-ignore
                      return release.tracks.map(track => {
                        const artists = createArtistsLabel({
                          tracks: [track],
                          artistsLabel: null
                        });
                        return (
                          <tr key={track.id} className={`table-list__item`}>
                            <td key={`${track.id}-id`} className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                              {track.id}
                            </td>
                            <td key={`${track.id}-title`} className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                              {track.title}
                            </td>
                            <td key={`${track.id}-artists`} className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                              {artists}
                            </td>
                            <td key={`${track.id}-isrc`} className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                              {track.isrc}
                            </td>
                            <td key={`${track.id}-barcode`} className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                              {release.barcode}
                            </td>
                          </tr>
                        );
                      })
                    }
                  )
                )}
            </TableList>
          )}
        <div className="d-flex justify-content-end mt-3 position-sticky bg-white sticky_bottom">
          <Button variant='outline-danger' className='border_radius_left-side_lg' onClick={onToggleModal}>
            {uiTexts.global.close}
          </Button>
          <Button
            variant='outline-success'
            className='border_radius_right-side_lg'
            disabled={isCsvDownloadable}
          >
            <CSVLink
              className={`text-decoration-none text-inherit${isCsvDownloadable ? ' pointer_none' : ''}`}
              data={constructCSVDate()}
              headers={csvHeaders}
              filename={csvFilename}
            >
              <Download size={globalSizes.icon.default} className='mr-2' />
              {uiTexts.global.downloadCsv}
            </CSVLink>
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ProfileReleases;