import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormField from '../FormField';
import uiTexts from '../../config/text';
import AdminFormProps from './types';
import GradientButton from '../GradientButton';

export default function AdminForm({ loading, data: admin, capabilities, errors, onChange, onSelectCapabilities, onSubmit }: AdminFormProps) {
  return (
    <Form
      className='form-collection__item'
      onSubmit={onSubmit}
    >
      <Form.Row>
        <Col sm={12} md={6}>
          <FormField
            name='fname'
            defaultValue={`${admin?.fname || ""}`}
            error={errors?.fname}
            label={uiTexts.signup.form.fname}
            onChange={onChange}
          />
        </Col>
        <Col sm={12} md={6}>
          <FormField
            name='lname'
            defaultValue={`${admin?.lname || ""}`}
            label={uiTexts.signup.form.lname}
            error={errors?.lname}
            onChange={onChange}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col sm={12} md={6}>
          <FormField
            name='email'
            defaultValue={`${admin?.email || ""}`}
            error={errors?.email}
            label={uiTexts.signup.form.email}
            onChange={onChange}
          />
        </Col>
        <Col sm={12} md={6}>
          <FormField
            name='password'
            error={errors?.password}
            label={uiTexts.signup.form.password}
            type='password'
            onChange={onChange}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Group>
            <label className="form-label">
              Permissions
            </label>
            <div className='d-flex flex-wrap pt-2 gap-3'>
              {capabilities.map(capability => (
                <Form.Check
                  key={`cap-${capability.id}`}
                  id={`cap-${capability.id}`}
                  className={`custom__checkbox-container`}
                >
                  <Form.Check.Input
                    name={'capabilities'}
                    type={'checkbox'}
                    className={`custom__checkbox`}
                    value={`${capability.id}`}
                    onChange={onSelectCapabilities}
                    // @ts-ignore
                    defaultChecked={admin?.capabilities?.includes(capability.id)}
                  />
                  <Form.Check.Label>
                    {capability.name}
                  </Form.Check.Label>
                </Form.Check>
              ))}
            </div>
          </Form.Group>
        </Col>
      </Form.Row>
      <div className='d-flex justify-content-end'>
        <GradientButton
          disabled={loading}
          className='text-white pl-4 pr-4'
          type='submit'
        >
          {
            loading
              ? uiTexts.global.loading
              : uiTexts.global.save
          }
        </GradientButton>
      </div>
    </Form>
  )
}
