import React, { useReducer, useContext } from 'react';
import Axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Music, Save } from 'react-feather';
import Form from 'react-bootstrap/Form';
import Papa from 'papaparse';
import globalSizes from '../../config/sizes';
import globalLinks from '../../config/links';
import uiTexts from '../../config/text';
import { ProfileCSVReleasesFormPropsTypes } from './types';
import { globalReducer } from '../../store/reducer';
import LoaderIndicator from '../../components/LoaderIndicator';
import AlertMessage from '../../components/AlertMessage';
import ProfilValidator from '../../utils/ProfilValidator';
import AuthContext from '../../context/auth';

function ProfileCSVReleasesForm({ user, show, onToggleModal }: ProfileCSVReleasesFormPropsTypes) {
  const { csrf } = useContext(AuthContext);
  const [state, dispatch] = useReducer(globalReducer, {
    loading: false,
    errors: null,
    data: {
      releases: null,
      file: null,
    }
  });
  const showAlertMessage = !!state.errors?.global || !!state.global;
  const alertMessage = state.errors?.global || state.global;
  const alertVariant = state.errors?.global ? "danger" : "success";
  const invalidFileData = !!state.errors?.file || !!state.errors?.releases;
  const onChooseFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.item(0);
    const csvMimeTypes = ['text/csv', 'text/comma-separated-values', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

    if (file?.type !== undefined && csvMimeTypes.includes(file.type)) {
      dispatch({
        type: 'RESET_STATE',
        payload: {
          ...state,
          errors: null,
          data: {
            ...state.data,
            file
          }
        }
      });
    } else {
      dispatch({
        type: 'ERROR',
        payload: {
          file: 'S.V.P selectionner un fichier Excel'
        }
      })
    }
  }
  const csvTransformHeader = (header: string) => {
    let headerName = header.toLowerCase();
    headerName = headerName === "upc" ? "barcode" : headerName;

    return headerName;
  }
  /**
   * Parse csv file to json
   * @param file selected file(csf format)
   * @returns json format of the file content
   */
  const parseCSVFile = (file: File): Promise<Papa.ParseResult<unknown>> => {
    return new Promise((resolve) => {
      Papa.parse(
        file,
        {
          header: true,
          transformHeader: csvTransformHeader,
          complete: (releases) => {
            resolve(releases);
          }
        }
      )
    });
  }
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch({
      type: 'LOADING',
      payload: true
    });
    try {
      const { data: releases, errors: csvErrors } = await parseCSVFile(state.data.file);
      const data = {
        releases,
        user
      };
      const errors = ProfilValidator.csvReleasesData(data);

      if (csvErrors.length !== 0 || errors !== false) {
        const payload = errors !== false
          ? errors
          : { global: uiTexts.global.inValid };

        dispatch({
          type: 'ERROR',
          payload,
        });
        return false;
      }

      const { data: response } = await Axios.post(`${globalLinks.api.account.profile}/${user}/libraries`, data, {
        headers: {
          'X-Csrf-Token': csrf,
        },
      });

      if (response.code === 'success') {
        dispatch({
          type: 'SUCCESS',
          payload: response.message,
        });
      } else {
        throw new Error('failed');
      }
    } catch (error) {
      let payload;
      if (error.response && error.response.data && error.response.data.code) {
        payload = error.response.data.errors;
      } else {
        payload = { global: uiTexts.global.network };
      }
      dispatch({
        type: 'ERROR',
        payload
      });
    }
  }

  return (
    <Modal
      show={show}
      onHide={onToggleModal}
      centered
    >
      <Modal.Body>
        <Modal.Title className='mb-3 position-sticky bg-white sticky_top'>
          <Music size={globalSizes.icon.default} className='mr-2' />
          {uiTexts.home.boxes.distribution.action.label}
        </Modal.Title>
        <LoaderIndicator loading={state.loading} className='z_index_2' />
        <AlertMessage
          show={showAlertMessage}
          message={alertMessage}
          variant={alertVariant}
        />
        <Form onSubmit={onSubmit}>
          <Form.Row className='m-0'>
            <Form.Group className='w-100' controlId='csv-file'>
              <Form.File id="csv-file" custom>
                <Form.File.Input
                  isInvalid={invalidFileData}
                  isValid={state.data.releases !== null}
                  onChange={onChooseFile}
                />
                <Form.File.Label data-browse="Fichier Excel" className='text-truncate'>
                  {
                    state?.data.file?.name || 'Selectionner votre fichier'
                  }
                </Form.File.Label>
                {
                  invalidFileData && (
                    <Form.Control.Feedback type="invalid">
                      {state.errors?.file || state.errors?.releases}
                    </Form.Control.Feedback>
                  )
                }
              </Form.File>
            </Form.Group>
          </Form.Row>
          <div className="d-flex justify-content-end mt-n2 position-sticky bg-white sticky_bottom">
            <Button variant='outline-danger' className='border_radius_left-side_lg' onClick={onToggleModal}>
              {uiTexts.global.close}
            </Button>
            <Button
              variant='outline-success'
              className='border_radius_right-side_lg'
              disabled={state.loading}
              type='submit'
            >
              <Save size={globalSizes.icon.default} className='mr-2' />
              {uiTexts.global.save}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default ProfileCSVReleasesForm;