import React, { useContext, useEffect, useReducer, } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import { Slash, Trash2 } from 'react-feather';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

import PageLayout from '../../../libs/PageLayout';
import LoaderIndicator from '../../../components/LoaderIndicator';
import TableList from '../../../components/TableList';
import uiTexts from '../../../config/text';
import globalLinks from '../../../config/links';
import { parseJsonData } from '../../../utils/data';
import { formatDateTo } from '../../../utils/dates';
import globalSizes from '../../../config/sizes';
import { globalReducer } from '../../../store/reducer';
import { GlobalReducerActionType } from '../../../types/shared';
import AuthContext from '../../../context/auth';

const tableHeaders = [
  uiTexts.global.order,
  uiTexts.global.paymentState,
  uiTexts.global.createdAt,
  uiTexts.services.promoCode.dueDate,
  uiTexts.global.cancel
];
const initState = {
  loading: true,
  errors: {},
  global: null,
  data: [],
};
export default function SubscriptionsList() {
  const axiosCancel = Axios.CancelToken.source();
  const title = uiTexts.services.subscriptions.list;
  const intiStateOfRemoving = {
    ...initState,
    loading: false,
    data: {
      showModal: false,
      orderId: null
    }
  };
  let _isMounted = true;
  const { csrf } = useContext(AuthContext);
  const [state, dispatch] = useReducer(globalReducer, initState);
  const [removingState, removeDispatch] = useReducer(globalReducer, intiStateOfRemoving);
  /**
   * close the alert message
   */
  const onClose = (type: GlobalReducerActionType, payload: any) => () => {
    dispatch({
      type,
      payload,
    });
  }
  const onToggleModal = (orderId?: number | string) => () => {
    _isMounted && removeDispatch({
      type: "SET_FIELDS",
      payload: {
        ...removingState.data,
        showModal: !removingState.data.showModal,
        orderId: orderId || null
      }
    });
  }
  const onCancelSubscription = async () => {
    _isMounted && removeDispatch({
      type: "LOADING",
      payload: true
    });

    try {
      const { data } = await Axios.delete(
        `${globalLinks.api.services.subscriptions.item}/${removingState.data.orderId}/cancel`,
        {
          cancelToken: axiosCancel.token,
          headers: {
            'X-Csrf-Token': csrf,
          }
        }
      );
      if (data.code === "success") {
        let newSubscriptions = state.data.filter((item: any) => item.id !== removingState.data.orderId);
        _isMounted && dispatch({
          type: "RESET_STATE",
          payload: {
            ...state,
            data: newSubscriptions,
          }
        });
        _isMounted && removeDispatch({
          type: "RESET_STATE",
          payload: {
            ...removingState,
            loading: false,
            global: data.message,
            data: {
              showModal: false,
              orderId: null
            }
          }
        });
      }
    } catch (error) {
      let payload;
      if (error?.response?.data?.code) {
        payload = error.response.data.errors;
      } else {
        payload = { global: uiTexts.global.network };
      }
      _isMounted && removeDispatch({
        type: 'RESET_STATE',
        payload: {
          ...removingState,
          errors: payload,
          data: {
            ...removingState.data,
            showModal: false,
            orderId: null
          }
        },
      });
    }
  }

  useEffect(() => {
    const loadSubscriptionsData = async () => {
      try {
        const { data } = await Axios.get(
          globalLinks.api.services.subscriptions.list,
          {
            cancelToken: axiosCancel.token,
          }
        );

        if (data.code === 'success') {
          _isMounted && dispatch({
            type: 'SET_LIST',
            payload: data.data,
          });
        } else {
          _isMounted && dispatch({
            type: 'LOADING',
            payload: false,
          });
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.code) {
          _isMounted && dispatch({
            type: 'ERROR',
            payload: error.response.data.errors,
          });
        } else {
          _isMounted && dispatch({
            type: 'ERROR',
            payload: { global: uiTexts.global.network },
          });
        }
      }
    }
    loadSubscriptionsData();

    return () => {
      _isMounted = false;
      axiosCancel.cancel('Operation canceled');
    }
  }, []);

  /**
   * render a list of invoices
   */
  const renderListItem = () => {
    if (!state.loading && !state.data.length) {
      return (
        <tr>
          <td className='h5 text-muted text-center' colSpan={5}>
            <Slash size={globalSizes.icon.xm} className='mr-2' />
            {uiTexts.global.noOptions}
          </td>
        </tr>
      );
    } else {
      return state.data.map(
        (item: Record<string, any>) => {
          const orderItems = parseJsonData(item.orderItems, {});
          const createdAt = formatDateTo(item.createdAt, globalSizes.dates.fr.format);
          const dueDate = formatDateTo(orderItems?.dueDate, globalSizes.dates.fr.format);
          const badgeVariant = item?.status === 'PAID'
            ? ' badge-primary'
            : ' badge-warning';

          return (
            <tr key={item.id} className={`table-list__item`} onClick={undefined}>
              <td key={`${item.id}-order`} className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                <div>
                  <strong>#{item.id}</strong>
                </div>
                <Link to={`${globalLinks.account.profile}/${item.Account.id}`} className='text-capitalize'>
                  {`${item.Account.fname} ${item.Account.lname}`}
                </Link>
              </td>
              <td key={`${item.id}-payment`} className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                <Badge className='custom__badge' variant={badgeVariant}>
                  <span className='font-weight-bold'>
                    {item.totalAmountByLocation}
                  </span>
                  <span className='mr-2'>{orderItems.currency}</span>
                  {uiTexts.global.status[item?.status]}
                </Badge>
              </td>
              <td key={`${item.id}-created-at`} className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                <Badge variant="light" className='fs-14'>{createdAt}</Badge>
              </td>
              <td key={`${item.id}-due-date`} className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                <Badge variant="light" className='fs-14'>{dueDate}</Badge>
              </td>
              <td key={`${item.id}-remove-btn`} className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                <Button
                  variant='outline-danger'
                  title={uiTexts.global.cancel}
                  className='d-flex p-2' data-testid={`remove-${item.id}`}
                  onClick={onToggleModal(item.id)}
                >
                  <Trash2 size={globalSizes.icon.default} className='mr-2' />
                  {uiTexts.global.cancel}
                </Button>
              </td>
            </tr>
          );
        }
      );
    }
  }

  return (
    <PageLayout className='page__bloc pt-3 pt-md-5 pb-3 pb-md-5 main__block' title={title}>

      <LoaderIndicator
        loading={state.loading}
        testid='spinner'
      />

      <div className="action__block d-flex justify-content-between flex-wrap mb-3 mb-md-4">
        <h1 className='h4'>{title}</h1>
      </div>

      {
        (!!state.errors.global || !!removingState.errors.global) &&
        <Alert
          className='w-100'
          variant='danger'
          onClose={onClose('ERROR', {})}
          dismissible={!!state.errors.global}
          transition>
          {state.errors.global || removingState.errors.global}
        </Alert>
      }
      {
        (!!state.global || !!removingState.global) &&
        <Alert
          className='w-100'
          variant='success'
          onClose={onClose('SUCCESS', null)}
          dismissible
          transition>
          {state.global || removingState.global}
        </Alert>
      }

      <TableList headers={tableHeaders}>
        {renderListItem()}
      </TableList>

      <Modal
        show={removingState.data.showModal}
        onHide={onToggleModal()}
        centered
      >
        <Modal.Header className='border-bottom-0' closeButton>
          <Modal.Title>
            {uiTexts.global.askConfirmation}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-end">
            <Button
              variant='outline-secondary'
              className='border_radius_left-side_lg'
              onClick={onToggleModal()}
            >
              {uiTexts.global.cancel}
            </Button>
            <Button
              variant='outline-danger'
              className='border_radius_right-side_lg'
              onClick={onCancelSubscription}
              disabled={removingState.loading}
            >
              {
                removingState.loading
                  ? (
                    <Spinner
                      className='main-text-color'
                      animation="border"
                      role="status"
                      size='sm'
                    />
                  )
                  : uiTexts.global.sure
              }
            </Button>
          </div>
        </Modal.Body>
      </Modal>

    </PageLayout>
  )
}
