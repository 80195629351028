import React, { FormEvent, useContext, useReducer, } from 'react';
import Axios from 'axios';
import { useLocation, Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import AuthContext from '../../context/auth';
import uiTexts from '../../config/text';
import Alert from 'react-bootstrap/Alert';
import { Action, AuthState, AuthActionType } from '../../types/shared';
import globalLinks from '../../config/links';
import AuthValidator from '../../utils/AuthValidator';
import globalSizes from '../../config/sizes';
import GradientButton from '../../components/GradientButton';

import Logo from '../../assets/images/dropzik-logo-icon.png';
import '../Signup/style.css';

const fields = {
  email: null,
  password: null,
};
const authState = {
  loading: false,
  errors: fields,
  global: null,
  data: fields,
};
function signinReducer(state: AuthState, action: Action<AuthActionType, any>) {
  switch (action.type) {
    case 'LOADING':
      return { ...state, loading: action.payload };
    case 'ERROR':
      return { ...state, loading: false, errors: action.payload }
    case 'SUCCESS':
      return { ...state, loading: false, errors: fields, global: action.payload };
    case 'SET_FIELD':
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.key]: action.payload.value
        }
      };
    default: return state;
  }
}

export default function SignIn() {
  const location = useLocation<{ from: string | null }>();
  const authContext = useContext(AuthContext);
  const [state, dispatch] = useReducer(signinReducer, authState);

  const onChange = (evnt: React.ChangeEvent<HTMLInputElement>) => {
    const target = evnt.currentTarget;
    dispatch({
      type: 'SET_FIELD',
      payload: {
        key: target.name, value: target.value
      }
    });
  }
  const onSubmit = async (evn: FormEvent<HTMLFormElement>) => {
    evn.preventDefault();
    dispatch({
      type: 'LOADING',
      payload: true,
    });
    try {
      const errors = AuthValidator.signInData(state.data);

      if (errors) {
        return dispatch({
          type: 'ERROR',
          payload: errors,
        });
      } else {
        const { data, } = await Axios.post(globalLinks.api.signin, state.data, {
          headers: {
            'X-Csrf-Token': authContext.csrf // csrf token
          },
        });
        if (data.code === 'success') {
          authContext.signIn();
          window.location.replace(location.state?.from || '/');
        }
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.code) {
        dispatch({
          type: 'ERROR',
          payload: error.response.data.errors,
        });
      } else {
        dispatch({
          type: 'ERROR',
          payload: { global: uiTexts.global.network },
        });
      }
    }
  }

  return (
    <div className='auth-page__container d-flex justify-content-cener align-items-center'>
      <Container>
        <div className="form-signup__container d-flex align-items-center justify-content-center">
          <Form className='form__block rounded shadow p-3 bg-white' onSubmit={onSubmit}>

            <Form.Group controlId='appLogo'>
              <div className="text-center">
                <img className="mb-2" src={Logo} alt={uiTexts.signup.form.imageAlt} width={globalSizes.icon.md} />
                <h1 className="h3 mb-0 font-weight-normal">{uiTexts.signup.form.title}</h1>
              </div>
              {
                !!state.errors.global &&
                <Alert data-testid='global-error-alert' variant='danger'>{state.errors.global}</Alert>
              }
            </Form.Group>

            <Form.Group controlId='email'>
              <Form.Label className='mb-1'>{uiTexts.signup.form.email}</Form.Label>
              <Form.Control size='lg' isInvalid={!!state.errors.email} onChange={onChange} name="email" placeholder="Votre Email" autoFocus required />
              {
                !!state.errors.email &&
                <Form.Control.Feedback type="invalid">
                  {state.errors.email}
                </Form.Control.Feedback>
              }
            </Form.Group>
            <Form.Group controlId='password'>
              <Form.Label className='mb-1'>{uiTexts.signup.form.password}</Form.Label>
              <Form.Control size='lg' isInvalid={!!state.errors.password} onChange={onChange} name="password" type='password' placeholder="Votre mot de passe " required />
              {
                !!state.errors.password &&
                <Form.Control.Feedback type="invalid">
                  {state.errors.password}
                </Form.Control.Feedback>
              }
            </Form.Group>
            <Form.Group>
              <Link to='/reset-password'>{uiTexts.signup.form.forget}</Link>
            </Form.Group>
            <GradientButton className='text-white' disabled={!!state.loading} type="submit" size='lg' block>
              {
                !!state.loading
                  ? uiTexts.global.loading
                  : uiTexts.signup.form.button
              }
            </GradientButton>

          </Form>
        </div>
      </Container>
    </div>
  );
}
