import Axios from 'axios';
import React, { useContext, useReducer, useRef } from 'react'
import AlertMessage from '../../../components/AlertMessage';
import CSVTrackISRCForm from '../../../components/CSVTrackISRCForm';
import globalLinks from '../../../config/links';
import uiTexts from '../../../config/text';
import AuthContext from '../../../context/auth';
import PageLayout from '../../../libs/PageLayout';
import { globalReducer } from '../../../store/reducer';
import { generateCSVFile, parseCSVFile } from '../../../utils/csv';
import LibraryValidator from '../../../utils/LibraryValidator';

const initialState: any = {
  loading: false,
  errors: {},
  global: null,
  data: {},
};
export default function TracksISRC() {
  const { csrf } = useContext(AuthContext);
  const anchorRef = useRef<HTMLAnchorElement>(null!);
  const [csvState, csvDispatch] = useReducer(globalReducer, initialState);
  const alertMessage = csvState.global || csvState.errors.global;
  const alertVariant = csvState.global ? "success" : "danger";
  const showAlert = !!csvState.global || !!csvState.errors.global;

  const onChooseFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.item(0);
    const csvMimeTypes = ['text/csv', 'text/comma-separated-values', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

    if (file?.type !== undefined && csvMimeTypes.includes(file.type)) {
      csvDispatch({
        type: 'RESET_STATE',
        payload: {
          ...csvState,
          data: {
            ...csvState.data,
            file: file
          },
          errors: {}
        }
      });
    } else {
      csvDispatch({
        type: 'ERROR',
        payload: {
          file: 'S.V.P selectionner un fichier CSV'
        }
      })
    }
  }
  const generateUnsavedTracksISRCOrBarcode = async (unsavedTracks: Record<string, string>[]) => {
    const { link } = await generateCSVFile(unsavedTracks);
    anchorRef.current.href = link;
    anchorRef.current.click();
  }
  const onBulkUpdateTracksISRC = async (evnt: React.FormEvent) => {
    evnt.preventDefault();
    csvDispatch({
      type: 'LOADING',
      payload: true,
    });

    try {
      const { data: tracksList, errors: csvErrors } = await parseCSVFile<Record<string, string>>(csvState.data.file);
      const validatorErrors = LibraryValidator.verifyISRCOrBarcodeBulkUpdateData(tracksList, csvState.data?.type);
      const BULK_SIZE = 50;
      let errors = null;

      if (csvErrors.length !== 0) {
        errors = {
          global: uiTexts.global.inValid
        };
      } else if (validatorErrors !== false) {
        errors = validatorErrors;
      }
      if (errors !== null) {
        csvDispatch({
          type: 'ERROR',
          payload: errors,
        });
        return false;
      }
      let response: Record<string, any> | null = null;
      let unsavedTracksISRC = [];
      const enpoinUrl = csvState.data?.type === "barcode"
        ? globalLinks.api.libraries.bulkBarcodeUpdate
        : globalLinks.api.libraries.tracks.bulkISRCUpdate;
      for (let index = 0; index < Math.ceil(tracksList.length / BULK_SIZE); index++) {
        const chunk = tracksList.slice(index * BULK_SIZE, (index + 1) * BULK_SIZE);
        const { data } = await Axios.put(
          enpoinUrl,
          chunk,
          {
            headers: {
              'X-Csrf-Token': csrf,
            }
          }
        );
        response = data;
        unsavedTracksISRC.push(...(data?.data || []));
      }

      if (response?.code === 'success') {
        await generateUnsavedTracksISRCOrBarcode(unsavedTracksISRC);
        csvDispatch({
          type: 'SUCCESS',
          payload: response?.message,
        });
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.code) {
        csvDispatch({
          type: 'ERROR',
          payload: error.response.data.errors,
        });
      } else {
        csvDispatch({
          type: 'ERROR',
          payload: { global: uiTexts.global.network },
        });
      }
    }
    window.scrollTo(0, 0);
  }
  const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name: key, value } = event.currentTarget;
    csvDispatch({
      type: 'SET_FIELD',
      payload: {
        key,
        value
      }
    });
  }

  return (
    <PageLayout className='page__bloc pt-3 pt-md-5 pb-3 pb-md-5 main__block' title={uiTexts.global.sidebar.tools.editIsrcOrBarcode}>
      <div className="action__block d-flex justify-content-between flex-wrap mb-3 mb-md-4">
        <h1 className='h4'>{uiTexts.global.sidebar.tools.editIsrcOrBarcode}</h1>
      </div>
      <AlertMessage
        message={alertMessage}
        variant={alertVariant}
        show={showAlert}
      />
      <CSVTrackISRCForm
        errors={csvState.errors}
        data={csvState.data}
        loading={csvState.loading}
        onChooseFile={onChooseFile}
        onSubmit={onBulkUpdateTracksISRC}
        onChange={onChange}
      />
      <a
        ref={anchorRef}
        className='hidden'
        download={'unsaved-reports.csv'}
      />
    </PageLayout>
  );
}
