import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Image from 'react-bootstrap/Image';
import DatePicker, { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import { parse } from 'date-fns';
import Select, { ActionMeta, ValueType } from 'react-select';

import FormField from '../FormField';
import GradientButton from '../GradientButton';
import { BillingFormProps, KeyValueObject } from '../../types/shared';
import libraryMessages from '../../config/messages/library';
import uiTexts from '../../config/text';
import yearsList from '../../config/data/years';

import "react-datepicker/dist/react-datepicker.css";
import languagesList from '../../config/data/languages';
import genresList from '../../config/data/genre';
import subgenresList from '../../config/data/subgenres';
import globalSizes from '../../config/sizes';
registerLocale('fr', fr);

interface LibraryFormProps extends BillingFormProps {
  onSelectDate: (field: string) => (o: any) => void;
  onSelectReleaseCover: (evnt: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeSelect: (value: ValueType<any>, action: ActionMeta<any>) => void;
  isEdit?: boolean;
}
export default function LibraryForm({ onSubmit, onChange, onClose, state, onSelectDate, onChangeSelect, onSelectReleaseCover, isEdit }: LibraryFormProps) {
  const digitalReleaseDate = state.data?.digitalReleaseDate
    ? new Date(parse(state.data?.digitalReleaseDate, 'dd/MM/yyyy', new Date()))
    : null;
  const CustomDateInput = ({ value, onClick, label, name }: any) => {
    return (
      <FormField
        defaultValue={value}
        error={state.errors?.originalRealeaseDate || null}
        label={label || libraryMessages.originalRealeaseDate.label}
        name={name || 'originalRealeaseDate'}
        onClick={onClick}
        required readonly={false} />
    );
  }

  return (
    <Form onSubmit={onSubmit}>
      {
        !!state.errors.global &&
        <Alert className='w-100 mb-5' variant='danger' onClose={onClose && onClose('ERROR', {})} dismissible transition>{state.errors.global}</Alert>
      }
      {
        !!state.global &&
        <Alert className='w-100 mb-5' variant='success' onClose={onClose && onClose('SUCCESS', null)} dismissible transition>{state.global}</Alert>
      }
      <Form.Row>
        <Col xs={12} sm={6} className='mb-2'>
          <FormField
            defaultValue={state.data?.title} error={state.errors?.title || null}
            label={libraryMessages.title.label} name='title'
            onChange={onChange}
            required autoFocus readonly={false} />
        </Col>
        <Col xs={12} sm={6} className='mb-2'>
          <FormField
            defaultValue={state.data?.label} error={state.errors?.label || null}
            label={libraryMessages.label.label} name='label'
            onChange={onChange}
            required readonly={false} />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12} sm={6} className='mb-2'>
          <Form.Group controlId='status'>
            <Form.Label>{uiTexts.global.itemStatus}*</Form.Label>
            <Form.Control size='lg' defaultValue={state.data.status} onChange={onChange} name='status' as="select" className='pt-2' isInvalid={!!state.errors?.status}>
              <option value={"PENDING"}>{uiTexts.global.status['PENDING']}</option>
              <option value={"DELIVRED"}>{uiTexts.global.status['DELIVRED']}</option>
            </Form.Control>
            {
              !!state.errors?.status &&
              <Form.Control.Feedback className='d-block' type="invalid">
                {state.errors?.status}
              </Form.Control.Feedback>
            }
          </Form.Group>
        </Col>
        <Col xs={12} sm={6} className='mb-2'>
          <Form.Group controlId='language'>
            <Form.Label>{libraryMessages.language.label}</Form.Label>
            <Form.Control size='lg' defaultValue={state.data.language || '-1'} onChange={onChange} name='language' as="select" className='pt-2' isInvalid={!!state.errors?.language}>
              <option value='-1' disabled>{libraryMessages.language.label}</option>
              {
                languagesList.map((language: KeyValueObject<string>) => (
                  <option key={language.value} value={language.value}>{language.label}</option>
                ))
              }
            </Form.Control>
            {
              !!state.errors?.language &&
              <Form.Control.Feedback className='d-block' type="invalid">
                {state.errors?.language}
              </Form.Control.Feedback>
            }
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12} sm={6} className='mb-2'>
          <DatePicker
            locale='fr'
            selected={digitalReleaseDate}
            onChange={onSelectDate('digitalReleaseDate')}
            customInput={<CustomDateInput name='digitalReleaseDate' label={libraryMessages.digitalReleaseDate.label} />}
            wrapperClassName='w-100'
            placeholderText={libraryMessages.digitalReleaseDate.label}
          />
        </Col>
        <Col xs={12} sm={6} className='mb-2'>
          <FormField
            defaultValue={state.data?.publisherLink}
            error={state.errors?.publisherLink || null}
            label={libraryMessages.publisherLink.label}
            name='publisherLink'
            onChange={onChange}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12} sm={6} className='mb-2'>
          <DatePicker
            locale='fr'
            selected={new Date(parse(state.data?.originalRealeaseDate, 'dd/MM/yyyy', new Date()))}
            onChange={onSelectDate('originalRealeaseDate')}
            customInput={<CustomDateInput />}
            wrapperClassName='w-100'
            placeholderText={libraryMessages.originalRealeaseDate.label}
          />
        </Col>
        <Col xs={12} sm={6} className='mb-2'>
          <FormField
            defaultValue={state.data?.version} error={state.errors?.version || null}
            label={libraryMessages.version.label} name='version'
            onChange={onChange}
            readonly={false} />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12} sm={6} className='mb-2'>
          <Form.Group controlId='genre'>
            <Form.Label>{libraryMessages.genre.label}</Form.Label>
            <Form.Control size='lg' defaultValue={state.data.genre || '-1'} onChange={onChange} name='genre' as="select" className='pt-2' isInvalid={!!state.errors?.language}>
              <option value='-1' disabled>{libraryMessages.genre.label}</option>
              {
                genresList.map((genre: KeyValueObject<string>) => (
                  <option key={genre.value} value={genre.value}>{genre.label}</option>
                ))
              }
            </Form.Control>
            {
              !!state.errors?.genre &&
              <Form.Control.Feedback className='d-block' type="invalid">
                {state.errors?.genre}
              </Form.Control.Feedback>
            }
          </Form.Group>
        </Col>
        <Col xs={12} sm={6} className='mb-2'>
          <Form.Group controlId='subgenre'>
            <Form.Label>{libraryMessages.subgenre.label}</Form.Label>
            <Form.Control size='lg' defaultValue={state.data.subgenre || '-1'} onChange={onChange} name='subgenre' as="select" className='pt-2' isInvalid={!!state.errors?.language}>
              <option value='-1' disabled>{libraryMessages.subgenre.label}</option>
              {
                subgenresList.map((subgenre: KeyValueObject<string>, indx: number) => (
                  <option key={`${subgenre.value}-${indx}`} value={subgenre.value}>{subgenre.label}</option>
                ))
              }
            </Form.Control>
            {
              !!state.errors?.subgenre &&
              <Form.Control.Feedback className='d-block' type="invalid">
                {state.errors?.subgenre}
              </Form.Control.Feedback>
            }
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12} sm={6} className='mb-2'>
          <Form.Group id='coverDetailsYear' className='custom__select-block'>
            <Select
              options={yearsList} name='yearOfRegistration'
              placeholder=''
              defaultValue={{ label: state.data?.yearOfRegistration, value: state.data?.yearOfRegistration }}
              className='custom__select'
              onChange={onChangeSelect} />
            {
              state.errors?.yearOfRegistration && (
                <Form.Control.Feedback className='d-block' type="invalid">
                  {state.errors?.yearOfRegistration}
                </Form.Control.Feedback>
              )
            }
            <Form.Label>{libraryMessages.yearOfRegistration.label}</Form.Label>
          </Form.Group>
        </Col>
        <Col xs={12} sm={6} className='mb-2'>
          <FormField
            defaultValue={state.data?.recordingOwnerName} error={state.errors?.recordingOwnerName || null}
            label={libraryMessages.recordingOwnerName.label} name='recordingOwnerName'
            onChange={onChange}
            required readonly={false} />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12} sm={6} className='mb-2'>
          <FormField
            defaultValue={state.data?.barcode} error={state.errors?.barcode || null}
            label={libraryMessages.barcode.label} name='barcode'
            onChange={onChange}
            readonly={false} />
        </Col>
        <Col xs={12} sm={6} className='mb-2'>
          <FormField
            defaultValue={state.data?.catalogNumber} error={state.errors?.catalogNumber || null}
            label={libraryMessages.catalogNumber.label} name='catalogNumber'
            onChange={onChange}
            readonly={false} />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col sm={12}>
          {
            isEdit
              ? (
                <div className='release-image overflow-hidden border_radius_3 shadow mb-5'>
                  <Image src={`${globalSizes.resourcesMainDomain}${state.data?.coverDetails?.imageDetails?.url}`} fluid />
                </div>
              )
              : (
                <FormField
                  defaultValue={state.data?.cover} error={state.errors?.cover || null}
                  label={libraryMessages.coverDetails.label} name='cover'
                  onChange={onSelectReleaseCover} type='file'
                  required readonly={false} />
              )
          }
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs={12} sm={6} className='mb-2'>
          <FormField
            defaultValue={state.data?.coverDetailsOwner} error={state.errors?.coverDetailsOwner || null}
            label={libraryMessages.coverDetailsOwner.label} name='coverDetailsOwner'
            onChange={onChange}
            required
            readonly={false} />
        </Col>
        <Col xs={12} sm={6} className='mb-2'>
          <Form.Group id='coverDetailsYear' className='custom__select-block'>
            <Select
              className='custom__select'
              placeholder=''
              defaultValue={{ label: state.data?.coverDetailsYear || state.data?.coverDetails?.year, value: state.data?.coverDetailsYear || state.data?.coverDetails?.year }}
              options={yearsList} name='coverDetailsYear'
              onChange={onChangeSelect} />
            {
              state.errors?.coverDetailsYear && (
                <Form.Control.Feedback className='d-block' type="invalid">
                  {state.errors?.coverDetailsYear}
                </Form.Control.Feedback>
              )
            }
            <Form.Label>{libraryMessages.coverDetailsYear.label}</Form.Label>
          </Form.Group>
        </Col>
      </Form.Row>
      {
        onSubmit && (
          <div className="d-flex justify-content-sm-end justify-content-start">
            <GradientButton disabled={state.loading || !!state.global} className='text-white pl-4 pr-4' type="submit">
              {
                state.loading
                  ? uiTexts.global.loading
                  : uiTexts.global.save
              }
            </GradientButton>
          </div>
        )
      }
    </Form>

  )
}
