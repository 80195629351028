import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import CardImage from '../../components/CardImage';
import { ScrollPosition, trackWindowScroll } from 'react-lazy-load-image-component';

interface CarsListPropsTypes {
  data: Record<string, any>[];
  scrollPosition: ScrollPosition;
  onNavigateToReleasePage: (release: Record<string, any>) => () => void;
}
function CardsList({ data, onNavigateToReleasePage, scrollPosition }: CarsListPropsTypes) {
  return (
    <Row>
      {data.map((release: any) => {
        const coverDetails = JSON.parse(release.coverDetails);
        const imageSource = coverDetails?.imageDetails;
        return (
          <Col key={release.id} xs={12} sm={6} md={4}>
            <CardImage
              imageSource={imageSource?.url}
              release={release}
              onNavigateToReleasePage={onNavigateToReleasePage(release)}
              scrollPosition={scrollPosition}
            />
          </Col>
        );
      })}
    </Row>
  );
}

export default trackWindowScroll(CardsList);