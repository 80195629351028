import React, { useContext, useEffect, useReducer } from 'react';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { Edit, Slash, Trash2 } from 'react-feather';
import { Link, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

import TableList from '../../../components/TableList';
import LoaderIndicator from '../../../components/LoaderIndicator';
import globalLinks from '../../../config/links';
import globalSizes from '../../../config/sizes';
import uiTexts from '../../../config/text';
import PageLayout from '../../../libs/PageLayout';
import { globalReducer } from '../../../store/reducer';
import { GlobalReducerActionType } from '../../../types/shared';
import AuthContext from '../../../context/auth';

const initState = {
  loading: true,
  errors: {},
  global: null,
  data: [],
};
export default function SmartlinkList() {
  const { status } = useParams<{ status: string }>();
  const [state, dispatch] = useReducer(globalReducer, initState);
  const { csrf } = useContext(AuthContext);
  const axiosOptions = {
    headers: {
      'X-Csrf-Token': csrf,
    },
  }
  const { link, } = uiTexts.services.smartlink;
  const headers = [uiTexts.global.releaseTitle, link, uiTexts.global.edit];
  let _isMounted = true;

  const onClose = (type: GlobalReducerActionType, payload: any) => () => {
    dispatch({
      type,
      payload,
    })
  }

  const onCancelSmartlink = (id: number) => async () => {
    _isMounted && dispatch({
      type: 'LOADING',
      payload: true
    });

    try {
      const { data } = await axios.delete(
        `${globalLinks.api.services.smartlink.item}/${id}/delete`,
        axiosOptions
      );

      if (data.code === 'success') {
        const newData = state.data.filter((item: Record<'id', number>) => item.id !== id);
        _isMounted && dispatch({
          type: 'SET_LIST_WITH_MESSAGE',
          payload: {
            message: data.message,
            data: newData,
          }
        });
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.code) {
        _isMounted && dispatch({
          type: 'ERROR',
          payload: error.response.data.errors,
        });
      } else {
        _isMounted && dispatch({
          type: 'ERROR',
          payload: { global: uiTexts.global.network },
        });
      }
    }
  }

  useEffect(() => {
    const fetchSmartlinkList = async () => {
      _isMounted && !state.loading && dispatch({
        type: 'LOADING',
        payload: true,
      })
      try {
        const { data } = await axios.get(`${globalLinks.api.services.smartlink.list}/${status}`);
        if (data.code === 'success') {
          _isMounted && dispatch({
            type: 'SET_LIST_WITH_MESSAGE',
            payload: {
              message: null,
              data: data.data
            },
          });
        } else {
          throw new Error(data.code);
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.code) {
          _isMounted && dispatch({
            type: 'ERROR',
            payload: error.response.data.errors,
          });
        } else {
          _isMounted && dispatch({
            type: 'ERROR',
            payload: { global: uiTexts.global.network },
          });
        }
      }
    }
    fetchSmartlinkList();

    return () => {
      _isMounted = false;
    }
  }, [status]);

  return (
    <PageLayout className='page__bloc pt-3 pt-md-5 pb-3 pb-md-5 main__block' title={uiTexts.services.smartlink.list}>
      <LoaderIndicator loading={state.loading} testid='spinner' />
      <div className="action__block d-flex justify-content-between flex-wrap mb-3 mb-md-4">
        <h1 className='h4'>{uiTexts.services.smartlink.list}</h1>
      </div>
      {
        !!state.errors.global &&
        <Alert className='w-100' variant='danger' onClose={onClose('ERROR', {})} dismissible transition>{state.errors.global}</Alert>
      }
      {
        !!state.global &&
        <Alert className='w-100' variant='success' onClose={onClose('SUCCESS', null)} dismissible transition>{state.global}</Alert>
      }
      <div className="list__block mt-3 mt-md-4">
        {
          !state.data.length
            ? <h4 className='h5 text-muted text-center'>
              <Slash size={globalSizes.icon.xm} className='mr-2' />
              {uiTexts.global.noOptions}
            </h4>
            : <TableList headers={headers}>
              {
                state.data.map(
                  (smartlinkItem: Record<any, any>) => {
                    const createdAt = format(new Date(smartlinkItem.createdAt), globalSizes.dates.fr.format, {
                      locale: fr
                    });
                    const link = `${globalLinks.fenanPro}/${smartlinkItem.slug}`;

                    return (
                      <tr key={`smartlink-item-${smartlinkItem.id}`} className={`table-list__item`}>
                        <td className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle fl-uppercase'>
                          <h3 className='h5'>{smartlinkItem.Library.title}</h3>
                          <span className='text-muted'>{createdAt}</span>
                        </td>
                        <td className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle'>
                          <a href={`//${link}`} target='_blank' rel='noopener noreferrer'>{link}</a>
                        </td>
                        <td className='table-list__body-cell pt-3 pl-4 pb-3 pr-4 align-middle fl-uppercase'>
                          <Link to={`${globalLinks.services.smartlink.item}/${smartlinkItem.id}/edit`} className='btn btn-outline-primary p-2 mr-2'>
                            <Edit size={globalSizes.icon.default} />
                          </Link>
                          <Button onClick={onCancelSmartlink(Number(smartlinkItem.id))} variant='outline-danger' title={uiTexts.global.remove} className='p-2' data-testid={`remove-${smartlinkItem.id}`}>
                            <Trash2 size={globalSizes.icon.default} />
                          </Button>
                        </td>
                      </tr>
                    );
                  }
                )
              }
            </TableList>
        }
      </div>
    </PageLayout>
  )
}
