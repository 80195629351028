import React from 'react'
import Form from 'react-bootstrap/Form';
import { CheckBoxProps } from '../../types/shared';

import './style.css';

export default function CheckBox({ name, value, label, defaultChecked, id, type = 'checkbox', containerClassName = '', className = '', error, onChange }: CheckBoxProps) {
  return (
    <Form.Check id={id || name} className={`custom__checkbox-container ${containerClassName}`}>
      <Form.Check.Input
        name={name} type={type}
        className={`custom__checkbox ${className}`}
        checked={defaultChecked}
        defaultValue={value}
        onChange={onChange}
        isInvalid={!!error} />
      <Form.Check.Label>{label}</Form.Check.Label>
      {
        error && (
          <Form.Control.Feedback type='invalid'>{error}</Form.Control.Feedback>
        )
      }
    </Form.Check>
  )
}
