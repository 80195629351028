import Joi from "joi";
import AbstractValidator from "./abstract";
import { servicesMessages } from '../../config/messages/services';

export default class ServiceValidator extends AbstractValidator {

  /**
   * verify promo code data
   * @param data promo code details
   * @returns list of errors or false
   */
  static verifyPromoCode(data: Partial<Record<'name' | 'amount' | 'dueDate' | 'description', string | number>>) {
    const dateRegExp = /(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})/;
    const dataSchema = Joi.object().keys({
      name: Joi.string().trim().min(2).required().label(servicesMessages.name.errorMsg),
      description: Joi.string().trim().optional().label(servicesMessages.description.errorMsg),
      amount: Joi.number().min(0).max(100).required().label(servicesMessages.amount.errorMsg),
      dueDate: Joi.string().regex(dateRegExp).required().label(servicesMessages.dueDate.errorMsg),
    })
      .options({ abortEarly: false });

    return this.getErrorsOfSchema(dataSchema, data);
  }

  /**
   * verify promo code data
   * @param data promo code details
   * @returns list of errors or false
   */
  static verifySmartlink(data: Partial<Record<'urls' | 'status', string | Record<string, string>>>) {
    const platformsErrors = this.verifySmartlinkPlatforms(data?.urls);

    if (platformsErrors !== false) {
      return platformsErrors;
    }

    const dataSchema = Joi.object().keys({
      status: Joi.string().regex(/ENABLED|PENDING/).required().label(servicesMessages.status.errorMsg),
      urls: Joi.any().optional(),
    })
      .options({ abortEarly: false });

    return this.getErrorsOfSchema(dataSchema, data);
  }

  static verifySmartlinkPlatforms(data: any) {
    const regExp = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/;
    const urlSchema = Joi.string().trim().regex(regExp).optional().allow(null, '');
    const dataSchema = Joi.object().keys({
      facebook: urlSchema.label(servicesMessages.facebook.errorMsg),
      spotify: urlSchema.label(servicesMessages.spotify.errorMsg),
      instagram: urlSchema.label(servicesMessages.instagram.errorMsg),
      youtube: urlSchema.label(servicesMessages.youtube.errorMsg),
      deezer: urlSchema.label(servicesMessages.deezer.errorMsg),
      anghami: urlSchema.label(servicesMessages.anghami.errorMsg),
      'youtube-music': urlSchema.label(servicesMessages.youtubeMusic.errorMsg),
      'apple-music': urlSchema.label(servicesMessages.appleMusic.errorMsg),
    })
      .options({ abortEarly: false });

    return this.getErrorsOfSchema(dataSchema, data);
  }

}